import React from 'react'
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postActionSetProfileVersionCopyCpoUser, getChasValidationName } from '../../api/controlhub/controlhubApi.js';


export const ChasCopyModal = (props) => {
  let navigate = useNavigate();
  const [loadingOn, setLoadingOn] = useState(false);
  const [loadingOn2, setLoadingOn2] = useState(false);

  const [errMsg, setErrMsg] = useState(null);
  const copyActionSetProfileVersion = async()=>{
      setLoadingOn2(true);
      let data = {"actionSetName" : chasName};
      let result = await postActionSetProfileVersionCopyCpoUser(props.profileId, props.versionId, data);
      if(result.status === 200 || result.status === 201){
        props.setOnOff(false);
        navigate('/cpo/custom-chas/edit',{ state: { mode:"edit", ocpp: "OCPP 1.6", actionSetId:result.data?.actionSetId } })
      }
      else{
        setErrMsg("복사에 실패하였습니다.");
      }
      setLoadingOn2(false);
    }

  const [chasName, setChasName] = useState(props.profileName+ " " +props.version+" 복사본");
  const [chasValid, setChasValid] = useState(null);

  const dupCheck = async(chasName)=>{
    setLoadingOn(true);
    let result = await getChasValidationName(chasName);
    if(result.status === 200){
      if(result.data){
        setChasValid(true);
      }
      else setChasValid(false);
    }
    setLoadingOn(false);
  }

  return (
    <div className="cpo-chas-copy-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-chas-copy-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="modal-header">
          <title>액션셋 복사</title>
          <div className='modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-chas-copy-modal-content">
          <div className='cpo-custom-chas-action-modal-info'>
            <div>{props.profileName}</div>
            <div>{props.version}</div>
          </div>
          <div className='cpo-chas-copy-modal-content-container'>
            <p>액션셋 명</p>
            <div className='cpo-chas-copy-modal-grid-wrapper'>
              <div className='cpo-chas-copy-modal-input-container'>
                <input type="text" placeholder='CHAS의 이름을 입력해주세요' value={chasName} onChange={(e)=>{setChasName(e.target.value);setChasValid(null)}}/>
              </div>
              <div className='cpo-chas-name-check-button ' onClick={()=>{dupCheck(chasName)}}>
                {loadingOn ? 
                <Loading/>
                :"중복확인"}
              </div>
              {(chasName =="" || chasValid === null) &&<p className='cpo-chas-name-check-info'>중복확인을 눌러 주세요.</p>}
              {chasValid === false &&<p className='cpo-chas-name-check-info'>중복된 이름입니다.</p>}
              {chasValid === true && <p className='cpo-chas-name-check-info valid'>사용가능한 이름입니다.</p>}
            </div>
            <div className='cpo-chas-copy-modal-copy-button-container'>
              <div className={`cpo-chas-copy-modal-copy-button ${chasValid ? "":"disabled"}`} onClick={()=>{copyActionSetProfileVersion()}}>
                {loadingOn2 ? 
                <Loading/>
                :"복사하기"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChasCopyModal;