/**
 * @author lnamkyung <lnamkyung@evmonit.com>
 * @create 2024
 * @file 시스템 소개 > 가격페이지 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {getCookie} from '../../util/util.js'
import topBg from '../../img/servicePricingBg_1.png'
import topBg2 from '../../img/servicePricingBg_2.png'

import cpoServiceSlider_1 from '../../img/cpoServiceSlider_1.png'
import cpoServiceSlider_2 from '../../img/cpoServiceSlider_2.png'
import cpoServiceSlider_3 from '../../img/cpoServiceSlider_3.png'


export const ServicePricing = () => {
  let navigate = useNavigate();
  let signedToken = getCookie("signedToken");
  
  const [sliderIdx, setSliderIdx] = useState(0);
  
  const [sliderImageList, setSliderImageList] = useState([cpoServiceSlider_1, cpoServiceSlider_2, cpoServiceSlider_3]);
  

  const goToSubscription = ()=>{
    if(!signedToken)navigate('/login');
    else navigate('/cpo/subscription');
  }

  return (
    <div className='main-introduce-container'>
      {/* header */}
      <div className='main-introduce-header pricing'>
        <div className="main-introduce-bg">
          <img src={topBg} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>EMOOV 핵심 구독 상품</h1>
          <p>Small Office Pack에서 Core Pack까지, 단계별로 준비된 맞춤형 혜택 서비스<br/>당신의 비즈니스에 최적화된 상품을 찾아보세요.</p>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">
        <div className='main-introduce-pricing-container'>
          <PricingItem data={{
            title:"Small Office Pack",
            price:"5만원",
            monthly:true,
            quotes:[3], 
            desc:"소규모로 충전기를 운영하기 원하는 사업자\n또는\n충전기 시험을 위한 개발자를 위한 상품",
            button: true,
            bottomText:"지금 시작하기",
            clickEvent:goToSubscription,
            options:[
              "충전소 관리시스템",
              "모바일 충전 앱",
              "최대 5개의 충전기 운영제어",
            ]}
          }/>
          
          <PricingItem data={{
            title:"Starter Pack",
            price:"충전기대수",
            monthly:true,
            quotes:[4], 
            desc:"초기 저렴한 투자비용으로\n충전소 운영을 원하는\n충전사업자를 위한 상품",
            button: true,
            bottomText:"지금 시작하기",
            clickEvent:goToSubscription,
            options:[
              "충전소 관리시스템",
              "모바일 충전 앱",
              "운영하는 충전기 대수에 따른 비용청구",
              "충전기 연결을 위한 사용자 지원",
              `기본 사용료<color>1</color> 00원/월`,
            ]
          }}/>
          
          <PricingItem data={{
            title:"Core Pack",
            price:"충전기대수", 
            monthly:true, 
            quotes:[6],
            desc:"필수 충전소 운영 서비스 함께\n충전소 운영과 관련된 통계 데이터\n및 AI 서비스를 제공하는 상품",
            button: true,
            bottomText:"지금 시작하기",
            clickEvent:goToSubscription,
            options:[
              "충전소 관리시스템",
              "모바일 충전 앱",
              "운영하는 충전기 대수에 따른 비용청구",
              "충전기 연결을 위한 사용자 지원",
              "지역별 충전소 통계",
              "데이터 및 AI기반의 충전소 입지추천 서비스",
              `기본 사용료<color>1</color> 00원/월`
            ]}}/>
          
        </div>
        <div className='main-introduce-pricing-quotes-container'>
          <p>(1) 기본 사용료: 클라우드 컴퓨팅, 데이터베이스 그리고 네트워크 사용비를 포함합니다.</p>
        </div>
      </div>


      {/* header */}
      <div className='main-introduce-header pricing'>
        <div className="main-introduce-bg">
          <img src={topBg} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>모바일 앱 지원</h1>
          <p>무료로 이용가능한 앱부터 사용자 맞춤 신규개발까지<br/>당신의 비즈니스에 최적화된 상품을 찾아보세요.</p>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">
        <div className='main-introduce-pricing-container'>
          <PricingItem data={{
            title:"무료",
            price:"0 원",
            desc:"플랫폼을 이용하는 모든 충전사업자들은\nEMOOV 충전 앱이 무료",
            button: true,
            bottomText:"지금 시작하기",
            clickEvent:goToSubscription,
            options:[
              "구글플레이, 애플 앱스토어 모두 지원",
              "EV 회원을 위한 서비스 제공",
              "앱을 이용한 충전 시작/종료",
              "추가비용이 필요없는 지속적인 유지보수",
            ]}
          }/>
          
          <PricingItem data={{
            title:"앱 라벨링",
            price:"15 만원",
            quotes:[3],
            monthly: true, 
            desc:"무료로 제공하는 EMOOV 충전 앱의 피처와\n디자인 수정 없이 자체 브랜드로 배포\n가능한 모바일 앱이 필요한 사업자",
            button: true,
            bottomText:"지금 시작하기",
            clickEvent:goToSubscription,
            options:[
              "브랜드 이미지/이름 변경 가능",
              "안드로이드 및 애플 앱 스토어에서 고유의\n모바일 앱으로 등록 및 설치 가능",
              "유지보수 계약을 통한 앱 관리 가능",
              `초기개발 및 배포 비용 <color>1 </color> 별도 (000원/1회)`,
            ]
          }}/>
          
          <PricingItem data={{title:"신규 앱 개발",price:null, desc:"고유의 앱 디자인이나 기능을 필요로 하는\n사업자를 위한 상품",
            button: false,
            bottomText:"메일로 문의하기\n<monit@evmonit.com>",
            options:[]}}/>
          
        </div>
        <div className='main-introduce-pricing-quotes-container'>
          <p>(1) 초기개발비용에 포함된 내용: 안드로이드 개발자 계정 등록비용, iOS 개발자 계정 등록비용, 브랜드화 개발 비용</p>
        </div>
      </div>

      {/* header */}
      <div className='main-introduce-header pricing'>
        <div className="main-introduce-bg">
          <img src={topBg2} alt="" />
        </div>
        <div className="main-introduce-title-container">
          <h1>OCPP 프록시 서비스 상품</h1>
          <p>OCPP 메시지 중계를 위한 프록시 서비스를 운영하는 충전기 대수에 맞춰 사용해 보세요.<br/>당신의 비즈니스에 최적화된 상품을 찾아보세요.</p>
        </div>
      </div>
      {/* content */}
      <div className="main-introduce-content-section">
        <div className='main-introduce-pricing-container'>
          <PricingItem data={{
            title:"구독형",
            price:"최소 88 만원",
            monthly:true,
            quotes:[3], 
            desc:"초기의 높은 투자비용과 운영을 위한 고민없이 충전기의 대수에\n유동적으로 운영하고 싶은 경우",
            button: false,
            bottomText:"메일로 문의하기\n<monit@evmonit.com>",
            options:[
              "AWS 클라우드 인프라 사용비 (컴퓨팅서버, 데이터베이스, IP, 내외부 통신비 포함)",
              "운영지원 포함",
              "서비스 초기설정비용 별도 (2백만원/1회)",
              "충전기당 서비스 사용비용 별도 (1천원/기기)"
            ]
          }}/>         
          <PricingItem data={{
            title:"판매형",
            price:"문의필요",
            quotes:[3], 
            monthly:false,
            desc:"구독대신 사용기간을 설정하고 사업전략에 따라서 초기 비용투자를 통해\n서비스를 구매하여 운영하고 싶은 경우",
            button: false,
            bottomText:"메일로 문의하기\n<monit@evmonit.com>",
            options:[
              "AWS 클라우드를 통해 서비스 제공",
              "구매시 보증기간 3년 지원",
              "보증기간이후 별도의 유지보수계약을 통한 지원 가능",
              "사용대수에 따른 인프라 구축비용 및 서비스사용료가 결정"
            ],
            
          }}/>        
        </div>
      </div>



      

      <div className='main-introduce-dis-spacer'></div>



    </div>
  )
}
export default ServicePricing;




export const PricingItem = (props)=>{
  // props.quotes는 옵션 중에 특정 옵션을 인용문으로 사용할 때 사용 (태그를 인식함) -> 인덱스를 지정하면 됨
  return(
    <div className={'main-introduce-pricing-item'}>
      <div className='main-introduce-pricing-item-header'>
        <p>{props.data.title}</p>
        <pre>{props.data.desc}</pre>
      </div>
      <div className='main-introduce-pricing-item-price-info'>
        {
          props.data.price === null ?
          <p className=' empty'>문의 필요</p>
          :
          <>
            <p>{props.data.price}</p>
            {props.data.monthly&&<p>/월</p>}
          </>
        }
      </div>
      <div className='main-introduce-pricing-item-option-container'>
        {props.data.options?.map((it,idx)=>{
          return(
            <>
              <div className='main-introduce-pricing-item-option'>
                <img alt="" />
                { props.data.quotes?.filter((it)=>it == idx)[0] ?
                  <pre dangerouslySetInnerHTML={{ __html: it }}></pre>
                  :<pre>{it}</pre>
                }
              </div>
            </>
          )
        })}
      </div>
      {
        props.data.button === true ?
        <div className='main-introduce-pricing-item-button' onClick={props.data.clickEvent}><pre>{props.data.bottomText}</pre></div>
        :
        <div className='main-introduce-pricing-item-button plain'><pre>{props.data.bottomText}</pre></div>
      }

    </div>
  )
}