/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-19
 * @file 찾아줌 충전소 최적위치 시뮬레이션 결과페이지의 지도에 대한 범례 컴포넌트.
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import * as React from "react";
import * as ReactDOM from "react-dom";

const ZoomSimReportMapLegend = (prop) => {

    /********************************************************/
    /* VAR SECTION                                          */
    /********************************************************/
    const recommendedStationIcon = `${process.env.PUBLIC_URL}/img/high_recommended_station_icon.png`;
    const recommendedLocationIcon = `${process.env.PUBLIC_URL}/img/suggestion_location_pin_icon.png`;
    const stationIcon = `${process.env.PUBLIC_URL}/img/fast_electric_icon.png`;

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return (
        <div style={{
                marginLeft: '15px', 
                marginBottom: '20px', 
                marginTop: '15px',
                width: '97%'
            }} 
            className='e-card'>
            <div className='e-card-content'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px'
                }}>
                    <img src={recommendedStationIcon} width='25'/>추가설치추천 충전소위치
                    <img src={recommendedLocationIcon} width='25'/>신규설치 추천위치
                    <img src={stationIcon} width='25'/>기 설치된 충전소
                </div>
                <div style={{
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px'
                }}>
                    <div style={{
                        backgroundColor: '#001790',
                        padding: '8px',
                        borderRadius: '8px', 
                        fillOpacity: 0.3,
                        color: '#ffffff'
                        }}>
                        상업시설중심 거주지 및 번화가
                    </div>
                    <div style={{
                        backgroundColor: '#FFCC57',
                        padding: '8px',
                        borderRadius: '8px', 
                        fillOpacity: 0.3,
                        color: '#000000'
                        }}>
                        학군지 아파트단지
                    </div>
                    <div style={{
                        backgroundColor: '#0f5930',
                        padding: '8px',
                        borderRadius: '8px', 
                        fillOpacity: 0.3,
                        color: '#fff'
                        }}>
                        생활거주지
                    </div>
                    <div style={{
                        backgroundColor: '#813234',
                        padding: '8px',
                        borderRadius: '8px', 
                        fillOpacity: 0.3,
                        color: '#fff'
                        }}>
                        상대적으로 미개발지역 
                    </div>
                    <div style={{
                        backgroundColor: '#FF3E44',
                        padding: '8px',
                        borderRadius: '8px', 
                        fillOpacity: 0.3,
                        color: '#fff'
                        }}>
                        교외생활복지시설
                    </div>
                </div>
            </div>
        </div>        
    );
}

export default ZoomSimReportMapLegend;