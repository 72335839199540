import React, { useState, useEffect } from 'react';

import { emailInputValidation } from '../../util/util';

import { TfiClose } from "react-icons/tfi";

import { postSendEmail } from '../../api/account/accountApi';
import Lottie from "react-lottie";
import animationSplash from '../../lotties/loading2.json'

export const CpoGroupInvitation = (props) => {

  const emailInput = (target)=>{
    setEmailValidate(emailInputValidation(target.value));
  }

  const [emailValidate, setEmailValidate] = useState();

  const [email, setEmail] = useState();

  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  const reqPostSendEmail = async()=>{
    setSuccessMsg(false);
    setErrMsg(false);
    if(!email){
      setEmailValidate(false);
      return;
    }
    if(!emailValidate){
      return;
    }
    setLoadingOn(true);
    let result = await postSendEmail(email);
    setLoadingOn(false);
    if(result.status === 200){
      setSuccessMsg(true);
    }
    else{
      setErrMsg(true);
    }
    props.reqGetSendEmailList();
  }

  const [loadingOn, setLoadingOn] = useState(false);

  return (
    <div className="cpo-group-invitation-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-group-invitation-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-group-invitation-modal-header">
          <title>초대</title>
          <p>초대하고 싶은 상대방의 이메일을 입력하세요</p>
          <div className='cpo-group-invitation-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-group-invitation-modal-content">
          <p>일반 회원으로 가입한 이메일에 초대를 보낼 수 있습니다.</p>
          <div className="cpo-group-invitation-modal-content-wrapper marginTop">
            <p>상대방 이메일</p>
            <div className="cpo-group-invitation-modal-content-input-container">
              <input type="text" placeholder='상대방의 이메일을 입력하세요' value={email} onChange={(e)=>{emailInput(e.target); setEmail(e.target.value)}}/>
            </div>
            {emailValidate===false && <p className='alertMsg'>이메일 형식을 입력하세요.</p>}
            {errMsg && <p className='alertMsg'>이메일 발송에 실패하였습니다.</p>}
            {successMsg && <p className='alertMsg success'>이메일 발송에 성공하였습니다.</p>}
          </div>
        </div>
        <div className="cpo-group-invitation-modal-content-wrapper marginTop">
          <div className="cpo-group-invitation-modal-button-container">
            <div className={`cpo-group-invitation-modal-button ${!email || emailValidate===false ? "none":""}`} onClick={()=>{reqPostSendEmail();}}>
              {loadingOn ? <Loading/> :"초대"}
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}
export default CpoGroupInvitation



function Loading() {
  const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationSplash,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};	
return (
  <Lottie options={defaultOptions} />
)
}