import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { useNavigate, Routes, Route, Link, Switch, useParams, useLocation } from "react-router-dom";

import { timestampFormat, timestampFormat2, timestampFormat3} from '../../util/util';
import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";

import { MdInfoOutline } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";
import { MdClose } from "react-icons/md";


import { JsonView, allExpanded, darkStyles, defaultStyles, collapseAllNested } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import { getChargerInfo } from '../../api/infra/infraApi.js';
import { getChargingTransactions, getOccpMessageHistoriesByUniqueId, getOccpActionMessageHistoriesList } from '../../api/controlhub/controlhubApi.js';
import CpoPayReportSearchModal from '../../components/modals/CpoPayReportSearchModal.js';

const CpoPayReport = (props) => {

  let navigator = useNavigate();
  const params = useParams();
  const location = useLocation();
  const initDisplaySerialNumber = location.state?.DisplaySerialNumber;
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  
  

  useEffect(()=>{
    let val = location.pathname
    if(val.replace('/cpo/pay-report','').length !== 0)dispatch(setNum({id:10, idx:0, detail:true}));
    else dispatch(setNum({id:10, idx:0}));
  },[])

  // 트랜잭션 / 액션 탭 전환
  const [tabIdx, setTabIdx] = useState(0)
  
  // 검색 input value
  const [searchKeyword, setSearchKeyword] = useState();

  useEffect(()=>{
    reqChargingTransactions();
  },[])

  useEffect(()=>{
    reqChargingTransactions();
  },[params.id])

  // 총 페이지 갯수
  const [pageCount, setPageCount] = useState(null);

  // 한 번에 보일 컨텐츠 수
  let showContentCount = 15;

  
  
  // 트랜잭션
  const [resData, setResData] = useState([]);
  // 선택한 트랜잭션Id
  const [clickedTransactionId, setClickedTransactionId] = useState(null);
  // 액션 리스트
  const [resData2, setResData2] = useState([]);
  // 통신 로그
  const [actionList, setActionList] = useState();
  // 클릭한 액션 idx
  const [clickedActionIdx, setClickedActionIdx] = useState();
  // 액션 필터링리스트
  const [visibleActionList, setVisibleActionList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  // 트랜잭션 요청 함수
  let reqChargingTransactions = async()=>{
    console.log(params);
    let query = await makeQuery();
    let val = await getChargingTransactions(params.id?params.id:1, showContentCount, query);
    console.log(val)
    setPageCount(Math.ceil(val.total/showContentCount));
    setTotalCount(val.total);
    setResData([...val.results]);
  }
  let makeQuery = async()=>{
    let temp = ""
    if(DisplaySerialNumber) temp += `&ChargerDisplaySerialNumber=${DisplaySerialNumber}`;
    if(TagId)temp += `&TagId=${TagId}`;
    if(MinCreatedDate) temp += `&MinCreatedDate=${MinCreatedDate}`
    if(MaxCreatedDate) temp += `&MaxCreatedDate=${MaxCreatedDate}`
    if(MinUnitPrice) temp += `&MinUnitPrice=${MinUnitPrice}`
    if(MaxUnitPrice) temp += `&MaxUnitPrice=${MaxUnitPrice}`
    console.log(PaymentCompleted);
    if(PaymentCompleted===true || PaymentCompleted===false ) temp += `&PaymentCompleted=${PaymentCompleted}`
    return temp;
  }
  

  // 식별번호
  const [DisplaySerialNumber, setDisplaySerialNumber] = useState(initDisplaySerialNumber);
  // idTag
  const [TagId, setTagId] = useState();
  // 생성일
  const [MinCreatedDate, setMinCreatedDate] = useState();
  const [MaxCreatedDate, setMaxCreatedDate] = useState();
  // 단가
  const [MinUnitPrice, setMinUnitPrice] = useState();
  const [MaxUnitPrice, setMaxUnitPrice] = useState();
  // 결제 성공 여부
  const [PaymentCompleted, setPaymentCompleted] = useState();

  useEffect(()=>{
    // if(DisplaySerialNumber||TagId,MinCreatedDate||MaxCreatedDate||MinUnitPrice||MaxUnitPrice||(PaymentCompleted===true || PaymentCompleted===false))
    reqChargingTransactions();
  },[DisplaySerialNumber,TagId,MinCreatedDate,MaxCreatedDate,MinUnitPrice,MaxUnitPrice,PaymentCompleted])

  // 충전소 이름 요청 함수 트리거
  // useEffect(()=>{
  //   if(resData == null || resData == undefined || resData.length == 0)return;
  //   getStationInfoList();
  // },[resData])

  // 선택한 트랜잭션 -> 액션 리스트 요청 함수 트리거
  useEffect(()=>{
    if(clickedTransactionId==undefined || clickedTransactionId == null)return;
    getActionList();
  },[clickedTransactionId])

  // 액션 리스트 -> 로그 리스트 요청 함수 트리거
  useEffect(()=>{
    if(resData2 == null || resData2 == undefined)return;
    getLogList();
  },[resData2])



  // 트랜잭션 탭으로 전환시 클릭한 액션 index 초기화 트리거
  useEffect(()=>{
    if(tabIdx == 0){
      setClickedActionIdx(null)
    }
  },[tabIdx])


  const getActionList = async()=>{
    let val = await getOccpActionMessageHistoriesList(clickedTransactionId);
    setResData2([...val.results]);
  }

  // 액션 -> 로그 요청함수
  const getLogList = async()=>{
    let temp=[];
    for await(const it of resData2){
      let val = await getOccpMessageHistoriesByUniqueId(it.uniqueId);
      console.log(val);
      temp.push([...val.results]);
    }
    setActionList(temp);
  }
  


  // 현재 보일 첫 페이지 번호 반환 함수
  const getInitPage = (pageNum)=>{
    return (Math.ceil(pageNum/5)-1)*5+1;
  }
  // 현재 보일 마지막 페이지 번호 반환 함수
  const getEndPage = (pageNum)=>{
    return getInitPage(pageNum)+5;
  }
  // 페이지 이동함수
  const movePage = (page)=>{
    navigator(`/cpo/pay-report/${page}`, {state: {detail:true}});
  }
  // 페이지 선택자 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = params.id?params.id:1;
    let initPage = getInitPage(params.id?params.id:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    return result;
  }
  // 검색 모달 OnOff state
  const [modalOn, setModalOn] = useState();

  const [submitTrigger, setSubmitTrigger] = useState(false);

  useEffect(()=>{
    if(submitTrigger){
      makeQuery();
      setSubmitTrigger(false);
    }
  },[submitTrigger])


  return (
    <div className='cpo-pay-report-container'>
      {modalOn&&<CpoPayReportSearchModal setOnOff={setModalOn} tabIdx={tabIdx} 
      DisplaySerialNumber={DisplaySerialNumber} setDisplaySerialNumber={setDisplaySerialNumber}
      TagId={TagId} setTagId={setTagId}
      MinCreatedDate={MinCreatedDate} setMinCreatedDate={setMinCreatedDate}
      MaxCreatedDate={MaxCreatedDate} setMaxCreatedDate={setMaxCreatedDate}
      MinUnitPrice={MinUnitPrice} setMinUnitPrice={setMinUnitPrice}
      MaxUnitPrice={MaxUnitPrice} setMaxUnitPrice={setMaxUnitPrice}
      PaymentCompleted={PaymentCompleted} setPaymentCompleted={setPaymentCompleted}
      actionData={resData2} visibleActionList={visibleActionList} setVisibleActionList={setVisibleActionList}/>}
      <div className="cpo-pay-report-inner">

        <div className={"cpo-pay-report-content-container "}>
          <div className="cpo-pay-report-content-search-container">
            {tabIdx == 1&&<div className='cpo-pay-report-back-button' onClick={()=>{setTabIdx(0)}}><FaChevronLeft/><p>뒤로가기</p></div>}
            <div className="cpo-pay-report-content-search-filter-button" onClick={()=>{setModalOn(true)}}>검색필터<PiSlidersHorizontal/></div>

            {/* {tabIdx == 0&&<div className="cpo-pay-report-content-search-input-container">
              <input type="text" placeholder='이름, 넘버, idTag를 키워드로 검색하기' value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
              <FaSearch/>
            </div>} */}
            

            {/* DisplaySerialNumber
            TagId
            MinCreatedDate
            MaxCreatedDate
            MinUnitPrice
            MaxUnitPrice
            PaymentCompleted */}
            <div className='cpo-log-report-filter-item-container'>
              {DisplaySerialNumber ? <div className='cpo-log-report-filter-item'>식별번호: {DisplaySerialNumber}<MdClose onClick={()=>{setDisplaySerialNumber(null); setSubmitTrigger(true);}}/></div> : null}
              {TagId ? <div className='cpo-log-report-filter-item'>회원 id: {TagId}<MdClose onClick={()=>{setTagId(null); setSubmitTrigger(true);}}/></div> : null}
              {MinCreatedDate || MaxCreatedDate ? <div className='cpo-log-report-filter-item'>{
                `${MinCreatedDate ? timestampFormat3(MinCreatedDate): ""} ~ ${MaxCreatedDate ? timestampFormat3(MaxCreatedDate):""}`
              }<MdClose onClick={()=>{setMinCreatedDate(null);   setMaxCreatedDate(null); setSubmitTrigger(true);}}/></div> : null}
              {MinUnitPrice || MaxUnitPrice ? <div className='cpo-log-report-filter-item'>{
                `${MinUnitPrice ? `${MinUnitPrice}원` : ""} ~ ${MaxUnitPrice ? `${MaxUnitPrice}원`:""}`
              }<MdClose onClick={()=>{setMinUnitPrice(null);   setMaxUnitPrice(null); setSubmitTrigger(true);}}/></div> : null}
              {(PaymentCompleted === true || PaymentCompleted === false) ? <div className='cpo-log-report-filter-item'>{PaymentCompleted === true ? "결제 성공" : "결제 실패"}<MdClose onClick={()=>{setPaymentCompleted(null); setSubmitTrigger(true);}}/></div> : null}

            </div>
            

          </div>

          {
            tabIdx == 1&&
            <>
              <div className="cpo-pay-report-content-item-column pay-tab0 readOnly">
                <div>충전기식별번호</div>
                <div>트랜잭션 ID</div>
                <div>회원 idTag</div>
                <div>결제일자</div>
                <div>결제성공여부</div>
                <div>결제금액</div>
                <div>시작 meter</div>
                <div>종료 meter</div>
                <div>단가</div>
              </div>
              <ChargingLogListItem readOnly={true} data={resData.filter(it=> it.ocppTransactionId ==clickedTransactionId)[0]}/>
            </>
          }


          <div className={'cpo-pay-report-item-list-grid-container '+`${tabIdx == 1 ? 'grid':""}`}>
              <div className="cpo-pay-report-content-item-list-container">
                {
                  tabIdx == 0?
                
                  <div className="cpo-pay-report-content-item-column pay-tab0">
                    <div>충전기식별번호</div>
                    <div>트랜잭션 ID</div>
                    <div>회원 idTag</div>
                    <div>결제일자</div>
                    <div>결제성공여부</div>
                    <div>결제금액</div>
                    <div>시작 Meter</div>
                    <div>종료 Meter</div>
                    <div>단가</div>
                  </div>
                  :
                  <div className="cpo-pay-report-content-item-column pay-tab1">
                    <div>트랜잭션 ID</div>
                    <div>통신 날짜</div>
                    <div>액션명</div>
                  </div>
                }

                {
                  tabIdx == 0 && resData?.map((it, idx)=>{
                    return(
                      <ChargingLogListItem key={idx} data={it} idx={idx} setTabIdx={setTabIdx} setClickedTransactionId={setClickedTransactionId} />
                    );
                  })
                }
                {
                  tabIdx == 1 && actionList && resData2?.map((it, idx)=>{
                    if(visibleActionList.length == 0 || (visibleActionList.filter(ia=>ia.action == it.action)[0]))
                    return(
                      <div key={idx} className={'cpo-pay-report-content-item pay-tab1 '+`${clickedActionIdx == idx?"clicked":""}`} onClick={()=>{setClickedActionIdx(idx)}}>
                        <div><p>{clickedTransactionId}</p></div>
                        <div><p>{timestampFormat(new Date(it.createdDate))}</p></div>
                        <div><p>{it.action}</p></div>
                      </div>
                    );
                  })
                }
              </div>
              {
                tabIdx == 1?
                <>
                  <div className='cpo-pay-report-detail-container'>
                    {
                      actionList[clickedActionIdx] ?
                        actionList[clickedActionIdx]?.map((it,idx)=>{
                          return(
                          <div key={idx}>
                            <div className="cpo-pay-report-occp-action-header">
                              <div className={'cpo-pay-report-occp-action-result-badge '+`${it.messageType == "CALL"? "request" :it.messageType == "CALLRESULT"? "confirm" :it.messageType == "CALLERROR"? "error" :""} }`}>
                                {it.messageType == "CALL"? "REQUEST" :it.messageType == "CALLRESULT"? "CONFIRM" :it.messageType == "CALLERROR"? "ERROR" :"-"}
                              </div>
                              <div>{timestampFormat2(new Date(it.timestamp))}</div>
                            </div>
                            <div className='cpo-pay-report-json-container json-container'>
                              <JsonView data={JSON.parse(it.payload)} shouldExpandNode={collapseAllNested}clickToExpandNode={true} style={darkStyles} />
                            </div>
                          </div>
                          )
                        })
                      :
                      <div className='cpo-pay-report-detail-info'>
                        액션을 선택해주세요
                      </div>
                    }
                  </div>
                </>
                :<></>
              }
          </div>
          {
            tabIdx == 0 &&
            <div className='page-selector-container'>
              {/* {(params.id>5) &&<FaChevronLeft onClick={()=>{movePage(getInitPage(params.id?params.id:1) -1)}}/>}
              {pageSelectorRendering()}
              {(getEndPage(params.id?params.id:1) < pageCount) &&<FaChevronRight onClick={()=>{movePage(getEndPage(params.id?params.id:1))}}/>} */}

              {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${ (params.id==="1" || !(params.id))?"disabled":""}`}/>}
              {<CgChevronLeft onClick={()=>{movePage(getInitPage(params.id?params.id:1) -1)}} className={`${params.id>5?"":"disabled"}`}/>}
              {pageSelectorRendering()}
              {<CgChevronRight onClick={()=>{movePage(getEndPage(params.id?params.id:1)+2)}} className={`${(getEndPage(params.id?params.id:1) < pageCount-1)?"":"disabled"}`}/>}
              {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${params.id === pageCount || pageCount<=1 ? "disabled":""}`}/>}
              <div className='page-total-info'>{`Total : ${totalCount}`}</div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default CpoPayReport;


const ChargingLogListItem = (props)=>{

  const calPayment = (start, end, rate)=>{
    let val =  (parseFloat(end) - parseFloat(start))*parseFloat(rate);
    return isNaN(val)?"-":val+ "원";
  }

  return(
    <div className={'cpo-pay-report-content-item pay-tab0 '+`${props.readOnly?"readOnly":""}`} onClick={()=>{ props.setTabIdx(1); props.setClickedTransactionId(props.data.ocppTransactionId);}}>
      <div><p>{props.data.charger.displaySerialNumber}</p></div>
      <div><p>{props.data.ocppTransactionId}</p></div>
      <div><p>{props.data.tag.idTag}</p></div>
      <div><p>{timestampFormat(new Date(props.data.createdDate))}</p></div>
      <div>
        <div className={'cpo-pay-report-content-item-payment ' + `${props.data.paymentCompleted ? "success":"failure"}`}><p>{props.data.paymentCompleted ? "성공":"실패"}</p></div>
      </div>
      <div><p>{calPayment(props.data.meterStart, props.data.meterStop, props.data.unitPrice)}</p></div>
      <div><p>{isNaN(props.data.meterStart)?"-":props.data.meterStart}</p></div>
      <div><p>{props.data.meterStop?props.data.meterStop:"-"}</p></div>
      <div><p>{props.data.unitPrice}</p></div>
    </div>
    )
}














/*
{
  "chargingTransactionId": 48,
  "charger": {
      "chargerId": "7a7f8620-9944-401b-8516-def567131fec",
      "displaySerialNumber": "L81296"
  },
  "tag": {
      "idTag": "9313339593609980",
      "expiredDate": "2029-08-29T07:04:06",
      "useYn": "Y",
      "blockYn": "N",
      "deleteYn": "N"
  },
  "ocppTransactionId": 177,
  "unitPrice": 100,
  "meterStart": 0,
  "meterStop": 1,
  "orderId": null,
  "PaymentCompleted": true,
  "paymentCompleted": false,
  "ocppActionHistories": [
      {
          "ocppActionHistoryId": "0e69b987-c3cd-44af-ab8d-77246f2ebfa7",
          "charger": {
              "chargerId": "7a7f8620-9944-401b-8516-def567131fec",
              "displaySerialNumber": "L81296"
          },
          "uniqueId": "491302bf-36ca-428b-9630-b12929d5e5d2",
          "action": "StatusNotification",
          "createdDate": "2024-09-24T11:20:32.015246",
          "lastModifiedDate": "2024-09-24T11:20:32.018539",
          "chargingTransactionId": 48
      },
      {
          "ocppActionHistoryId": "3a6caabd-1654-4d47-980e-813c9ad9044c",
          "charger": {
              "chargerId": "7a7f8620-9944-401b-8516-def567131fec",
              "displaySerialNumber": "L81296"
          },
          "uniqueId": "780ac8d9-2f7c-42a5-9487-79e134fbc4e2",
          "action": "StopTransaction",
          "createdDate": "2024-09-24T11:20:34.776723",
          "lastModifiedDate": "2024-09-24T11:20:34.780491",
          "chargingTransactionId": 48
      },
      {
          "ocppActionHistoryId": "725066c3-c4b1-4025-b597-7517b1535f65",
          "charger": {
              "chargerId": "7a7f8620-9944-401b-8516-def567131fec",
              "displaySerialNumber": "L81296"
          },
          "uniqueId": "2d555e34-2174-47bd-8a45-9f47639758df",
          "action": "MeterValues",
          "createdDate": "2024-09-24T11:20:33.606578",
          "lastModifiedDate": "2024-09-24T11:20:33.609325",
          "chargingTransactionId": 48
      }
  ],
  "createdDate": "2024-09-24T11:20:28.211113",
  "lastModifiedDate": "2024-09-24T11:20:34.838905"
}


*/