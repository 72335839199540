import React, { useState, useEffect } from 'react';
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';

import { putActionOfDataTransferMessage } from '../../api/controlhub/controlhubApi.js';

export const DataTransferFunctionModal = (props) => {

  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const [chasName, setChasName] = useState();
  const [chasDesc, setChasDesc] = useState();
  const [chasValid, setChasValid] = useState(null);

  const dupCheck = async(chasName)=>{
    if(props.dataTransferMsgNameList.filter(it=>it === "DATA_TRANSFER_REQ_"+chasName).length === 0){
      setChasValid(true);
    }
    else setChasValid(false);
  }


  const reqAddMessage = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "controlHubActionInfoIds": [1],
      "dataTransferMessageId": chasName,
    }
    let result = await putActionOfDataTransferMessage(props.actionSetId, "DATA_TRANSFER_REQ", reqObj);
    if(result.status === 200 || result.status === 201){
      await props.reqGetChas();
      props.setOnOff(false);
    }
    else{
      setErrMsg("추가에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }


  return (
    <div className="datatransfer-function-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="datatransfer-function-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>{props.mode==="append" ? "DataTransfer 메세지 추가":"DataTransfer 메세지 편집"}</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='datatransfer-function-modal-content'>
          {
            props.mode==="append" &&
            <>
              <div className='cpo-custom-chas-append-modal-content-container'>
                <p>데이터 명</p>
                <div className='cpo-custom-chas-append-modal-grid-wrapper'>
                  <div className='cpo-custom-chas-append-modal-input-container'>
                    <input type="text" placeholder='데이터 명을 입력해주세요' value={chasName} onChange={(e)=>{setChasName(e.target.value);setChasValid(null)}}/>
                  </div>
                  <div className='cpo-custom-chas-append-check-button ' onClick={()=>{dupCheck(chasName)}}>
                    {loadingOn ? 
                    <Loading/>
                    :"중복확인"}
                  </div>
                  {(chasName =="" || chasValid === null) &&<p className='cpo-custom-chas-append-check-info'>중복확인을 눌러 주세요.</p>}
                  {chasValid === false &&<p className='cpo-custom-chas-append-check-info'>중복된 이름입니다.</p>}
                  {chasValid === true && <p className='cpo-custom-chas-append-check-info valid'>사용가능한 이름입니다.</p>}
                </div>
              </div>
              <div className='cpo-custom-chas-append-modal-content-container'>
                <p>설명</p>
                <div className='cpo-custom-chas-append-modal-input-container textarea'>
                  <textarea type="text" placeholder='설명을 입력해주세요' value={chasDesc} onChange={(e)=>{setChasDesc(e.target.value);}} />
                </div>
              </div>
              {errMsg && <p className='datatransfer-function-modal-error-msg'>{errMsg}</p>}
              <div className={`datatransfer-function-modal-button ${chasValid && !(chasDesc==undefined || chasDesc ==null || chasDesc == "") ? "":"disabled"}`} onClick={()=>{
                reqAddMessage();
                }}>
                
                {
                loadingOn ? 
                  <Loading/>
                :
                <>
                  <p>추가하기</p>
                </>
                }
              </div>
            </>
          }

        </div>

      </div>
    </div>
  )
}
export default DataTransferFunctionModal;