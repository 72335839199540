/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-08
 * @file 찾아줌 충전소 최적위치 시뮬레이션 요청 팝업 모달 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useState, useRef, useEffect } from 'react';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { postZoomStatRequest } from '../../../api/zoom/zoomApi';
import data from '../../../data/localData.json';


export const ZoomStatRequestModal = (props) => {

    /********************************************************/
    /* VAR SECTION                                          */
    /********************************************************/
    let userSystemId = localStorage.getItem('user-systemId');

    const [zcodeValue, setZcodeValue] = useState('');
    const [zcodeText, setZcodeText] = useState('');
    const [sigCodeValue, setSigCodeValue] = useState('');
    const [filteredSigData, setFilteredSigData] = useState([]);

    /// 드롭다운 컴포넌트의 입력값의 필드 선언
    const dataFields = {text: 'label', value: 'code'};
    /// 드롭다운 컴포넌트 초기값 설정 
    const zcodeData = data['zcode'];
    const sigData = data['sigcode'];
    /// 날짜선택 컴포넌트에서 선택가능한 최소날짜 선언
    const selectableMinDate = new Date(2021,0,1);
    /// 날짜선택 컴포넌트 레퍼런스 
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);

    /********************************************************/
    /* STATE SECTION                                        */
    /********************************************************/
    /// 팝업 대화상자 활성화 상태
    const [visibleDialog, setVisibleDialog] = useState(props.visible);
    /// 입력값 검사 결과 값 
    const [disableOkButton, setDisableOkButton] = useState(true);
    
    /********************************************************/
    /* EFFECT SECTION                                       */
    /********************************************************/
    useEffect(() => {
        setVisibleDialog(props.visible);
    }, [props.visible]);

    /********************************************************/
    /* HANDLER SECTION                                      */
    /********************************************************/
    /// OK 버튼 활성화 체크 함수
    const okButtonEnableCheck = () => {
        if(zcodeValue === '' || sigCodeValue === '') {
            setDisableOkButton(true);
            return;
        }
        if(startDatePickerRef.current.value === '' || 
            endDatePickerRef.current.value === '') {
            setDisableOkButton(true);
            return;
        }
        
        setDisableOkButton(false);
    };

    /// 요청 대화상자의 버튼에 대한 핸들러 
    const dialogButtons = [
        {
            buttonModel: {
                content: '신청',
                cssClass: 'e-flat',
                isPrimary: false,
                disabled: disableOkButton
            },
            click: async() => {
                const startDate = startDatePickerRef.current.value;
                const endDate = endDatePickerRef.current.value;
                
                const startMonthStr = String(startDate.getMonth()+1).padStart(2,'0');
                const startDayStr = String(startDate.getDate()).padStart(2,'0');
                const startDateStr = `${startDate.getFullYear()}-${startMonthStr}-${startDayStr}`;

                const endMonthStr = String(endDate.getMonth()+1).padStart(2,'0');
                const endDayStr = String(endDate.getDate()).padStart(2,'0');
                const endDateStr = `${endDate.getFullYear()}-${endMonthStr}-${endDayStr}`;

                await postZoomStatRequest ({
                    userSystemId: userSystemId,
                    sigCode: sigCodeValue,
                    startDate: startDateStr,
                    endDate: endDateStr,
                });
                setVisibleDialog(false);
                props.closeDialogCallback(true);
            }
        },
        {
            buttonModel: {
                content: '닫기',
                cssClass: 'e-flat',
            },
            click: () => {
                setVisibleDialog(false);
                props.closeDialogCallback(false);
            }
        }
    ];

    /// 시도 목록 콤보박스 선택 변경 이벤트 핸들러 
    const onChangeZcode = (args) => {
        setZcodeValue(args.itemData === null ? 'null' : args.itemData[dataFields.value].toString());
        setZcodeText(args.itemData === null ? 'null' : args.itemData[dataFields.text].toString());

        const temp = sigData.filter(item => item.code.startsWith(args.itemData[dataFields.value].toString()));
        setFilteredSigData(temp);
        okButtonEnableCheck();
        //console.log(filteredSigData);
    };

    /// 군구 목록 콤보박스 선택 변경 이벤트 핸들러 
    const onChangeSigCode = (args) => {
        setSigCodeValue(args.itemData === null ? '' : args.itemData[dataFields.value].toString());
        okButtonEnableCheck();
    };

    /// 시작날짜 선택 변경 이벤트 핸들러 
    const onChangeStartDate = (args) => {
        okButtonEnableCheck();
    }

    /// 종료날짜 선택 변경 이벤트 핸들러 
    const onChangeEndDate = (args) => {
        okButtonEnableCheck();
    }

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return(
        <div>
            <DialogComponent width='500px' height='300px' header='충전관련 통계자료 요청'
                isModal={true} 
                visible={visibleDialog}
                showCloseIcon={true} 
                close={props.closeDialogCallback}
                buttons={dialogButtons}>
                <div style={{display:'flex', flexDirection:'column', height:'100%', padding:'10px'}}>
                    <div style={{marginBottom:'10px'}}>
                        <DropDownListComponent placeholder='시/도를 선택해 주세요.' popupHeight='220px'
                            change={onChangeZcode}
                            value={zcodeValue}
                            fields={dataFields}
                            dataSource={zcodeData}/>
                    </div>
                    <div style={{marginBottom:'10px'}}>
                        <DropDownListComponent placeholder='군/구를 선택해 주세요.' popupHeight='220px'
                            change={onChangeSigCode}
                            value={sigCodeValue}
                            fields={dataFields}
                            dataSource={filteredSigData}/>
                    </div>
                    <div style={{marginBottom:'10px'}}>
                        <DatePickerComponent id='startDatePicker' placeholder='통계자료 시작일을 선택해 주세요.' format='yyyy-MM-dd'
                            change={onChangeStartDate}
                            min={selectableMinDate}
                            ref={startDatePickerRef}/>
                    </div>
                    <div style={{marginBottom:'10px'}}>
                        <DatePickerComponent id='endDatePicker' placeholder='통계자료 종료일을 선택해 주세요.' format='yyyy-MM-dd' 
                            change={onChangeEndDate}
                            ref={endDatePickerRef}/>
                    </div>                    
                    <div style={{marginLeft:'10px', marginRight:'10px', marginTop: 'auto'}}>
                    </div>                     
                </div>
            </DialogComponent>
        </div>
    );
}

export default ZoomStatRequestModal;