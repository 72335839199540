/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-10
 * @file 찾아ZooM 시뮬레이션결과로 전시된 지도에서 특정 그리드에 대한 상세정보를 전시하는 페이지 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setNum } from '../../store.js';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FaChevronLeft, FaShoppingBag } from "react-icons/fa"; 
import { MapContainer, TileLayer, Polygon, useMap, Marker, Popup, Icon } from 'react-leaflet';
import L from 'leaflet';
import { ColumnDirective, 
    ColumnsDirective, 
    GridComponent, 
    Inject,
    Resize, 
    Page, 
    Selection } from '@syncfusion/ej2-react-grids';
import 'leaflet/dist/leaflet.css';
import * as ZoomApi from '../../api/zoom/zoomApi.jsx';  
import ZoomSimGridReportPoiSummaryCard from '../../components/fragments/zoom/ZoomSimGridReportPoiSummaryCard.jsx';

const CpoStationZoomSimGridReport = () => {

    /********************************************************/
    /* VAR SECTION                                          */
    /********************************************************/
    let dispatch  = useDispatch();
    let userSystemId = localStorage.getItem('user-systemId');

    const params = useParams();
    const navigator = useNavigate();
    const location = useLocation();
    //console.log(location.state);
    //console.log(JSON.parse(localStorage.getItem('feature')));
    const selectedGridFeature = JSON.parse(localStorage.getItem('feature'));
    //console.log(selectedGridFeature);
    const gridPolygonCoorinates = [
        [selectedGridFeature.geometry.coordinates[0][0][1],selectedGridFeature.geometry.coordinates[0][0][0]],
        [selectedGridFeature.geometry.coordinates[0][1][1],selectedGridFeature.geometry.coordinates[0][1][0]],
        [selectedGridFeature.geometry.coordinates[0][2][1],selectedGridFeature.geometry.coordinates[0][2][0]],
        [selectedGridFeature.geometry.coordinates[0][3][1],selectedGridFeature.geometry.coordinates[0][3][0]],
        [selectedGridFeature.geometry.coordinates[0][4][1],selectedGridFeature.geometry.coordinates[0][4][0]]
    ];
    const clusterTypeId = selectedGridFeature.properties.clusterType;
    //console.log('[EMOOV]군집유형:', clusterTypeId);
    const chargerStationMarkerIcon = L.icon({
        iconUrl: `${process.env.PUBLIC_URL}/img/fast_electric_icon.png`,
        iconSize: [30,35],
        //iconAnchor: [20,40],
    });
    
    /********************************************************/
    /* STATE SECTION                                        */
    /********************************************************/
    // const [centerLat, setCenterLat] = useState();
    // const [centerLng, setCenterLng] = useState(); 
    const [centerLatLng, setCenterLatLng] = useState([
        selectedGridFeature.geometry.coordinates[0][0][1],
        selectedGridFeature.geometry.coordinates[0][0][0]
    ]);
    // 대한민국 위경도 값 
    const [mapBounds, setMapBounds] = useState(null); //useState([33.0, 124.6],[38.6, 131.9]);   
    const [isLoading, setIsLoading] = useState(false);
    const [clusterTypeLabel, setClusterTypeLabel] = useState('');
    const [chargerStations, setChargerStations] = useState([]);
    const [chargerStationMarkers, setChargerStationMarkers] = useState([]);
    const [population, setPopulation] = useState('');
    const [landPrice, setLandPrice] = useState('');
    const [commercialPoi, setCommercialPoi] = useState([]);
    const [accomodationPoi, setAccomodationPoi] = useState([]);
    const [tourPoi, setTourPoi] = useState([]);
    const [educationPoi, setEducationPoi] = useState([]);
    const [coveniencePoi, setConveniencePoi] = useState([]);
    const [businessPoi, setBusinessPoi] = useState([]);
    const [outdoorLeisurePoi, setOutdoorLeisurePoi] = useState([]);
    const [religionPoi, setReligionPoi] = useState([]);
    const [residentialPoi, setResidentialPoi] = useState([]);
    const [ectPoi, setEtcPoi] = useState([]);

    /********************************************************/
    /* EFFECT SECTION                                       */
    /********************************************************/
    useEffect(() => {
        dispatch(setNum({id:101, report:true}));
    },[]);
    useEffect(() => {
        /// 그리드에 포함되어야할 일반적인 정보를 가져온다. 
        getGridDataAsync();
        /// 그리드의 지도범위를 설정한다.
        getGridBoundaryMapData();
        setIsLoading(true);
    },[]);

    useEffect(() => {
        if(clusterTypeId === 'CommercialResidentialBusyArea') {
            setClusterTypeLabel('상업시설중심 거주지 및 번화가');
        }
        else if(clusterTypeId === 'SchoolDistrictApartmentComplex'){
            setClusterTypeLabel('학군지 및 아파트단지');
        }
        else if(clusterTypeId === 'ResidentialArea'){
            setClusterTypeLabel('생활거주지');
        }
        else if(clusterTypeId === 'UnderdevelopedArea'){
            setClusterTypeLabel('개발중인 지역');
        }
        else if(clusterTypeId === 'SubunbanWelfareFacility'){
            setClusterTypeLabel('교외생활시설 및 복지시설');
        }
    },[clusterTypeId])

    // useEffect(() => {
    //     const map = useMap();
    //     map.setView(centerLatLng, map.getZoom());
    // }, [centerLatLng])

    /********************************************************/
    /* HANDLER SECTION                                      */
    /********************************************************/
    const ChangeMapCenter = ({ center }) => {
        const map = useMap();
        map.setView(center, map.getZoom()); // 중심 좌표를 변경
        return null;
    };

    /// Grid 데이터 불러오기  
    const getGridDataAsync = async() => {
        let rawData = await ZoomApi.getGridPropertyData(params.zscode, params.gid);

        // 생산가능인구 
        setPopulation(rawData.workingPopulation.toLocaleString('ko-KR'));

        // 공시지가
        setLandPrice(rawData.officialLandPrice.toLocaleString('ko-KR'));

        // 상업 POI 개수 
        setCommercialPoi([
            {'poiName': 'cafe', 'value': rawData.cafe},
            {'poiName': 'restaurant', 'value': rawData.restaurant},
            {'poiName': 'spaceRental', 'value': rawData.spaceRental},
            {'poiName': 'theater', 'value': rawData.theater},
            {'poiName': 'leisureSports', 'value': rawData.leisureSports},
            {'poiName': 'beauty', 'value': rawData.beauty},
            {'poiName': 'shopping', 'value': rawData.shopping},
            {'poiName': 'studyCafe', 'value': rawData.studyCafe},
            {'poiName': 'medicalOthers', 'value': rawData.medicalOthers},
            {'poiName': 'pub', 'value': rawData.pub},
            {'poiName': 'kidsCafe', 'value': rawData.kidsCafe},
            {'poiName': 'familyRestaurant', 'value': rawData.familyRestaurant},
            {'poiName': 'fastFood', 'value': rawData.fastFood},
        ]);
        // 숙박 POI 개수 
        setAccomodationPoi([
            {'poiName': 'hotel', 'value': rawData.hotel},
            {'poiName': 'accommodation', 'value': rawData.accommodation},
            {'poiName': 'condo', 'value': rawData.condo},
        ]);
        // 여행/문화  POI 개수
        setTourPoi([
            {'poiName': 'culturalFacilities', 'value': rawData.culturalFacilities},
            {'poiName': 'touristAttraction', 'value': rawData.touristAttraction},
            {'poiName': 'culture', 'value': rawData.culture},
            {'poiName': 'aquarium', 'value': rawData.aquarium},
            {'poiName': 'themeStreet', 'value': rawData.themeStreet},
            {'poiName': 'cafeStreet', 'value': rawData.cafeStreet},
        ]);
        // 교육 POI 개수
        setEducationPoi([
            {'poiName': 'university', 'value': rawData.university},
            {'poiName': 'school', 'value': rawData.elementaryMiddleHigh},
            {'poiName': 'educationalFacilities', 'value': rawData.educationalFacilities},
            {'poiName': 'academy', 'value': rawData.academy},
        ]);        
        // 생활편의 POI 개수
        setConveniencePoi([
            {'poiName': 'finance', 'value': rawData.finance},
            {'poiName': 'pharmacy', 'value': rawData.pharmacy},
            {'poiName': 'hospital', 'value': rawData.hospital},
            {'poiName': 'parkingLot', 'value': rawData.parkingLot},
            {'poiName': 'dailyConvenience', 'value': rawData.dailyConvenience},
            {'poiName': 'park', 'value': rawData.park},
            {'poiName': 'cultureCenter', 'value': rawData.cultureCenter},
            {'poiName': 'realEstate', 'value': rawData.realEstate},
            {'poiName': 'subway', 'value': rawData.subway},
            {'poiName': 'convenienceStore', 'value': rawData.convenienceStore},
        ]);
        // 사업 POI 개수 
        setBusinessPoi([
            {'poiName': 'publicInstitution', 'value': rawData.publicInstitution},
            {'poiName': 'company', 'value': rawData.company},
            {'poiName': 'researchFacilities', 'value': rawData.researchFacilities},
        ]);
        // 야외 여가 POI 개수 
        setOutdoorLeisurePoi([
            {'poiName': 'golfCourse', 'value': rawData.golfCourse},
            {'poiName': 'concertHall', 'value': rawData.concertHall},
            {'poiName': 'fishingSpot', 'value': rawData.fishingSpot},
            {'poiName': 'amusementFacilities', 'value': rawData.amusementFacilities},
        ]);        
        // 종교시설 POI 개수 
        setReligionPoi([
            {'poiName': 'church', 'value': rawData.church},
            {'poiName': 'cathedral', 'value': rawData.cathedral},
            {'poiName': 'temple', 'value': rawData.temple},
        ]);        
        // 주거시설 
        setResidentialPoi([
            {'poiName': 'apartment', 'value': rawData.apartment},
            {'poiName': 'residentialFacilities', 'value': rawData.residentialFacilities},
        ]);
        // 기타 (복지시설, 주유소, 휴게소 )
        setEtcPoi([
            {'poiName': 'welfareFacilities', 'value': rawData.welfareFacilities},
            {'poiName': 'gasStation', 'value': rawData.gasStation},
            {'poiName': 'restArea', 'value': rawData.restArea},
        ]);

        // 충전소 목록으로 마커정보 만들기 
        const markers = [];
        rawData.chargers.forEach((chargerStation) => {
            const marker = {
                position: [chargerStation.lat, chargerStation.lng],
                statNm: chargerStation.statnm,
                address: chargerStation.addr,
                cpoNm: chargerStation.bnm,
            };
            markers.push(marker); 
        });
        // 충전소 목록 업데이트 
        setChargerStations(rawData.chargers);
        setChargerStationMarkers(markers);
    };

    /// Grid를 중심으로 지도에 표시될 지리 정보 불러오기 
    const getGridBoundaryMapData = async() => {
        let rawData = await ZoomApi.getGridBoundaryMapData(params.zscode, params.gid);
        const swPoint = [rawData.boundingBox.swLat, rawData.boundingBox.swLng];
        const nePoint = [rawData.boundingBox.neLat, rawData.boundingBox.neLng];
        const bound = [swPoint, nePoint];
        const centerPoint = [rawData.center.centerLat, rawData.center.centerLng];
        setMapBounds(bound);
        setCenterLatLng(centerPoint);
    }

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return(
        <div style={{height:'100%'}}>
            {/* 목록으로 돌아가기 네비게이션 */}
            <div style={{
                    marginBottom: '15px', 
                    color:'#2FB4D9',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content'}}>
                <FaChevronLeft/>
                <p onClick={()=>{navigator('/cpo/station-zoom')}}>목록으로 이동</p>
                <FaChevronLeft/>
                <p onClick={()=>{navigator(-1)}}>보고서로 이동</p>
            </div>
            {/* 개요 세션 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px'
                }}>
                <h1>'{params.gid}' 구역 상세 내용 </h1>
            </div> 
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '15px'
                }}>
                <ul>
                    <li>이 지역의 지역적 특성 분류는 [{clusterTypeLabel}] 입니다.</li>
                    <li>이 지역의 평균 생산가능인구는 {population}명 입니다. </li>
                    <li>이 지역의 평균 공시지가는 {landPrice}원 입니다.</li>
                </ul>
            </div>
            <hr/>
            <div style={{display:'flex', flexDirection:'column', padding: '10px', width: '100%', height: '100%'}}>
                <h4>선택한 구역의 상세지도와 반경 500m안에 설치된 충전소 목록</h4>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'top',
                    gap: '15px'}}>
                    <div className='e-card' style={{width: '55%'}}>
                        <div className='e-card-content' style={{height: '100%'}}>
                            {isLoading === true && <MapContainer style={{height: '600px', width: '100%'}}
                                center={centerLatLng} 
                                maxBounds={mapBounds}
                                maxBoundsViscosity={0.8}
                                zoom={17}
                                minZoom={16}
                                maxZoom={18} 
                                scrollWheelZoom={false}>
                                <ChangeMapCenter center={centerLatLng}/>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    //url="https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=rXoTIkSzGZ8Jf181Ma1IWdTUaWboVPRhrR7pTwplUPE3KbORdTF0qj5hTzw1W8e6"
                                    //url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {/* 선택영역범위를 폴리곤으로 표시 */}
                                <Polygon
                                    positions={gridPolygonCoorinates}
                                    color='#FF3E44'
                                />
                                {/* 선택영역에 포함되는 충전소 정보를 마커로 표시  */}
                                {chargerStationMarkers.map((marker, index) => (
                                    <Marker key={index} position={marker.position} icon={chargerStationMarkerIcon}>
                                        <Popup>
                                            {marker.statNm} <br />
                                            {marker.address} <br />
                                            {marker.cpoNm}
                                        </Popup>
                                    </Marker>
                                ))}
                            </MapContainer>}
                        </div>
                    </div>
                    <div style={{width: '45%', height: '550px'}}>
                        <GridComponent 
                            height={530}
                            allowPaging={true}
                            allowResizing={true}
                            loadingIndicator={{indicatorType: 'Spinner'}}
                            pageSettings = {{pageSize:13, pageCount:5}}
                            //rowSelected={onSelectRecommendedStation}
                            dataSource={chargerStations}>
                            <ColumnsDirective>
                                {/* <ColumnDirective field='statid' headerText='충전소식별번호' width='50' textAlign='Center'/>     */}
                                <ColumnDirective field='statnm' headerText='충전소이름' width='150' textAlign='Left'/>
                                <ColumnDirective field='addr' headerText='주소' width='150' textAlign='Left'/>  
                                <ColumnDirective field='bnm' headerText='사업자이름' width='100' textAlign='Left'/>  
                            </ColumnsDirective>    
                            <Inject services={[Page, Selection, Resize]}/>
                        </GridComponent> 
                    </div>
                </div>
                {/* 해당지역의 추천 정보 세션 제목 */}
                <div style={{marginTop: '20px'}}>
                    <h4>선택한 구역의 충전소 설치를 위한 추천정보</h4>
                </div>
                <div className='e-card' style={{width:'97%', height:'200px', padding:'15px', marginLeft:'15px'}}>
                    <div className='e-card-content'>
                        <div style={{display:'flex', flexDirection:'row', gap: '20px', alignItems:'top'}}>
                            <div style={{display: 'flex', 
                                alignItems:'center', justifyContent:'center', 
                                borderRadius: '12px',
                                width:'100px', height:'100px', 
                                backgroundColor:'#2FB4D9'}}>
                                <div style={{fontSize: '30px', fontWeight: 'bold', color:'#fff'}}>
                                    비추천
                                </div>
                            </div>
                            <div>
                                <ul>
                                    <li> AI는 미래에 필요한 필요충전량을 기준으로 이 구역의 등급을 {selectedGridFeature.properties.predGrade} 등급지로 추론했습니다.</li>
                                    <li> 이 구역과 유사한 지역(행정단위, 전기차수, 인구, 지역적 특성분류)에 설치된 충전소들의 통계를 바탕으로 추정된 월 평균 충전량은 ooo kWh(킬로와트시) 입니다.</li>
                                    <li> 이 지역의 상위 10%의 충전량은 xxx kWh으로, 많은 차이가 발생합니다.</li>
                                </ul>
                            </div> 
                        </div>      
                    </div>
                </div>
                {/* POI 요약정보 세션 제목  */}
                <div style={{marginTop: '20px'}}>
                    <h4>선택한 구역을 중심으로 반경 1km에 위치한 POI 개수 요약</h4>
                </div>
                <div>
                    <ZoomSimGridReportPoiSummaryCard poiSummary={commercialPoi} cardTitle='상업시설'/>   
                    <ZoomSimGridReportPoiSummaryCard poiSummary={accomodationPoi} cardTitle='숙박시설'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={tourPoi} cardTitle='관광지'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={educationPoi} cardTitle='교육시설'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={coveniencePoi} cardTitle='생활편의'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={businessPoi} cardTitle='사업'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={outdoorLeisurePoi} cardTitle='야외여가시설'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={religionPoi} cardTitle='종교시설'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={residentialPoi} cardTitle='주거시설'/> 
                    <ZoomSimGridReportPoiSummaryCard poiSummary={ectPoi} cardTitle='기타시설'/>    
                </div>
            </div>           
        </div>
    );
}

export default CpoStationZoomSimGridReport;