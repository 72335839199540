import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { setCookie, getCookie, timeInput, dateFormat, getTimeZone, setTimeZone, timestampFormat3 } from '../../util/util';

import { TfiClose } from "react-icons/tfi";

import Selector2 from '../buttons/Selector2';

export const CpoPayReportSearchModal = (props) => {
  const signedToken =  getCookie('signedToken')
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

  // tab 0
  // 식별번호
  const [DisplaySerialNumber, setDisplaySerialNumber] = useState(props.DisplaySerialNumber);
  // idTag
  const [TagId, setTagId] = useState(props.TagId);
  // 단가
  const [MinUnitPrice, setMinUnitPrice] = useState(props.MinUnitPrice);
  const [MaxUnitPrice, setMaxUnitPrice] = useState(props.MaxUnitPrice);
  
  // 시작 날짜
  const [startDate, setStartDate] = useState(props.MinCreatedDate?timestampFormat3(props.MinCreatedDate).split(' ')[0]:null);
  // 종료 날짜
  const [stopDate, setStopDate] = useState(props.MaxCreatedDate?timestampFormat3(props.MaxCreatedDate).split(' ')[0] :null);
  // 시작 시간(시&분)
  const [timeFrom, setTimeFrom] = useState(props.MinCreatedDate ? ( timestampFormat3(props.MinCreatedDate).split(' ')[1] ):null);
  // 종료 시간(시&분)
  const [timeTo, setTimeTo] = useState(props.MaxCreatedDate ? (timestampFormat3(props.MaxCreatedDate).split(' ')[1] ) :null);  
  // 결제 여부
  const [paymentDoneFailure, setPaymentDoneFailure] = useState(props.PaymentCompleted===false);
  const [paymentDoneSuccess, setPaymentDoneSuccess] = useState(props.PaymentCompleted===true);

  // tab 1
  const dataSettingNameList = ["Authorize", "BootNotification", "ChangeAvailability", "ChangeConfiguration", "ClearCache", "DataTransfer", "GetConfiguration", "Heartbeat", "MeterValues", "RemoteStartTransaction", "RemoteStopTransaction", "Reset", "StartTransaction", "StatusNotification", "StopTransaction", "UnlockConnector", "GetDiagnostics", "DiagnosticsStatusNotification", "FirmwareStatusNotification", "UpdateFirmware", "GetLocalListVersion", "SendLocalList", "CancelReservation", "ReserveNow", "ClearChargingProfile", "GetCompositeSchedule", "SetChargingProfile", "TriggerMessage", "ClearLocalList", "CloseSession"]
  // 클릭된 뱃지 리스트
  const [clickedDataSettingNameList, setClickedSettingNameList] = useState([]);
  // 클릭 이벤트 처리 함수
  const clickDateSettingName = (idx)=>{
    let temp = [...clickedDataSettingNameList];
    if(temp.indexOf(idx) !== -1){ 
      temp.splice(temp.indexOf(idx),1)
      setClickedSettingNameList(temp);
    }
    else{
      temp.push(idx)
      setClickedSettingNameList(temp);
    } 
  }

  useEffect(()=>{
    if(startDate && stopDate){
      if(startDate > stopDate){
        let temp = startDate;
        setStartDate(stopDate);
        setStopDate(temp);
      }
    }
  },[startDate, stopDate])
  useEffect(()=>{
    if(startDate == stopDate && timeFrom?.length == 7 && timeTo?.length==7){
      let [from, to] = swapIfNeeded(timeFrom, timeTo);
      setTimeFrom(from);
      setTimeTo(to);
    }
  },[timeFrom, timeTo])
  function swapIfNeeded(time1, time2) {
    // 시간을 분으로 변환하는 함수
    function convertToMinutes(time) {
      const [hours, minutes] = time.split(" : ").map(Number);
      return hours * 60 + minutes;
    }
  
    // 비교 후 순서를 바꿔야 하는 경우
    if (convertToMinutes(time1) > convertToMinutes(time2)) {
      return [time2, time1];
    }
    
    return [time1, time2];
  }

  useEffect(()=>{
    if(props.visibleActionList.length !== 0){
      getInitBadgeStatus();
    }
  },[])
  const getInitBadgeStatus = async()=>{
    let temp = [];
    for await(const it of props.visibleActionList){
      temp.push(it.idx);
    }
    setClickedSettingNameList(temp);
  }

  
  const submit = async()=>{
    if (props.tabIdx==0){
      props.setDisplaySerialNumber(DisplaySerialNumber);
      props.setTagId(TagId);
      props.setMinCreatedDate(startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null)
      props.setMaxCreatedDate(stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null)
      props.setMinUnitPrice(MinUnitPrice)
      props.setMaxUnitPrice(MaxUnitPrice)
      props.setPaymentCompleted(paymentDoneFailure && paymentDoneSuccess ? "" : paymentDoneSuccess?true:paymentDoneFailure?false:"")
    }
    else{
      console.log(clickedDataSettingNameList);
      let temp=[];
      for await(const it of clickedDataSettingNameList){
        temp.push({idx:it, action:dataSettingNameList[it]});
      }
      props.setVisibleActionList(temp);
    }
    props.setOnOff(false);
  }

  const reset = ()=>{
    if (props.tabIdx==0){
      setDisplaySerialNumber("");
      setTagId("");
      setMinUnitPrice("");
      setMaxUnitPrice("");
      setStartDate("");
      setStopDate("");
      setTimeFrom("");
      setTimeTo("");
      setPaymentDoneFailure(false);
      setPaymentDoneSuccess(false);
    }
    else{
      setClickedSettingNameList([]);
    }
  }




  return (
    <div className="cpo-pay-report-search-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-pay-report-search-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-pay-report-search-modal-header">
          <title>{props.tabIdx == 0 ?"트랜잭션 검색":"액션 검색"}</title>
          {/* <p>초기화</p> */}
          <div className='cpo-pay-report-search-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        {
          props.tabIdx == 0 &&
          <div className='cpo-pay-report-search-modal-content-section'>
            <div className="cpo-log-report-modal-content-wrapper divide">
              <div>
                <div className="cpo-log-report-modal-content-wrapper">
                  <p>충전기식별번호</p>
                </div>
                <div className="cpo-log-report-modal-content-wrapper">
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="text" placeholder='충전기식별번호를 입력하세요' value={DisplaySerialNumber} onChange={(e)=>{setDisplaySerialNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                </div>
              </div>
              <div></div>
              <div>
                <div className="cpo-log-report-modal-content-wrapper">
                  <p>회원 idTag</p>
                </div>
                <div className="cpo-log-report-modal-content-wrapper">
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="text" placeholder='회원 idTag를 입력하세요' value={TagId} onChange={(e)=>{setTagId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
              <p>검색 기간</p>
              <p>최소와 최대 범위를 설정해주세요</p>                
            </div>
            <div className="cpo-log-report-modal-content-wrapper divide">
              <div className='cpo-log-report-modal-content-input-wrapper'>
                <div className="cpo-log-report-modal-content-input-container ">
                  <input type="date" onClick={(e)=>{e.stopPropagation(); }} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>
                </div>
                <div className="cpo-log-report-modal-content-input-container time">
                  <input type="text" placeholder='00 : 00' value={timeFrom} onChange={(e)=>{setTimeFrom(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                </div>
              </div>
              <div>~</div>
              <div className='cpo-log-report-modal-content-input-wrapper'>
                <div className="cpo-log-report-modal-content-input-container ">
                  <input type="date"  onClick={(e)=>{e.stopPropagation(); }} value={stopDate} onChange={(e)=>{setStopDate(e.target.value)}}/>
                </div>
                <div className="cpo-log-report-modal-content-input-container time">
                  <input type="text" placeholder='00 : 00' value={timeTo} onChange={(e)=>{setTimeTo(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                </div>
              </div>
            </div>
            <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
              <p>충전 단가</p>
              <p>최소와 최대 범위를 설정해주세요</p>                

            </div>
            <div className="cpo-log-report-modal-content-wrapper divide">
              <div className="cpo-log-report-modal-content-input-container postfix">
                <input type="text" value={MinUnitPrice} onChange={(e)=>{setMinUnitPrice(e.target.value)}}/>
                <p>원</p>
              </div>
              <div>~</div>
              <div className="cpo-log-report-modal-content-input-container postfix">
                <input type="text" value={MaxUnitPrice} onChange={(e)=>{setMaxUnitPrice(e.target.value)}}/>
                <p>원</p>
              </div>
            </div>
            {/* <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
              <p>충전량</p>
              <p>최소와 최대 범위를 설정해주세요</p>                
            </div>
            <div className="cpo-log-report-modal-content-wrapper divide">
              <div className="cpo-log-report-modal-content-input-container postfix2">
                <input type="text"/>
                <p>kWh</p>
              </div>
              <div>~</div>
              <div className="cpo-log-report-modal-content-input-container postfix2">
                <input type="text"/>
                <p>kWh</p>
              </div>
            </div> */}

            <div className="cpo-log-report-modal-content-wrapper divide marginTop">
              <div>
                <p>결제 성공 여부</p>
                <div className='cpo-log-report-modal-content-toggle-container'>
                  <div className={'cpo-log-report-modal-content-toggle ' + `${paymentDoneFailure ? "failure" : ""}`} onClick={()=>{setPaymentDoneFailure(!paymentDoneFailure)}}>실패</div>
                  <div className={'cpo-log-report-modal-content-toggle ' + `${paymentDoneSuccess ? "success" : ""}`} onClick={()=>{setPaymentDoneSuccess(!paymentDoneSuccess)}}>성공</div>
                </div>
              </div>            
              <div></div>
            </div>
            
          </div>
        }
        {
          props.tabIdx == 1 && props.actionData &&
          <div>
            <div className="cpo-log-report-modal-content-wrapper marginTop">
              <p>액션명</p>
              <div className='cpo-log-report-modal-content-data-setting-badge-container'>
                {
                dataSettingNameList.map((it, idx)=>{
                  if(props.actionData.filter(ia=>ia.action == it)[0]){
                    return<div className={"cpo-log-report-modal-content-data-setting-badge " + `${clickedDataSettingNameList.indexOf(idx) !== -1 ? "selected" :"" }`} onClick={()=>{clickDateSettingName(idx)}}>{it}</div>
                  }
                })
                }
              </div>
            </div>
          </div>
        }
        <div className='cpo-pay-report-search-modal-button-container'>
          <div className="cpo-pay-report-search-modal-button reset" onClick={()=>{reset();}}>초기화</div>
          <div className="cpo-pay-report-search-modal-button" onClick={()=>{submit()}}>검색</div>
        </div>
      </div>
    </div>
  )
}
export default CpoPayReportSearchModal
