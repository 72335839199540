import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import StationItem from "../../components/list/StationItem.js";
import StationDetailModal from "../../components/modals/StationDetailModal.js";
import StationEnrollModal from '../../components/modals/StationEnrollModal.js';
import StationDeleteModal from '../../components/modals/StationDeleteModal.js';
import { getCookie } from '../../util/util.js'; 
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";


import { getChargerByStationId, getStationListCustom } from '../../api/infra/infraApi.js';


// 충전인프라관리 - 충전소 관리
const CpoStationManagement = () => {
  // redux
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");
  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:2}));
  },[])

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  const [stationCount, setStationCount]=useState(null);

  // 충전기 infra 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);


  
  useEffect(()=>{
    reqGetStationList();
  },[])
  
  // cpo가 소유한 station GET
  const getStationList = async(id)=>{
    await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      let temp = [...res.data.Results].filter(it=>it.OpStatus !== "삭제됨");
      setStationInfo(temp);
      setStationCount(res.data.Total);
    })
  }

  const reqGetStationList = async()=>{
    let result = await getStationListCustom(pageNum);
    if(result.status === 200){
      let temp = [...result.data.Results].filter(it=>it.OpStatus !== "삭제됨");
      setStationInfo(temp);
      setStationCount(result.data.Total);
    }
  }

  // station에 종속된 charger
  const reqChargerList = async()=>{
    let result =[];
    for await(const station of stationInfo){
      let tempChargerList = await getChargerByStationId(station.ChargingStationId);
      result.push(tempChargerList.data);
    }
    console.log(result);
    setChargerInfo(result);
  }

  // chargerHub 정보 API GET
  const getChargerHubList = async(id)=>{
    let temp = [];
    await axios.get(`/controlhub/api/cpouser/v1/Chargers/${id}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
    return temp;
  }
  
  const reqChargerHubList = async()=>{
    let result = [];
    for await(const chargerList of chargerInfo){
      let tempList = [];
      for await(const charger of chargerList.Results){
        let data = await getChargerHubList(charger.ChargerId)
        tempList.push(data);
      }
      result.push(tempList);
    }
    setChargerHubInfo(result);
  }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    // getChargerHubList();
    reqChargerList();
    setCheckStationList([]);
  }, [stationInfo])

  useEffect(()=>{
    if(!chargerInfo)return;
    reqChargerHubList();
  },[chargerInfo])

  // station list Ref[]
  const stationRef = useRef([]);
  
  // 클릭한 station의 idx state
  const [clickedStation, setClickedStation] = useState(null);

  // 상세 페이지 Modal
  const [detailModalOpen, setDetailModalOpen] = useState(false);


  useEffect(()=>{
    if(clickedStation !== null){
      setDetailModalOpen(true);
    }
    else{
      setDetailModalOpen(false);
    }
  },[clickedStation])

  // check한 station list
  const [checkStationList, setCheckStationList] = useState([]);

  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    if(checked){
      let temp = new Array(stationInfo.length).fill(0).map((_, i) => i)
      setCheckStationList(temp);
    }
    else{
      setCheckStationList([]);
    }
  },[checked])

  const [enrollModalOn, setEnrollModalOn] = useState(false);
  const [deleteModalOn, setDeleteModalOn] = useState(false);
  

  const [facility, setFacility] = useState({'공공시설':0,'주차시설':0,'휴게시설':0,'관광시설':0,'상업시설':0,'챠랑정비시설':0,'기타시설':0,'공동주택시설':0,'근린생활시설':0,'교육문화시설':0});

  useEffect(()=>{
    if(!stationInfo)return;
    getFacilityClassification();
  },[stationInfo])
  const getFacilityClassification = async()=>{
    let facilityTemp = {'공공시설':0,'주차시설':0,'휴게시설':0,'관광시설':0,'상업시설':0,'챠랑정비시설':0,'기타시설':0,'공동주택시설':0,'근린생활시설':0,'교육문화시설':0};
    for await(const station of stationInfo){
      if(station.FacilityClassification) facilityTemp[station.FacilityClassification] = facilityTemp[station.FacilityClassification]+1;
    }
    console.log(facilityTemp);
    setFacility(facilityTemp);
  }
  




// 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(stationCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetStationList();
      // getChargerHubInfo();
    }
  },[pageNum])





  return (
    <div className='cpo-station-management-container'>
      {enrollModalOn &&<StationEnrollModal setOnOff={setEnrollModalOn} getStationList={reqGetStationList}/>}
      {deleteModalOn &&<StationDeleteModal setOnOff={setDeleteModalOn} stationInfo={stationInfo} checkStationList={checkStationList} getStationList={reqGetStationList}/>}
      <div className="cpo-station-management-inner">
        {stationInfo&&chargerHubInfo&&<StationDetailModal getStationList={reqGetStationList} chargerHubInfo={chargerHubInfo[clickedStation]} data={stationInfo[clickedStation]}  detailModalOpen={detailModalOpen} setDetailModalOpen={setDetailModalOpen} clickedStation={clickedStation} setClickedStation={setClickedStation} lastIdx={stationInfo.length-1}/>}
        {/* <div className='cpo-station-management-search-container'>
          <div className="cpo-station-management-keyword-search-container">
            <input ref={searchInputRef} type="text" placeholder='키워드로 검색하기' onClick={(e)=>{e.stopPropagation(); }}/>
            <button><MdSearch className='search-icon'/></button>
          </div>
          <div className={detailSearchOpen ?'cpo-station-management-detail-search-button open': 'cpo-station-management-detail-search-button'} onClick={()=>{setDetailSearchOpen(!detailSearchOpen)}}>
            <p>상세검색</p>
            <FaChevronDown className={detailSearchOpen ?'open':''}/>
          </div>
          <div className={detailSearchOpen ? 'cpo-station-management-detail-search-modal-container open':'cpo-station-management-detail-search-modal-container'}>
            
          </div>
        </div> */}
        {/* <div className="cpo-station-management-ten-category-outer">
          <div className='cpo-station-management-ten-category-container'>
            <div><p className='cpo-station-management-category' onClick={()=>{}}>공공시설</p><p>{facility['공공시설']}</p></div>
            <div><p className='cpo-station-management-category'>주차시설</p><p>{facility["주차시설"]}</p></div>
            <div><p className='cpo-station-management-category'>휴게시설</p><p>{facility.휴게시설}</p></div>
            <div><p className='cpo-station-management-category'>관광시설</p><p>{facility.관광시설}</p></div>
            <div><p className='cpo-station-management-category'>상업시설</p><p>{facility.상업시설}</p></div>
            <div><p className='cpo-station-management-category'>챠랑정비시설</p><p>{facility.챠랑정비시설}</p></div>
            <div><p className='cpo-station-management-category'>기타시설</p><p>{facility.기타시설}</p></div>
            <div><p className='cpo-station-management-category'>공동주택시설</p><p>{facility.공동주택시설}</p></div>
            <div><p className='cpo-station-management-category'>근린생활시설</p><p>{facility.근린생활시설}</p></div>
            <div><p className='cpo-station-management-category'>교육문화시설</p><p>{facility.교육문화시설}</p></div>
          </div>
        </div> */}

        <div className={detailModalOpen ? 'cpo-station-management-station-list-container open':'cpo-station-management-station-list-container' }>
          <div className={"cpo-station-management-station-list-button-container " + `${detailModalOpen? "open":""}`}>
            <div className={'delete '+`${checkStationList.length === 0 ?"none":""}`} onClick={()=>{setDeleteModalOn(true)}}><p>삭제</p></div>
            <div onClick={()=>{setEnrollModalOn(true)}}><p>등록</p></div>
            {/* <div className='excel'><RiFileExcel2Line/><p>엑셀 다운로드</p></div> */}
          </div>
          <div className="cpo-station-management-station-list">
            <div className="cpo-station-management-station-list-column">
              <div className='cpo-station-management-station-list-column-item'></div>
              <div className='cpo-station-management-station-list-column-item'><p>권역</p></div>
              <div className='cpo-station-management-station-list-column-item'><p>충전소명</p></div>
              <div className='cpo-station-management-station-list-column-item'><p>주소</p></div>
              <div className='cpo-station-management-station-list-column-item'><p>
                운용대수
              </p></div>
              <div className='cpo-station-management-station-list-column-item'><p>충전기 총 대수</p></div>
              <div className='cpo-station-management-station-list-column-item checkbox'>
                <label className="checkbox_label">
                  <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                  <span className="checkbox_icon"></span>
                </label>
                <p>전체선택</p>
              </div>
            </div>
            <div className='cpo-station-management-list-content'>
              {
                stationInfo&&chargerHubInfo&&(stationInfo.map((it,idx)=>{
                  if(it.OpStatus === "삭제됨")return<></>;
                  return(<StationItem key={'stationItem-'+idx} totalCount={chargerInfo[idx]?.Total} chargerHubInfo={chargerHubInfo[idx]} checkStationList={checkStationList} setCheckStationList={setCheckStationList} clickedStation={clickedStation} setClickedStation={setClickedStation} stationRef={stationRef} idx={idx}  data={it} />)
                }))
              }
            </div>
            <div className='page-selector-container'>
              {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
              {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
              {pageSelectorRendering()}
              {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
              {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
              <div className='page-total-info'>{`Total : ${stationCount}`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoStationManagement;