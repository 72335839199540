// /subscription/api/cpouser/Subscriptions/ChargerLimit
import axios from 'axios';
import { getCookie, refresh } from '../../util/util';


// cpo 관리가능 충전기 개수 조회
export const getChargerLimit = async()=>{  
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/subscription/api/cpouser/Subscriptions/ChargerLimit`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}


// 현재 구독 정보 조회
export const getSubscription = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/subscription/api/cpouser/Subscriptions`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}