import React, {useState, useRef, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import NavButton from "../buttons/NavButton.js";
import NavSubButton from "../buttons/NavSubButton.js";
import {setNum} from '../../store.js'

import {getStationList} from '../../api/infra/infraApi.js';

const Sidebar = (props) => {
  const [stationCount, setStationCount] = useState(null);

  const reqGetStationList = async()=>{
    let res = await getStationList();
    if(res.status===200){
      setStationCount(res.data.Total);
    }
  }

  useEffect(()=>{
    reqGetStationList();
  })

  useEffect(()=>{
    let roleList = localStorage.getItem("user-roles");
    if(roleList.search("SysAdmin")!==-1){setIsSysAdmin(true)}
    if(roleList.search("CpoAdmin")!==-1){setIsCpoAdmin(true)}
  },[])
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [isCpoAdmin, setIsCpoAdmin] = useState(false);

  let pageForSysAdmin = [{id:11, idx:0},{id:11, idx:1},{id:11, idx:2},{id:12, idx:3}];
  let pageForCpoAdmin = [{id:14}];

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  
  // 현재 선택된 nav버튼 idx
  const [selectedIdx,setSelectedIdx] = useState(0);
  // 현재 선택된 navSub버튼 idx
  const [selectedSubIdx,setSelectedSubIdx] = useState(null);
  
  // nav 클릭 이벤트컨트롤러
  const navClick = (id)=>{
    console.log(id);
    // 새로 클릭된경우
    if(selectedIdx !== id){
      // 새로운 id로 변경
      setSelectedIdx(id);
      
      // 하위 메뉴가 있는가?
      const found = props.menuItems.find((el) => el.id == id);
      // 없는 경우
      if(!(found.items)){
        // 해당 페이지로 이동하기 위해 저장
        dispatch(setNum({id:id, idx:0, nav:true}));
      }
    }
    // 클릭된 것을 또 클릭한 경우
    else{
      // 하위 메뉴가 있는가?
      const found = props.menuItems.find((el) => el.id == id);
      // 있는 경우
      if(found.items){
        // nav 선택 초기화
        setSelectedIdx(null);
      }
    }
    // navSub 선택 초기화
    setSelectedSubIdx(null);
  }

  // navSub 클릭 이벤트
  const navSubClick = (id, idx)=>{
    // 새로 클릭된경우
    if(selectedSubIdx !== idx){
      // 새로운 idx로 변경
      setSelectedSubIdx(idx);
      dispatch(setNum({id:id, idx:idx, nav:true}));
    }
    // 클릭된 것을 또 클릭한 경우
    else{
      // navSub 선택 초기화
      // setSelectedSubIdx(null);
    }
  }
  useEffect(()=>{
    
    if(selectedIdx !== page.num.id){
      setSelectedIdx(page.num.id);
    }
    if(selectedSubIdx !== page.num.idx){
      setSelectedSubIdx(page.num.idx);
    }
  },[page])
  return (
    <div className='side-bar-container'>
      {
        props.menuItems.map((it, idx) => {
          
          if(it.title)return(<div key={'title-'+it.title} className='side-bar-title'><p>{it.title}</p></div>);
          if(!isSysAdmin&&pageForSysAdmin.filter(admin => admin.id===it.id && admin.idx===undefined).length !== 0)return(<></>);
          if(!isSysAdmin&&!isCpoAdmin&&pageForCpoAdmin.filter(admin => admin.id===it.id && admin.idx===undefined).length !== 0)return(<></>);
          if(it.test===true && process.env.REACT_APP_STAGE !== 'Development') return(<></>);
          return(
          <div ref={(el)=>{props.navButtonsRef.current[it.id]=el}} onClick={()=>{navClick(it.id)}}
          className='nav-button-container'
          key={'nav-button-'+it.id}>
            <NavButton data={it} id={it.id} selectedIdx={selectedIdx} fast={it.id===1 && stationCount === 0 ? "fast":""}/>
            <div className='nav-sub-container'>
              {
                it.items ? //sub리스트가 있는가?
                  it.items.map((uit, uidx)=>{
                    if(!isSysAdmin&&pageForSysAdmin.filter(admin => admin.id===it.id && admin.idx === uidx).length !== 0)return(<></>);
                    if(!isSysAdmin&&!isCpoAdmin&&pageForCpoAdmin.filter(admin => admin.id===it.id && admin.idx === uidx).length !== 0)return(<></>);
                    return (
                      // selectedIdx === idx(NavButton 중 현재 선택한 것이 해당 Nav인 경우)
                      // selectedSubIdx === uidx(NavSubButton 중 현재 선택한 것이 해당 Nav인 경우)
                      // isActive(현재 Nav의 선택여부) isOn(sub 중 현재sub의 선택 여부)
                      <div key={"nav-sub-"+it.id+uidx} ref={(el)=>{props.navSubButtonsRef.current[it.id*10 + uidx]=el}} className={selectedIdx === it.id?'nav-sub-inner':'nav-sub-inner none'} 
                      onClick={(e)=>{
                        e.stopPropagation(); 
                        navSubClick(it.id, uidx);
                        }}>
                        <NavSubButton isActive={selectedIdx === it.id?true:false} isOn={page.num.id===it.id && page.num.idx===uidx ? true:false } name={uit} />
                      </div>
                  );
                  })
                :
                  null
              }
            </div>
          </div>



          );
        })
      }
    </div>
  )
}
export default Sidebar;