import axios from 'axios';

import { getCookie, refresh } from '../../util/util';


// 충전통계 조회
export const getChargingStaticsTotal = async(data)=>{
  let {startTime, endTime, chargerType} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/statistics/api/cpouser/v1/ChargingStatistics/Total?${startTime?`StartTime=${startTime}&`:""}${endTime?`EndTime=${endTime}&`:""}${chargerType?`ChargerType=${chargerType}`:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}

// 기간그룹별 충전통계 조회 (총 수익, 총 충전량, 총 충전횟수 등)
export const getChargingStaticsGrouped = async(data)=>{
  let {startTime, endTime, chargerType, timeGroupType} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/statistics/api/cpouser/v1/ChargingStatistics/Grouped?${startTime?`StartTime=${startTime}&`:""}${endTime?`EndTime=${endTime}&`:""}${chargerType?`ChargerType=${chargerType}&`:""}${timeGroupType?`TimeGroupType=${timeGroupType}`:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}