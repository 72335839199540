/* eslint-disable */
import './App.css';
import { Suspense, lazy } from "react";
import React, { useRef, useState, useEffect } from 'react';
import Lottie from "react-lottie";

import MainTemplate from './pages/Default/MainTemplate.js';
import Login from './pages/Default/Login.js';
import SimpleLogin from './pages/Default/SimpleLogin.js';
import SignUp from './pages/Default/SingUp.js';
import AccountVerification from './pages/Default/AccountVerification.js';
import CpoService from './pages/Default/CpoService.js';
import OccpService from './pages/Default/OcppService.js';
import ServicePricing from './pages/Default/ServicePricing.js';

import CpoDashboard from './pages/CPO/CpoDashboard.js';
import CpoFastEnroll from './pages/CPO/CpoFastEnroll.js';
import CpoLogReport from './pages/CPO/CpoLogReport.js';
import CpoPayReport from './pages/CPO/CpoPayReport.js';
import CpoStationManagement from './pages/CPO/CpoStationManagement.js';
import CpoChargerManagement from './pages/CPO/CpoChargerManagement.js';
import CpoInstallManagement from './pages/CPO/CpoInstallManagement.js';
import CpoChargerModelManagement from './pages/CPO/CpoChargerModelManagement.js';
import CpoChargerControl from './pages/CPO/CpoChargerControl.js';
import CpoChargingFeeManagement from './pages/CPO/CpoChargingFeeManagement.js';

import CpoFAQ from './pages/CPO/CpoFAQ.js';
import CpoQna from './pages/CPO/CpoQna.js';
import CpoNotice from './pages/CPO/CpoNotice.js';
import CpoBreakdown from './pages/CPO/CpoBreakdown.js';
import CpoSubscription from './pages/CPO/CpoSubscription.js';
import CpoCHAS from './pages/CPO/CpoCHAS.js';
import CpoChasEdit from './pages/CPO/CpoChasEdit.js';
import CpoChasEdit2 from './pages/CPO/CpoChasEdit2.js';
import CpoPaymentMethod from './pages/CPO/CpoPaymentMethod.js';
import CpoBusinessAuthorize from './pages/CPO/CpoBusinessAuthorize.js';
import CpoMemberInfoUpdate from './pages/CPO/CpoMemberInfoUpdate.js';
import CpoMemberWithdraw from './pages/CPO/CpoMemberWithdraw.js';
import CpoGroupManagement from './pages/CPO/CpoGroupManagement.js';
import CpoNoticeDetail from './pages/CPO/CpoNoticeDetail.js';
import CpoNoticeEdit from './pages/CPO/CpoNoticeEdit.js';
import CpoChargingEventManagement from './pages/CPO/CpoChargingEventManagement.js';
import AlertMsgModal from './components/modals/AlertMsgModal.js';
/// Added by edeward at 2024.11.26
/// Start Code
import CpoStationZoom from './pages/CPO/CpoStationZoom.jsx';
import CpoStationZoomStatReport from './pages/CPO/CpoStationZoomStatReport.jsx';
import CpoStationZoomSimReport from './pages/CPO/CpoStationZoomSimReport.jsx';
import CpoStationZoomSimGridReport from './pages/CPO/CpoStationZoomSimGridReport.jsx';
/// End Code
import CpoDashBoardTest from './pages/CPO/CpoDashBoardTest.js';

import CpoSystemChas from './pages/CPO/CpoSystemChas.js';
import CpoCustomChas from './pages/CPO/CpoCustomChas.js';
import CpoCustomChasEdit from './pages/CPO/CpoCustomChasEdit.js';

import SysChasProfileManagement from './pages/CPO/SysChasProfileManagement.js';

import CpoIncomeReport from './pages/CPO/CpoIncomeReport.js';

import QrNavigator from './pages/Mobile/QrNavigator.js';
import CpoMainPage from './pages/CPO/CpoMainPage';
import InvitationVerification from './pages/Default/InvitationVerification.js';

import { Outlet, RouterProvider,createBrowserRouter, useNavigate } from 'react-router-dom'
import { NavermapsProvider } from 'react-naver-maps';

import animationSplash from './lotties/loading.json'
import animationSplash2 from './lotties/404Animation.json'
import axios from 'axios';
import { getCookie, setCookie, refresh } from './util/util.js';


function App(props) {


  // axios 인스턴스 생성
  const axiosInstance = axios.create();

  // axios.interceptors.response.use(
  //   (res)=>{

  //     return res;

  //   },
  //   async (err)=>{
      
  //     if(err.response.status===401){
  //       console.log(err);
  //       await refresh();
  //     }
  //     return Promise.reject(err);
  //   }
  // )
  const[errModalOn, setErrModalOn] = useState();
  const [errData, setErrData] = useState();

  // 응답 인터셉터에서 401 에러 발생 시 처리
  axios.interceptors.response.use(
    response => response, // 요청 성공 시 그대로 반환
    async (error) => {
      const { config, response } = error;

      // 요청에서 401 에러가 발생한 경우
      if (response && response.status === 401 && !config.__isRetryRequest) {
        // 로그인 시 401 발생
        if(response.request.responseURL.includes("signin")){
          setErrData({"Message": "계정정보가 일치하지 않습니다."});
          setErrModalOn(true);
          return Promise.reject(error)
        }
        config.__isRetryRequest = true;  // 무한 재시도 방지를 위해 플래그 설정
        
        try {
          await refresh();  // 토큰 갱신
          const signedToken =  getCookie('signedToken')

          config.headers.Authorization = `Bearer ${signedToken}`;  // 갱신된 토큰으로 요청 헤더 업데이트
          
          return axiosInstance(config);  // 갱신된 토큰으로 원래 요청을 다시 시도
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
      if(response.request.responseURL.includes("signin") && response.status === 404 ){
        setErrData({"Message": "존재하지 않는 이메일입니다."});
        setErrModalOn(true);
        return Promise.reject(error)
      }
      
      // console.log(error.response.data);
      // setErrData(error.response.data);
      // setErrModalOn(true);
      return Promise.reject(error);  // 401 외의 에러는 그대로 처리
    }
    
  );

  useEffect(() => {
    // adjustZoom();
    // window.addEventListener('resize', adjustZoom);
    // return () => {
    //   window.removeEventListener('resize', adjustZoom);
    // };
  }, []);
  return (
    <NavermapsProvider ncpClientId='f88olicqny'>
      <div className="App">
        <RouterProvider router={router} />
        {errModalOn&&<AlertMsgModal setOnOff={setErrModalOn} data={errData}/>}
      </div>
    </NavermapsProvider>
  );
}

export default App;

const router = createBrowserRouter([
  {
    element: (
      <div>
        <Outlet />
      </div>
    ),
    children: [
      {
        path: "cpo",
        element: (
          <Suspense fallback={<Loading/>}><CpoMainPage></CpoMainPage></Suspense>
        ),
        children: [
          {path: "/cpo",element: <CpoDashboard/>},
          {path: "/cpo/dashboard",element: <CpoDashboard/>},

          {path:'/cpo/fast-enroll' ,element:<CpoFastEnroll/>},
          
          {path:'/cpo/station-management' ,element:<CpoStationManagement/>},
          {path:'/cpo/charger-management' ,element:<CpoChargerManagement/>},
          {path:'/cpo/install-management' ,element:<CpoInstallManagement/>},
          {path:'/cpo/charger-model-management' ,element:<CpoChargerModelManagement/>},

          {path:'/cpo/log-report' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/authorize/:id' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/message/:id' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/status/:id' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/error/:id' ,element:<CpoLogReport/>},

          {path:'/cpo/pay-report' ,element:<CpoPayReport/>},
          {path:"/cpo/pay-report/:id", element:<CpoPayReport />},
          
          {path:'/cpo/income-report' ,element:<CpoIncomeReport/>},
          {path:"/cpo/income-report/:id", element:<CpoIncomeReport />},

          
          {path:'/cpo/charger-control' ,element:<CpoChargerControl/>},
          {path:'/cpo/charging-fee-management' ,element:<CpoChargingFeeManagement/>},
          {path:'/cpo/charging-event-management' ,element:<CpoChargingEventManagement/>},

          {path:'/cpo/faq' ,element:<CpoFAQ/>},
          {path:'/cpo/qna' ,element:<CpoQna/>},
          {path:'/cpo/notice' ,element:<CpoNotice/>},
          {path:"/cpo/notice/detail/:id", element:<CpoNoticeDetail />},
          {path:"/cpo/notice/edit", element:<CpoNoticeEdit />},
          {path:'/cpo/breakdown' ,element:<CpoBreakdown/>},

          {path:'/cpo/subscription' ,element:<CpoSubscription/>},
          
          {path:'/cpo/system-chas' ,element:<CpoSystemChas/>},
          {path:'/cpo/custom-chas' ,element:<CpoCustomChas/>},
          {path:'/cpo/custom-chas/edit' ,element:<CpoCustomChasEdit/>},
          {path:'/cpo/chas-profile-management' ,element:<SysChasProfileManagement/>},

          // {path:'/cpo/chas' ,element:<CpoCHAS/>},
          // {path:'/cpo/chas/edit/normal' ,element:<CpoChasEdit/>},
          // {path:'/cpo/chas/edit/data-transfer' ,element:<CpoChasEdit2/>},

          {path:'/cpo/payment-method' ,element:<CpoPaymentMethod/>},
          {path:'/cpo/business-authorize' ,element:<CpoBusinessAuthorize/>},
          {path:'/cpo/member-info-update' ,element:<CpoMemberInfoUpdate/>},
          
          {path:'/cpo/group-management' ,element:<CpoGroupManagement/>},
          
          /// Added by edeward at 2024.11.26
          /// Start Code
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom', element:<CpoStationZoom/>} : {},
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom/stat-report/:id', element:<CpoStationZoomStatReport/>} : {},
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom/sim-report/:id', element:<CpoStationZoomSimReport/>} : {},
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom/sim-grid-report/:id/:zscode/:gid', element:<CpoStationZoomSimGridReport/>} : {},
          /// End Code
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/dashboard-test', element:<CpoDashBoardTest/>} : {}
        ],
      },
      {
        path: "/",
        element:(
          <>
            <MainTemplate></MainTemplate>
          </>
        ),
        children:[
          {path: "",element:<CpoService/>},
          {path: "login",element:<Login/>},
          {path: "simple-login",element:<SimpleLogin/>},
          {path: "sign-up",element:<SignUp/>},
          {path: "email-verification",element:<AccountVerification/>},
          {path: "invitation-verification",element:<InvitationVerification/>},
          {path:'delete-account' ,element:<CpoMemberWithdraw/>},
          {path:'cpo-service' ,element:<CpoService/>},
          {path:'occp-service' ,element:<OccpService/>},
          {path:'service-pricing' ,element:<ServicePricing/>},
        ],
      },
      {
        path: "app-download",
        element:(
          <QrNavigator/>
        ),
      },
      {
        path: "*",
        element:(
          <Animation404/>
        ),
      },
    ],
  },
]);

function Loading() {
    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationSplash,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };	
  return (
    <div class="loading-container">
      <div className='loader'>
        <Lottie options={defaultOptions} />
      </div>
      <h1>Loading...</h1>
    </div>
  )
}
function Animation404() {
  let navigator = useNavigate();
    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationSplash2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };	
  return (
    <div class="loading-container">
      <div className='loader'>
        <Lottie options={defaultOptions} />
      </div>
      <h1>페이지를 찾을 수 없습니다.</h1>
      <div className='goToMain' onClick={()=>{navigator(-1)}}><p>뒤로가기</p></div>
    </div>
  )
}