import React, { useEffect, useState } from 'react';
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';

import { numberInput } from '../../util/util.js';
import { patchDefaultPricingPlan, putDefaultPricingPlan } from '../../api/chargerate/chargerateApi.js';

export const SettingDefaultChargingRateModal = (props) => {
  // 로딩여부
  const [loadingOn, setLoadingOn] = useState(false);

  const [checkedMonth1, setCheckedMonth1] = useState(false);
  const [checkedMonth2, setCheckedMonth2] = useState(false);
  const [checkedMonth3, setCheckedMonth3] = useState(false);
  const [checkedMonth4, setCheckedMonth4] = useState(false);
  const [checkedMonth5, setCheckedMonth5] = useState(false);
  const [checkedMonth6, setCheckedMonth6] = useState(false);
  const [checkedMonth7, setCheckedMonth7] = useState(false);
  const [checkedMonth8, setCheckedMonth8] = useState(false);
  const [checkedMonth9, setCheckedMonth9] = useState(false);
  const [checkedMonth10, setCheckedMonth10] = useState(false);
  const [checkedMonth11, setCheckedMonth11] = useState(false);
  const [checkedMonth12, setCheckedMonth12]= useState(false);
  
  const [checkedTime0, setCheckedTime0] = useState(false);
  const [checkedTime1, setCheckedTime1] = useState(false);
  const [checkedTime2, setCheckedTime2] = useState(false);
  const [checkedTime3, setCheckedTime3] = useState(false);
  const [checkedTime4, setCheckedTime4] = useState(false);
  const [checkedTime5, setCheckedTime5] = useState(false);
  const [checkedTime6, setCheckedTime6] = useState(false);
  const [checkedTime7, setCheckedTime7] = useState(false);
  const [checkedTime8, setCheckedTime8] = useState(false);
  const [checkedTime9, setCheckedTime9] = useState(false);
  const [checkedTime10, setCheckedTime10] = useState(false);
  const [checkedTime11, setCheckedTime11] = useState(false);
  const [checkedTime12, setCheckedTime12]= useState(false);
  const [checkedTime13, setCheckedTime13] = useState(false);
  const [checkedTime14, setCheckedTime14] = useState(false);
  const [checkedTime15, setCheckedTime15] = useState(false);
  const [checkedTime16, setCheckedTime16] = useState(false);
  const [checkedTime17, setCheckedTime17] = useState(false);
  const [checkedTime18, setCheckedTime18] = useState(false);
  const [checkedTime19, setCheckedTime19] = useState(false);
  const [checkedTime20, setCheckedTime20] = useState(false);
  const [checkedTime21, setCheckedTime21] = useState(false);
  const [checkedTime22, setCheckedTime22]= useState(false);
  const [checkedTime23, setCheckedTime23]= useState(false);

  const [chargeRate, setChargeRate] = useState();



  const searchUnitPrices = async()=>{
    let temp = [];
    let monthList = [checkedMonth1,checkedMonth2,checkedMonth3,checkedMonth4,checkedMonth5,checkedMonth6,checkedMonth7,checkedMonth8,checkedMonth9,checkedMonth10,checkedMonth11,checkedMonth12];
    let timeList = [checkedTime0,checkedTime1,checkedTime2,checkedTime3,checkedTime4,checkedTime5,checkedTime6,checkedTime7,checkedTime8,checkedTime9,checkedTime10,checkedTime11,checkedTime12,checkedTime13,checkedTime14,checkedTime15,checkedTime16,checkedTime17,checkedTime18,checkedTime19,checkedTime20,checkedTime21,checkedTime22,checkedTime23];
    let monthIdx = 1;
    for await(const month of monthList){
      let timeIdx = 0;
      if(month === true){
        for await(const time of timeList){
          if(time === true){
            temp.push({
              "month": monthIdx,
              "hour": timeIdx,
              "unitPrice": chargeRate
            })
          }
          timeIdx += 1;
        }
      }
      monthIdx += 1;
    }
    return temp;
  }

  const reqPatchDefaultPricingPlan = async()=>{
    setLoadingOn(true);
    let unitPrices = await searchUnitPrices();
    let reqObj = {
      "pricingType": props.pricingType,
      "currency": "KRW",
      "unitPrices": unitPrices
    }
    let result = await patchDefaultPricingPlan(reqObj);
    if(result.status === 200){
      props.setOnOff(false);
    }
    else if(result.status === 400){
      if(result.data.ErrorCode ==="PLAN_NOT_EXIST"){
        await reqPutDefaultPricingPlan();
      }
      else{
        setSaveResultMsg("설정에 실패하였습니다.");
        setTimeout(() => {
          setSaveResultMsg("");
        }, 3000);
      }
    }
    else{
      setSaveResultMsg("설정에 실패하였습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    await props.reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }

  const reqPutDefaultPricingPlan = async()=>{
    setLoadingOn(true);
    let unitPrices = await searchUnitPrices();
    let reqObj = {
      "pricingType": props.pricingType,
      "currency": "KRW",
      "unitPrices": unitPrices
    }

    let result = await putDefaultPricingPlan(reqObj);
    if(result.status === 200){
      props.setOnOff(false);
    }
    else{
      setSaveResultMsg("설정에 실패하였습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    await props.reqGetDefaultPricingPlan();
    setLoadingOn(false);
  }
  const [saveResultMsg, setSaveResultMsg] = useState("");

  const [allTimeChecked, setSetAllTimeChecked] = useState(false);

  const reqSetAllMonthChecked = (val)=>{
    setCheckedMonth1(val)
    setCheckedMonth2(val)
    setCheckedMonth3(val)
    setCheckedMonth4(val)
    setCheckedMonth5(val)
    setCheckedMonth6(val)
    setCheckedMonth7(val)
    setCheckedMonth8(val)
    setCheckedMonth9(val)
    setCheckedMonth10(val)
    setCheckedMonth11(val)
    setCheckedMonth12(val)

  }

  const reqSetAllTimeChecked = (val)=>{
    setCheckedTime0(val);
    setCheckedTime1(val);
    setCheckedTime2(val);
    setCheckedTime3(val);
    setCheckedTime4(val);
    setCheckedTime5(val);
    setCheckedTime6(val);
    setCheckedTime7(val);
    setCheckedTime8(val);
    setCheckedTime9(val);
    setCheckedTime10(val);
    setCheckedTime11(val);
    setCheckedTime12(val);
    setCheckedTime13(val);
    setCheckedTime14(val);
    setCheckedTime15(val);
    setCheckedTime16(val);
    setCheckedTime17(val);
    setCheckedTime18(val);
    setCheckedTime19(val);
    setCheckedTime20(val);
    setCheckedTime21(val);
    setCheckedTime22(val);
    setCheckedTime23(val);
  }

  return (
    <div className="cpo-setting-default-charging-rate-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-setting-default-charging-rate-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>기본 충전요금 설정</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-setting-default-charging-rate-modal-content-title'>
          월 선택
          <div className="cpo-setting-default-charging-rate-modal-all-time-checked-button" onClick={()=>{reqSetAllMonthChecked(true)}}>전체 선택</div>
          <div className="cpo-setting-default-charging-rate-modal-all-time-checked-button reset" onClick={()=>{reqSetAllMonthChecked(false)}}>초기화</div>
        </div>
        <div className='cpo-setting-default-charging-rate-checkbox-grid'>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth1} onChange={()=>{setCheckedMonth1(!checkedMonth1)}} />
              <span className="checkbox_icon"></span>
              <p>1월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth2} onChange={()=>{setCheckedMonth2(!checkedMonth2)}} />
              <span className="checkbox_icon"></span>
              <p>2월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth3} onChange={()=>{setCheckedMonth3(!checkedMonth3)}} />
              <span className="checkbox_icon"></span>
              <p>3월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth4} onChange={()=>{setCheckedMonth4(!checkedMonth4)}} />
              <span className="checkbox_icon"></span>
              <p>4월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth5} onChange={()=>{setCheckedMonth5(!checkedMonth5)}} />
              <span className="checkbox_icon"></span>
              <p>5월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth6} onChange={()=>{setCheckedMonth6(!checkedMonth6)}} />
              <span className="checkbox_icon"></span>
              <p>6월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth7} onChange={()=>{setCheckedMonth7(!checkedMonth7)}} />
              <span className="checkbox_icon"></span>
              <p>7월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth8} onChange={()=>{setCheckedMonth8(!checkedMonth8)}} />
              <span className="checkbox_icon"></span>
              <p>8월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth9} onChange={()=>{setCheckedMonth9(!checkedMonth9)}} />
              <span className="checkbox_icon"></span>
              <p>9월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth10} onChange={()=>{setCheckedMonth10(!checkedMonth10)}} />
              <span className="checkbox_icon"></span>
              <p>10월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth11} onChange={()=>{setCheckedMonth11(!checkedMonth11)}} />
              <span className="checkbox_icon"></span>
              <p>11월</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedMonth12} onChange={()=>{setCheckedMonth12(!checkedMonth12)}} />
              <span className="checkbox_icon"></span>
              <p>12월</p>
            </label>
          </div>
        </div>

        <div className='cpo-setting-default-charging-rate-modal-content-title'>
          시간 선택
          <div className="cpo-setting-default-charging-rate-modal-all-time-checked-button" onClick={()=>{reqSetAllTimeChecked(true)}}>전체 선택</div>
          <div className="cpo-setting-default-charging-rate-modal-all-time-checked-button reset" onClick={()=>{reqSetAllTimeChecked(false)}}>초기화</div>
        </div>
        <div className='cpo-setting-default-charging-rate-checkbox-grid'>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime0} onChange={()=>{setCheckedTime0(!checkedTime0)}} />
              <span className="checkbox_icon"></span>
              <p>0:00 - 0:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime1} onChange={()=>{setCheckedTime1(!checkedTime1)}} />
              <span className="checkbox_icon"></span>
              <p>1:00 - 1:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime2} onChange={()=>{setCheckedTime2(!checkedTime2)}} />
              <span className="checkbox_icon"></span>
              <p>2:00 - 2:59</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime3} onChange={()=>{setCheckedTime3(!checkedTime3)}} />
              <span className="checkbox_icon"></span>
              <p>3:00 - 3:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime4} onChange={()=>{setCheckedTime4(!checkedTime4)}} />
              <span className="checkbox_icon"></span>
              <p>4:00 - 4:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime5} onChange={()=>{setCheckedTime5(!checkedTime5)}} />
              <span className="checkbox_icon"></span>
              <p>5:00 - 5:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime6} onChange={()=>{setCheckedTime6(!checkedTime6)}} />
              <span className="checkbox_icon"></span>
              <p>6:00 - 6:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime7} onChange={()=>{setCheckedTime7(!checkedTime7)}} />
              <span className="checkbox_icon"></span>
              <p>7:00 - 7:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime8} onChange={()=>{setCheckedTime8(!checkedTime8)}} />
              <span className="checkbox_icon"></span>
              <p>8:00 - 8:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime9} onChange={()=>{setCheckedTime9(!checkedTime9)}} />
              <span className="checkbox_icon"></span>
              <p>9:00 - 9:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime10} onChange={()=>{setCheckedTime10(!checkedTime10)}} />
              <span className="checkbox_icon"></span>
              <p>10:00 - 10:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime11} onChange={()=>{setCheckedTime11(!checkedTime11)}} />
              <span className="checkbox_icon"></span>
              <p>11:00 - 11:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime12} onChange={()=>{setCheckedTime12(!checkedTime12)}} />
              <span className="checkbox_icon"></span>
              <p>12:00 - 12:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime13} onChange={()=>{setCheckedTime13(!checkedTime13)}} />
              <span className="checkbox_icon"></span>
              <p>13:00 - 13:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime14} onChange={()=>{setCheckedTime14(!checkedTime14)}} />
              <span className="checkbox_icon"></span>
              <p>14:00 - 14:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime15} onChange={()=>{setCheckedTime15(!checkedTime15)}} />
              <span className="checkbox_icon"></span>
              <p>15:00 - 15:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime16} onChange={()=>{setCheckedTime16(!checkedTime16)}} />
              <span className="checkbox_icon"></span>
              <p>16:00 - 16:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime17} onChange={()=>{setCheckedTime17(!checkedTime17)}} />
              <span className="checkbox_icon"></span>
              <p>17:00 - 17:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime18} onChange={()=>{setCheckedTime18(!checkedTime18)}} />
              <span className="checkbox_icon"></span>
              <p>18:00 - 18:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime19} onChange={()=>{setCheckedTime19(!checkedTime19)}} />
              <span className="checkbox_icon"></span>
              <p>19:00 - 19:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime20} onChange={()=>{setCheckedTime20(!checkedTime20)}} />
              <span className="checkbox_icon"></span>
              <p>20:00 - 20:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime21} onChange={()=>{setCheckedTime21(!checkedTime21)}} />
              <span className="checkbox_icon"></span>
              <p>21:00 - 21:59</p>  
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime22} onChange={()=>{setCheckedTime22(!checkedTime22)}} />
              <span className="checkbox_icon"></span>
              <p>22:00 - 22:59</p>
            </label>
          </div>
          <div className='cpo-setting-default-charging-rate-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checkedTime23} onChange={()=>{setCheckedTime23(!checkedTime23)}} />
              <span className="checkbox_icon"></span>
              <p>23:00 - 23:59</p>
            </label>
          </div>
        </div>
        <didiv className='cpo-setting-default-charging-rate-modal-content-title'>요금 (원 kWh, 부가세 포함)</didiv>
        <div className='cpo-charger-model-enroll-modal-content-wrapper'>
          <div className="cpo-setting-default-charging-rate-input-container">
            <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={chargeRate} onChange={(e)=>{setChargeRate(e.target.value)}} />
          </div>
        </div>
        <div>

        </div>

        <div>
          <div>{saveResultMsg}</div>
          <div className={`cpo-setting-default-charging-rate-enroll-button ${(checkedMonth1||checkedMonth2||checkedMonth3||checkedMonth4||checkedMonth5||checkedMonth6||checkedMonth7||checkedMonth8||checkedMonth9||checkedMonth10||checkedMonth11||checkedMonth12)&&
            (checkedTime0||checkedTime1||checkedTime2||checkedTime3||checkedTime4||checkedTime5||checkedTime6||checkedTime7||checkedTime8||checkedTime9||checkedTime10||checkedTime11||checkedTime12||checkedTime13||checkedTime14||checkedTime15||checkedTime16||checkedTime17||checkedTime18||checkedTime19||checkedTime20||checkedTime21||checkedTime22||checkedTime23)&&
            chargeRate ? "" : "none"
          }`} onClick={()=>{reqPatchDefaultPricingPlan()}}>{loadingOn ? <Loading/> : <p>설정</p>}</div>
        </div>
      </div>
    </div>
  )
}
export default SettingDefaultChargingRateModal;