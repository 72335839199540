import React, { useState } from 'react';
import { TfiClose } from "react-icons/tfi";

import { Loading } from '../alerts/Loading.js';

import {getChargerDisplaySerialNumberDuplicationCheck} from '../../api/infra/infraApi.js';

export const ChargerSerialNumberEditModal = (props) => {

  const [nameContent, setNameContent] = useState(props.displaySerialNumber??null);
  const [nameContentValidation, setNameContentValidation] = useState(null);
  const [nameContentDuplication, setNameContentDuplication] = useState(null);
  
  const reqGetChargerSerialNumberDuplicationCheck = async()=>{
    let result = await getChargerDisplaySerialNumberDuplicationCheck(nameContent);
    if(result.status === 200){
      console.log(result);
      if(result.data.IsExist === true){
        setNameContentDuplication("disable");
      }
      else{
        setNameContentDuplication(true);
      }
    }
    else{
      setNameContentDuplication("disable");
    }
  }

  const [isError, setIsError] = useState(false);
  const [loadingOn, setLoadingOn] = useState(false);
  return (
    <div className="cpo-station-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-station-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>충전기 식별번호 변경</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="fast-enroll-input-container name">
      
          <input type="text" placeholder='식별번호를 입력해주세요' value={nameContent} onChange={(e)=>{ setNameContent(e.target.value); setNameContentDuplication(null);setIsError(false);}} onClick={(e)=>{e.stopPropagation(); }}/>
          <div className={`fast-enroll-station-name-validation ` + `${nameContent===""||!nameContent?"disable":""}`} onClick={()=>{setIsError(false); reqGetChargerSerialNumberDuplicationCheck()}}><p>중복확인</p></div>
          {
            isError === true ? 
              <p className={'fast-enroll-input-validation-msg alert'}>일시적인 오류가 발생하였습니다.</p>
            :
              nameContentDuplication === "disable" ? 
                <p className={'fast-enroll-input-validation-msg alert'}>중복된 식별번호 입니다.</p>
              :
                nameContentDuplication ? 
                  <p className={'fast-enroll-input-validation-msg able'}>사용가능한 식별번호 입니다.</p>
                :
                  nameContent === "" || nameContent == null ?
                    nameContentValidation === "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>식별번호를 입력해주세요.</p>:<></>
                  :
                  <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
          }
        </div>
        <div>
          <div></div>
          <div className={'cpo-station-modal-enroll-button '+ `${nameContent!=="" && nameContentDuplication !== "disable" && nameContentDuplication  ?"":"none"}`} onClick={()=>{props.setDisplaySerialNumber(nameContent);props.setOnOff(false);}}>{loadingOn ? <Loading/> : <p>변경</p>}</div>
        </div>
      </div>
    </div>
  )
}
export default ChargerSerialNumberEditModal;