import React, {useState, useRef, useEffect} from 'react'
import { Outlet, useNavigate, Routes, Route, Link, Switch, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"

import axios from 'axios';
import NavButton from "../../components/buttons/NavButton.js";
import Sidebar from "../../components/sidebars/Sidebar.js";
import { FiChevronsLeft } from "react-icons/fi";
import {setNum, cpoActions} from '../../store.js'
import { getCookie, refresh, signedOut } from '../../util/util.js';

// CSS
import '../../styles/MainPage.css'
import '../../styles/CpoControl.css'
import '../../styles/CpoFastEnroll.css'
import '../../styles/CpoManagement.css'
import '../../styles/CpoChargerManagement.css'
import '../../styles/CpoCustomerService.css'
import '../../styles/CpoSystemSetting.css'
import '../../styles/CpoAccountManagement.css'
import '../../styles/CpoChargingFeeManagement.css'
import '../../styles/CpoInstallManagement.css'
import '../../styles/CpoLogReport.css'
import '../../styles/CpoPayReport.css'
import '../../styles/CpoMemberInfoUpdate.css'
import '../../styles/CpoChargerModelManagement.css'
import '../../styles/CpoGroupManagement.css'
import '../../styles/CpoDashBoard.css'
import '../../styles/CpoChas.css'
import '../../styles/SysChasProfileManagement.css'
import '../../styles/CpoIncomeReport.css'

const CpoMainPage = ({ children }) => {
  let signedToken = getCookie("signedToken");
  let LocalRealName = localStorage.getItem('LocalRealName');
  const getCHASList = async()=>{
    await axios.get(`/controlhub/api/cpouser/v1/ActionSets`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then(async(res)=>{
      if(res.data?.length === 0){
        await axios.post(`/controlhub/api/cpouser/v1/ActionSets`,
        {
          "name": "default",
          "description": "기본"
        },{
          headers: {
          Authorization: `Bearer ${signedToken}`
        }
        })
        .then((res)=>{
          
        })
        .catch((err)=>{
          console.log(err)
        })
      }
    })
    .catch((err)=>{
      
    })
  }
  useEffect(()=>{
    if(!signedToken){
      window.location.replace('/login');
      return;
    }
    getCHASList();
  },[])

  let page = useSelector((state) => state.page );
  let navigator = useNavigate();

  // 대분류 (세부 아이템 있는 경우)
  const [category, setCategory] = useState("")
  // 타이틀
  const [title, setTitle] = useState("대시보드")
  
  // 페이지 변경 시 타이틀 설정
  useEffect(()=>{
    if(page.num.id == -1)return;
    const found = menuItems.find((el) => el.id == page.num.id);
    if(found.items){
      setCategory(found.name);
      if(page.num.postFix){
        setTitle(found.items[page.num.idx]+page.num.postFix);
      }
      else setTitle(found.items[page.num.idx]);
    }
    else{
      setCategory("");
      setTitle(found.name);
    }
  },[page])

  // useEffect(()=>{},[])
  
  // 페이지 변경 시 라우팅
  useEffect(()=>{
    if(page.num.id == -1)return;
    renderSection()
  }
  ,[page.num])

  // 라우팅 function
  const renderSection = ()=>{
    switch (page.num.id) {
      case 0: //대시보드
        navigator('/cpo');
        break
      
      case 1: //빠른등록
        navigator('/cpo/fast-enroll');
        break

      case 2: //충전소관리
        navigator('/cpo/station-management');
        break
      
      case 3: //충전기 관리
        navigator('/cpo/charger-management');
        break
      
      case 4: //설치업체 관리
        navigator('/cpo/install-management');
        break
      
      case 5: //충전기모델 관리
        navigator('/cpo/charger-model-management');
        break
      
      case 6: //충전요금관리
        navigator('/cpo/charging-fee-management');
        break
      
      case 7: //충전이벤트관리
        navigator('/cpo/charging-event-management');
        break

      case 8: //충전기 운용제어
        navigator('/cpo/charger-control');
        break

      case 9: //충전기로그조회
        if(page.num.detail)break;
        navigator('/cpo/log-report');
        break

      case 10: //충전결제이력
        if(page.num.detail)break;
        navigator('/cpo/pay-report');
        break
            
      case 11: // 고객서비스관리
        switch (page.num.idx) {
          case 0: // FAQ
            navigator('/cpo/faq');
            break
          
          case 1: // 1:1 질문
            navigator('/cpo/qna');
            break
          
          case 2: // 공지사항
            if(page.num.detail)break;
            if(page.num.edit)break;

            navigator('/cpo/notice');
            break
          
          case 3: // 고장신고
            navigator('/cpo/breakdown');
            break
          
          default:
            navigator('/cpo/qna');
            break
        }
      break
      
      case 12: // 시스템설정
        switch (page.num.idx) {
          case 0: // 구독
            navigator('/cpo/subscription');
            break
          
          // case 3: // CHAS
          //   if(page.num.detail)break;
          //   navigator('/cpo/chas');
          //   break
          case 1: // 시스템 제공 CHAS
            if(page.num.detail)break;
            navigator('/cpo/system-chas');
            break
          case 2: // 사용자 정의 CHAS
            if(page.num.detail)break;
            navigator('/cpo/custom-chas');
            break
          case 3: // Profile 관리 for 시스템관리자
            if(page.num.detail)break;
            navigator('/cpo/chas-profile-management');
            break
          
          default:
            navigator('/cpo/subscription');
            break
        }
      break

      case 13: // 계정 정보 관리
        switch (page.num.idx) {
          case 0: // 결제수단
            navigator('/cpo/payment-method');
            break
          
          case 1: // 법인사업자인증
            navigator('/cpo/business-authorize');
            break
          
          case 2: // 회원정보 변경
            navigator('/cpo/member-info-update');
            break
          
          case 3: // 회원탈퇴
            navigator('/delete-account');
            break
          
          default:
            navigator('/cpo/payment-method');
            break
        }
      break

      case 14:
        navigator('/cpo/group-management');
        break
      
      case 15:
        if(page.num.detail)break;
        navigator('/cpo/income-report'); // 정산
        break
      
      /// Added by edeward at 2024.11.26
      /// Start Code
      case 101:
        if(process.env.REACT_APP_STAGE === 'Development') {
          if(page.num.report) break;
          navigator('/cpo/station-zoom');
        }
        break;
      /// End End
      case 102:
        if(process.env.REACT_APP_STAGE === 'Development') {
          navigator('/cpo/dashboard-test');
        }
        break;

      default:
        navigator("/cpo")
        break
    }

  };

  // nav 오픈 여부 state
  const [navOff, setNavOff] = useState("");

  // menu 아이템 리스트
  const menuItems = [
    {
      name:'대시보드',
      id: 0,
    },
    {
      name:'빠른 등록',
      id: 1,
    },
    {
      title: "충전인프라 관리"
    },
    {
      name:'충전소 관리',
      id: 2,
    },
    {
      name:'충전기 관리',
      id: 3,
    },
    {
      name:'설치업체 관리',
      id: 4,
    },
    {
      name:'충전기 모델 관리',
      id: 5,
    },

    {
      title : '요금관리'
    },
    {
      name:'기본요금 관리',
      id: 6,
    },
    {
      name:'이벤트요금 관리',
      id: 7,
    },
    {
      title : '제어'
    },
    {
      name:'충전기운용 제어',
      id: 8,
    },
    {
      name:'충전기 데이터 기록 조회',
      id: 9,
    },
    {
      name:'충전 기록 조회',
      id: 10,
    },
    {
      title : '정산'
    },
    {
      name:'결제내역 조회 및 정산',
      id: 15,
    },
    {
      title : '시스템 관리'
    },
    {
      name:'고객서비스 관리',
      id: 11,
      items:['자주묻는 질문(FAQ)','1:1 질문','공지사항','고장신고']
    },
    {
      name:'시스템 설정',
      id: 12,
      items:['구독', "시스템 제공 CHAS", "사용자 정의 CHAS", "CHAS Profile 관리",
        // 'CHAS'
      ]
    },
    {
      title : '계정 및 조직'
    },
    {
      name:'내 정보 관리',
      id:13,
      items:['결제수단','법인사업자인증','회원정보변경','회원탈퇴']
    },
    {
      name:'조직 관리',
      id:14,
    },
    {
      title : '실험실'
    },
    {
      name : '충전소 입지추천',
      id: 101,
      test: true,
    },
    {
      name : '대시보드 테스트',
      id: 102,
      test: true,
    }    
  ];

  // nav버튼 Ref array
  let navButtonsRef = useRef([]);
  // navSub버튼 Ref array
  let navSubButtonsRef = useRef({});

  let roleList = localStorage.getItem("user-roles");
  useEffect(()=>{
    if(roleList.length === 0){navigator("/invitation-verification")}
    if(roleList.search("SysAdmin")!==-1){setIsSysAdmin(true)}
    if(roleList.search("CpoAdmin")!==-1){setIsCpoAdmin(true)}
    else if(roleList.search("CpoUser")!==-1){setIsCpoUser(true)}
    
  })
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [isCpoAdmin, setIsCpoAdmin] = useState(false);
  const [isCpoUser, setIsCpoUser] = useState(false);


  return (
    <>
      <div className="main-page-container">
        <div className={'main-nav-controller '+navOff} onClick={()=>{navOff === "" ? setNavOff("off"):setNavOff("")}}>
          <FiChevronsLeft/>
        </div>
        <nav className={'main-nav '+navOff}>
          <div className="nav-logo-container">
            <img className='nav-logo' alt="" onClick={()=>{
              navigator("/cpo-service"); 
            }}/>
          </div>
          <div className="nav-button-list-container">
            <Sidebar navButtonsRef={navButtonsRef} navSubButtonsRef={navSubButtonsRef} menuItems={menuItems}/>
          </div>
        </nav>
        <div className={'main-nav-back '+navOff}></div>
        <div className={navOff !== 'off' ? 'main-view-container' : 'main-view-container navOff'}>
          <header className='main-view-header'>
            <div className="main-view-title-container">
              <h3>{category}</h3>
              {
                title !== "CHAS"?
                  title =="회원정보변경" ?
                  <h2>{'회원정보변경 (기능 업데이트 예정입니다.)'}</h2>
                  :<h2>{title}</h2>

                :<h2>CHAS (Control Hub Action Set)</h2>
              }
            </div>
            <div className='main-nav-user-info-container'>
              <p className='main-nav-go-to-main-button' onClick={()=>{navigator("/");}}>메인 페이지</p>
              <p><color>{isSysAdmin?"시스템 관리자":isCpoAdmin?"CPO 관리자":isCpoUser?"CPO 일반회원":"일반회원"}</color><strong>{` ${LocalRealName}`}</strong>{' 님'}</p>
              <p onClick={()=>{signedOut();}}>로그아웃</p>
            </div>
          </header>
          <section>
            <Outlet />
          </section>
        </div>
      </div>
      <footer>
        <p>Copyright (C) (주)모니트 All right Reserved.</p>
        <p>1.0.8+10</p>
      </footer>
    </>
  )
}

export default CpoMainPage;
