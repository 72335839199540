import React, { useState, useEffect } from 'react';
import Selector2 from '../buttons/Selector2';

export const ChargerChasItem = (props) => {
  // checked 상태
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    if(props.checkOff)return;
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkChargerList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      // 없는 경우
      if(itemIdx !== -1){
        return;
      }
      props.setCheckChargerList([...props.checkChargerList, props.idx]);
    }
    else{ // false 인 경우
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkChargerList];
        temp.splice(itemIdx,1);
        props.setCheckChargerList(temp);
      }
    }
  },[checked])

  useEffect(()=>{
    if(props.checkOff)return;
    let itemIdx = props.checkChargerList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkChargerList])

  const [chas, setChas] = useState("");
  const [chasActive, setChasActive] = useState(null);
  const activeReset = ()=>{
    props.setParentActive(false);
    setChasActive(false);
  }

  useEffect(()=>{
    if(props.parentActive){
      setChasActive(false);
    }
  },[props.parentActive])

  useEffect(()=>{
    if(props.chasBatch){
      setChas(props.chasBatch);
    }
  },[props.chasBatch])
  useEffect(()=>{
    if(!props.checkOff || !chas) return;
    let temp = props.selectedChasInfo;
    temp[props.chasIdx] = chas;
    console.log(temp);
    props.setSelectedChasInfo(temp);
  },[chas])
  


  return (
    <>
      <div className={'cpo-charger-chas-list-content-item ' + `${checked ? 'clicked' : ""}`}
      onClick={()=>{
        if(props.checkOff)return; setChecked(!checked);
      }}>
        <div>
          <div>
            {
              props.checkOff === true ?
              <></>:
              <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                <span className="checkbox_icon"></span>
              </label>
            }
          </div>
          <div><p>{props.name}</p></div>
        </div>
        <div>
          <p>액션셋 명</p>
          {
            props.checkOff === true ?
            <div className='actionset-selector-box'>
              <Selector2 name={'name'} dataList={props.chasInfo} select={chas} setSelect={setChas} active={chasActive} setActive={setChasActive} activeReset={activeReset}/>
            </div>
            :
            <div className='actionset-box' onClick={()=>{console.log(props.data);}}>
              <p>{props.data.actionSetName ? props.data.actionSetName : "-"}</p>
            </div>
          }
        </div>
      </div>
    </>
  )
}
export default ChargerChasItem;