/* eslint-disable */
import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './store.js'
import { BrowserRouter } from "react-router-dom";
import axios from 'axios';
import axiosRetry from 'axios-retry';
import {CookiesProvider} from 'react-cookie';
import { getCookie, setCookie, refresh } from './util/util.js';
import {registerLicense} from '@syncfusion/ej2-base';

/// <summary>
/// Added by edeward, 2024-11-25
/// Syncfusion Community License 27.1.48
/// </summary>
registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH1fd3RURGZYVUd2WUA=');

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <Provider store={store}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
