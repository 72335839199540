import React, { useEffect } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";


import { deleteCharger } from '../../api/infra/infraApi.js';

export const ChargerDeleteModal = (props) => {

  useEffect(()=>{
    console.log(props.chargerList)
    console.log(props.checkChargerList)
  },[])


  const reqDeleteChargers = async()=>{
    for await(const chargerIdx of props.checkChargerList){
      let tempCharger = props.chargerList[chargerIdx];
      if(tempCharger.IsDeleted === false){
        await deleteCharger(tempCharger.ChargerId);
      }
    }
    props.setOnOff(false);
    await props.getStationList();
    // await props.getChargerHubInfo();
    await props.getChargerInfo();
  }



  return (
    <div className="cpo-charger-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charger-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-delete-modal-header">
          <title>충전기 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-charger-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='cpo-charger-delete-modal-msg'>{props.checkChargerList?.length}개의 충전기를 삭제하시겠습니까?</p>
          <div className='cpo-charger-delete-modal-list-wrapper'>
            {
              props.checkChargerList && props.chargerList && props.checkChargerList?.map((it, idx)=>{
                if(props.chargerList[it].IsDeleted){
                  return;
                }
                return(
                  <>
                    <div className='cpo-charger-delete-modal-item'>{props.chargerList[it].DisplaySerialNumber}</div>

                  </>
                )
              })
            }
          </div>
        </div>
        <div>
          <div className={'cpo-charger-modal-delete-button '} onClick={()=>{reqDeleteChargers()}}><p>삭제</p></div>
        </div>
      </div>
    </div>
  )
}
export default ChargerDeleteModal

