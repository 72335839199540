import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import {setNum} from '../../store.js'
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { getCookie } from '../../util/util.js';
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { HiSpeakerphone } from "react-icons/hi";

import Selector2 from '../../components/buttons/Selector2.js';

import { getStationList } from '../../api/infra/infraApi.js';
import { exceptDeletedStation } from '../../util/util';

import { getDefaultPricingPlan } from '../../api/chargerate/chargerateApi.js';

import { Loading } from '../../components/alerts/Loading.js';

import { putDefaultPricingPlan, patchDefaultPricingPlan } from '../../api/chargerate/chargerateApi.js';
import { numberInput } from '../../util/util.js';


import { getAllCharger, postCharger, postStation, getChargerDisplaySerialNumberDuplicationCheck, getChargerModelList } from '../../api/infra/infraApi.js';
import { getChargerLimit, getSubscription } from '../../api/subscription/subscriptionApi.js';

import ChargerLimitAlert from '../../components/alerts/ChargerLimitAlert.js';

const CpoFastEnroll = (props) => {
  let navigator = useNavigate();

  // redux
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  useEffect(()=>{
    dispatch(setNum({id:1, idx:0}));
  },[])

  useEffect(()=>{
    reqGetChargerLimit();
  },[])
  const reqGetChargerLimit = async()=>{
    let chargerResult = await getAllCharger();
    let limitCount = await getChargerLimit();
    setChargerTotal(chargerResult?.Total);
    setChargerLimit(limitCount?.count);
  }
  
  const [chargerTotal, setChargerTotal] = useState();
  const [chargerLimit, setChargerLimit] = useState();

  const stationNameInputRef = useRef(null);
  
  const chargerIdInputRef = useRef(null);


  const [category, setCategory] = useState(null);

  const [nameContent, setNameContent] = useState();
  
  const [chargerIdContent, setChargerIdContent] = useState();
  
  const [chargerOCCPContent, setChargerOCCPContent] = useState();
  
  const [memberRate, setMemberRate] = useState(null);
  const [nonMemberRate, setNonMemberRate] = useState(null);
  // 선택자 클릭 여부
  const [occpActive, setOccpActive] = useState('');
  const [occpSelect, setOccpSelect] = useState('none');
  
  const [stationActive, setStationActive] = useState('');
  const [stationSelect, setStationSelect] = useState('none');

  const activeReset = ()=>{
    setStationActive(false);
    setOccpActive(false);
    setChargerModelActive(false);
  }

  

  //  validation
  const [nameContentValidation, setNameContentValidation] = useState(null);
  const [stationValidation, setStationValidation] = useState(null);
  const [nameContentDuplication, setNameContentDuplication] = useState(null);
  const [chargerIdContentValidation, setChargerIdContentValidation] = useState(null);
  const [chargerOCCPContentValidation, setChargerOCCPContentValidation] = useState(null);
  const [chargerModelValidation, setChargerModelValidation] = useState(null);



  const [buttonTab1, setButtonTab1] =useState(false);

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);

  useEffect(()=>{
    reqGetStationList();
    reqGetDefaultPricingPlan();
  },[])
  
  // cpo가 소유한 station GET
  const reqGetStationList = async(id)=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
  }



  const stationNameDuplicationCheck = async()=>{
    await axios.get(`/infra/api/cpouser/v1/ChargingStations/isExist?codeName=${nameContent}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      if(!res.data){
        setNameContentDuplication(true);
      }
      else{
        setNameContentDuplication("disable");
      }
    })
  }

  const inputValidation = ()=>{
    if(category == 1){
      if(!nameContent || nameContentDuplication == "disable" || !nameContentDuplication){setNameContentValidation("invalid");}else{setNameContentValidation("");}
    }
    else{
      if(stationSelect == 'none'){setStationValidation("invalid")}else{setStationValidation("")}
    }
    if(!chargerDisplaySerialNumber || chargerDisplaySerialNumberDuplication == "disable" || !chargerDisplaySerialNumberDuplication){setChargerDisplaySerialNumberValidation("invalid");}else{setChargerDisplaySerialNumberValidation("");}
    if(!chargerIdContent){setChargerIdContentValidation("invalid");}else{setChargerIdContentValidation("");}
    if(!chargerOCCPContent){setChargerOCCPContentValidation("invalid");}else{setChargerOCCPContentValidation("");}
    if(!ChargerModel){setChargerModelValidation("invalid");}else{setChargerModelValidation(null);}
    if( (category == 1 ?nameContentDuplication:stationSelect)&& chargerDisplaySerialNumber && chargerIdContent && chargerOCCPContent && ChargerModel) return true;
    return false;
  }

  const [chargerDisplaySerialNumber, setChargerDisplaySerialNumber] = useState(null);
  const [chargerDisplaySerialNumberValidation, setChargerDisplaySerialNumberValidation] = useState(null);
  const [chargerDisplaySerialNumberDuplication, setChargerDisplaySerialNumberDuplication] = useState(null);
  
  const reqGetChargerSerialNumberDuplicationCheck = async()=>{
    let result = await getChargerDisplaySerialNumberDuplicationCheck(chargerDisplaySerialNumber);
    if(result.status === 200){
      console.log(result);
      if(result.data.IsExist === true){
        setChargerDisplaySerialNumberDuplication("disable");
      }
      else{
        setChargerDisplaySerialNumberDuplication(true);
      }
    }
    else{
      setChargerDisplaySerialNumberDuplication("disable");
    }
  }


  const enroll = async()=>{
    setLoadingOn(true);
    console.log(
      nameContent,
      chargerIdContent,
      chargerOCCPContent
    );
    if(!inputValidation()){return;}
    let postStationResult = await postStation(nameContent);
    if(postStationResult.status === 201){
      setStationEnrollCheck(postStationResult.data.ChargingStationId);
    }
    else{
      setErrMsg("충전소 등록에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      console.log(postStationResult);
      return;
    };
  }

  const enrollCharger = async()=>{
    let postChargerResult = await postCharger({
      "displaySerialNumber": chargerDisplaySerialNumber,
      "chargerModelId": ChargerModel,
      "chargingStationId": stationEnrollCheck,
      "protocol": chargerOCCPContent == "OCPP 1.6" ? "OCPP_1_6" : "OCPP_2_0_1",
      "chargePointSerialNumber": chargerIdContent
    });
    if(postChargerResult?.status === 200 || postChargerResult?.status === 201){
      setChargerId(postChargerResult.data.ChargerId);
    }
    else{
      setErrMsg("충전기 등록에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      console.log(postChargerResult);
      return;
    }
    setLoadingOn(false);
  }

  const [stationEnrollCheck, setStationEnrollCheck] = useState(null);

  useEffect(()=>{
    if(!stationEnrollCheck)return;
    enrollCharger();
  },[stationEnrollCheck])
  


  const enroll2 = async()=>{
    setLoadingOn(true);
    console.log(
      stationSelect,
      chargerIdContent,
      chargerOCCPContent
    );
    if(!inputValidation()){return;}


    let result = await axios.post('/infra/api/cpouser/v1/Chargers',{
        "displaySerialNumber": chargerDisplaySerialNumber,
        "chargerModelId": ChargerModel,
        "chargingStationId": stationSelect.ChargingStationId,
        "protocol": chargerOCCPContent == "OCPP 1.6" ? "OCPP_1_6" : "OCPP_2_0_1",
        "chargePointSerialNumber": chargerIdContent
      },{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        console.log(res);
        setChargerId(res.data.ChargerId);
      })
      .catch((err)=>{
        console.log(err);
      })
    setLoadingOn(false);
  }


  const [tabIdx, setTabIdx] = useState(0);
  const [chargerId, setChargerId] = useState(null);

  // useEffect(()=>{
  //   if(!chargerId)return;
  //   setTimeout(() => {
  //     requestChargerRate(chargerId);
  //   }, 1000);
  // },[chargerId])



  
  // 기본 설정 단가 get
  const reqGetDefaultPricingPlan = async()=>{
    let result = await getDefaultPricingPlan();
    if(result.status === 200){
      setMemberRate(result.data.memberPlan?.defaultUnitPrice);
      setNonMemberRate(result.data.nonMemberPlan?.defaultUnitPrice);
    }
    else {
    }
    console.log(result);
  }



  const reqPatchDefaultPricingPlan = async(pricingType)=>{
    let reqObj = {
      "pricingType": pricingType,
      "currency": "KRW",
      "defaultUnitPrice": pricingType==="Member"?memberRate:nonMemberRate
    }
    console.log(reqObj);

    let result = await patchDefaultPricingPlan(reqObj);
    if(result.status === 200){
      pricingType==="Member"?setMemberRateCheck(true):setNonMemberRateCheck(true);
    }
    else if(result.status === 400){
      if(result.data.ErrorCode ==="PLAN_NOT_EXIST"){
        await reqPutDefaultPricingPlan(pricingType);
      }
      else{
        setErrMsg("요금설정에 실패하였습니다.");
        setTimeout(() => {
          setErrMsg("");
        }, 3000);
        pricingType==="Member"?setMemberRateCheck(false):setNonMemberRateCheck(false);
      }
    }
    reqGetDefaultPricingPlan();
  }
  const reqPutDefaultPricingPlan = async(pricingType)=>{
    let reqObj = {
      "pricingType": pricingType,
      "currency": "KRW",
      "defaultUnitPrice": pricingType==="Member"?memberRate:nonMemberRate,
      "unitPrices":[]
    }
    let result = await putDefaultPricingPlan(reqObj);
    if(result.status === 200){
      pricingType==="Member"?setMemberRateCheck(true):setNonMemberRateCheck(true);
    }
    else {
      console.log(result);
      setErrMsg("요금설정에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      pricingType==="Member"?setMemberRateCheck(false):setNonMemberRateCheck(false);
    }
    reqGetDefaultPricingPlan();
  }

  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  const reqGetSubscription = async()=>{
    let result = await getSubscription();
    if(result.status === 200){
      console.log(result);
      setSubscriptionInfo(result.data);
    }
    else{
      console.log(result);
    }
  }

  useEffect(()=>{
    reqGetSubscription();
  },[])


  const [enrollCheck, setEnrollCheck] = useState(false);
  const [rateInputMode, setRateInputMode] = useState(false);
  
  const [loadingOn, setLoadingOn] = useState(false);
  const [chargerLimitAlertOn, setChargerLimitAlertOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const [memberRateCheck, setMemberRateCheck] = useState(false);
  const [nonMemberRateCheck, setNonMemberRateCheck] = useState(false);

  useEffect(()=>{
    if(memberRateCheck && nonMemberRateCheck) setTabIdx(3);
  },[memberRateCheck , nonMemberRateCheck])




  const [modelList, setModelList] = useState([]);
  
    const reqGetModelList = async()=>{
      let result = await getChargerModelList();
      if(result.status === 200){
        setModelList(result.data.Results);
      }
    }
  
    useEffect(()=>{
      reqGetModelList();
    },[])
  
    // 선택자 클릭 여부
    const [ChargerModel, setChargerModel] = useState('');
    const [ChargerModelVal, setChargerModelVal] = useState('');
    const [ChargerModelActive, setChargerModelActive] = useState(null);
    
    
    useEffect(()=>{
      if(!ChargerModelVal)return;
      setChargerModel(ChargerModelVal.Id);
    },[ChargerModelVal])
  

  const goToModelManagement = ()=>{
    navigator('/cpo/charger-model-management');
  }


  return (
    <div className='cpo-fast-enroll-container'>
      {chargerLimitAlertOn && <ChargerLimitAlert setOnOff={setChargerLimitAlertOn} total={chargerTotal} limit={chargerLimit}/>}
        <div className='cpo-fast-enroll-grid-container'>
            <div className='cpo-fast-enroll-front'>

              <img className='fast-enroll-background' alt="" />
              <img className='fast-enroll-logo'/>

            </div>
            <div className='fast-enroll-back-wrapper'>
              {
                errMsg&&<div className={"cpo-fast-enroll-msg"}>{errMsg}</div>
              }
              {
                tabIdx == 0 ?
                <div className={'cpo-fast-enroll-button '+`${category==null?"none":""}`} onClick={()=>{
                  if((chargerTotal&&chargerLimit)&&chargerTotal>=chargerLimit){
                    setChargerLimitAlertOn(true);
                  }
                  else setTabIdx(1);
                  }}>
                  <p>다음</p>
                  <FaChevronRight/>
                </div>
                :
                tabIdx === 1 ?
                
                  enrollCheck ?

                  <div className={'cpo-fast-enroll-button ' + `${buttonTab1 ? "none":""}`} onClick={()=>{
                    if(!inputValidation()){return;}
                    if(category == 1){ enroll();}
                    else{enroll2();}
                    setTabIdx(2);
                    }}>
                    {
                      !loadingOn ?
                      <>
                        <p>등록</p>
                        <FaChevronRight/>
                      </>
                      :
                      <Loading/>
                    }
                  </div>
                  :
                  <div className={'cpo-fast-enroll-button ' + `${buttonTab1 ? "none":""}`} onClick={()=>{
                    if(!inputValidation()){return;}
                    setEnrollCheck(true);
                    }}>
                    <p>다음</p>
                    <FaChevronRight/>
                  </div>
                
                
                :
                
                
                tabIdx == 2 ?
                  !rateInputMode?

                  <div className='cpo-fast-enroll-button' onClick={()=>{
                    setTabIdx(3);
                  }}>
                    <p>건너뛰기</p>
                    <FaChevronRight/>
                  </div>
                  :
                  <div className='cpo-fast-enroll-button' onClick={async()=>{
                    setLoadingOn(true);
                    await reqPatchDefaultPricingPlan("Member");
                    await reqPatchDefaultPricingPlan("NonMember");
                    setLoadingOn(false);
                    
                  }}>
                    {
                    !loadingOn ?
                    <>
                      <p>설정</p>
                      <FaChevronRight/>
                    </>
                    :
                    <Loading/>
                    }
                  </div>
                
                :
                <></>
              }
              {
                tabIdx == 0 ?
                <></>
                :
                tabIdx == 1 ?
                  enrollCheck?
                  <div className='cpo-fast-enroll-button left' onClick={()=>{
                    setEnrollCheck(false);
                    }}>
                    <FaChevronLeft/>
                    <p>이전</p>
                  </div>
                  :
                  <div className='cpo-fast-enroll-button left' onClick={()=>{
                    setTabIdx(0);
                    setEnrollCheck(false);
                    }}>
                    <FaChevronLeft/>
                    <p>이전</p>
                  </div>
                :
                tabIdx == 2 ?
                !rateInputMode ?
                <></>
                :
                <div className='cpo-fast-enroll-button left' onClick={()=>{
                  setRateInputMode(false);
                }}>
                  <FaChevronLeft/>
                  <p>이전</p>
                </div>
                :
                <></>
              }
              {
                tabIdx !== 0 ?
                <div className='cpo-fast-enroll-step-container'>
                  <div className={'cpo-fast-enroll-step '+`${tabIdx == 1 ?"current":""}`}>1</div>
                  <p className={tabIdx == 1 ? 'current':''}>충전소&충전기 등록</p>
                  <FaChevronRight className={tabIdx == 1 ? 'current':''}/>
                  <div className={'cpo-fast-enroll-step '+`${tabIdx == 2 ?"current":""}`}>2</div>
                  <p className={tabIdx == 2 ? 'current':''}>요금 설정</p>
                  <FaChevronRight className={tabIdx == 2 ? 'current':''}/>
                  <div className={'cpo-fast-enroll-step '+`${tabIdx == 3 ?"current":""}`}>3</div>
                  <p className={tabIdx == 3 ? 'current':''}>빠른 등록 완료!</p>
                </div>
                :<></>
              }
              {
              tabIdx == 0 ?
              <div className='cpo-fast-enroll-back initial'>
                <div className='cpo-fast-enroll-sub-title'>구독 정보</div>
                <div>
                  {
                    subscriptionInfo && subscriptionInfo.length !== 0 ? 
                      subscriptionInfo?.map((it,idx)=>{
                        return(
                          <>
                            <p className='cpo-fast-enroll-subscription-info'>{it.subscriptionProduct?.name}</p>
                          </>
                        )})

                    :
                    <p className='cpo-fast-enroll-subscription-info empty'>구독된 상품이 없습니다.</p>
                  }
                </div>
                <div className='cpo-fast-enroll-sub-title'>충전기 등록 현황</div>
                <div className='cpo-fast-enroll-charger-limit-info-container'>
                  <p className={`${parseInt(chargerTotal) === parseInt(chargerLimit) ? "limited":""}`}>{chargerTotal}</p><p>/</p><p >{chargerLimit}</p>
                </div>
                <div className='cpo-fast-enroll-back-initial-title'>
                  <p>원하시는</p><p>유형을 선택</p><p>해주세요</p>  
                </div>
                <div className={'cpo-fast-enroll-category-button '+`${category == 0 ? "selected":""}`} onClick={()=>{setCategory(0)}}>
                  <div className='cpo-fast-enroll-category-select-outer'>
                    <div className='cpo-fast-enroll-category-select-inner'></div>
                  </div>
                  <div className='cpo-fast-enroll-category-content-container'>
                    <p>기존 등록</p>
                    <p>이미 충전소와 충전기가 있어요</p>
                  </div>
                </div>
                <div className={'cpo-fast-enroll-category-button '+`${category == 1 ? "selected":""}`} onClick={()=>{setCategory(1)}}>
                  <div className='cpo-fast-enroll-category-select-outer'>
                    <div className='cpo-fast-enroll-category-select-inner'></div>
                  </div>
                  <div className='cpo-fast-enroll-category-content-container'>
                    <p>신규 등록</p>
                    <p>새로운 충전소와 충전기를 등록하고 싶어요</p>
                  </div>
                </div>
              </div>
              :
              tabIdx == 1 ?

              enrollCheck === false ?
                <div className='cpo-fast-enroll-input-list-container'>  
                  <p>충전소 명</p>
                  {
                    category == 1 ?
                    <div className="fast-enroll-input-container name">
                      <input ref={stationNameInputRef} type="text" placeholder='충전소 명을 입력해주세요' value={nameContent} onChange={(e)=>{ setNameContent(e.target.value); setNameContentDuplication(null);}} onClick={(e)=>{e.stopPropagation(); }}/>
                      <div className={`fast-enroll-station-name-validation ` + `${nameContent==""||!nameContent?"disable":""}`} onClick={()=>{stationNameDuplicationCheck()}}><p>중복확인</p></div>
                      {
                        nameContentDuplication == "disable" ? 
                          <p className={'fast-enroll-input-validation-msg alert'}>중복된 충전소명 입니다.</p>
                        :
                          nameContentDuplication ? 
                            <p className={'fast-enroll-input-validation-msg able'}>사용가능한 충전소명 입니다.</p>
                          :
                            nameContent == "" || nameContent == null ?
                              nameContentValidation == "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>충전소명을 입력해주세요.</p>:<></>
                            :
                            <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
                      }
                    </div>
                    :
                    <div className="fast-enroll-input-container">
                      <Selector2 name={'ChargingStationName'} dataList={stationInfo} select={stationSelect} setSelect={setStationSelect} active={stationActive} setActive={setStationActive} activeReset={activeReset}/>
                      {
                      stationValidation == "invalid" ?
                      <p className={'fast-enroll-input-validation-msg alert'}>충전소를 선택해주세요.</p>
                      :
                      <></>
                    }
                    </div>
                  }
                  <p>충전기 식별번호</p>
                  <div className="fast-enroll-input-container">
                    <input type="text" placeholder='충전기 식별번호를 입력해주세요' value={chargerDisplaySerialNumber} onChange={(e)=>{ setChargerDisplaySerialNumber(e.target.value); setChargerDisplaySerialNumberDuplication(null);}} onClick={(e)=>{e.stopPropagation(); }}/>
                    <div className={`fast-enroll-station-name-validation ` + `${chargerDisplaySerialNumber===""||!chargerDisplaySerialNumber?"disable":""}`} onClick={()=>{reqGetChargerSerialNumberDuplicationCheck()}}><p>중복확인</p></div>
                    {
                      chargerDisplaySerialNumberDuplication === "disable" ? 
                        <p className={'fast-enroll-input-validation-msg alert'}>중복된 식별번호 입니다.</p>
                      :
                      chargerDisplaySerialNumberDuplication ? 
                          <p className={'fast-enroll-input-validation-msg able'}>사용가능한 식별번호 입니다.</p>
                        :
                          chargerDisplaySerialNumber === "" || chargerDisplaySerialNumber == null ?
                          chargerDisplaySerialNumberValidation === "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>식별번호를 입력해주세요.</p>:<></>
                          :
                          <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
                    }
                  </div>
                  <p>충전기 하드웨어 일련번호</p> 
                  <div className="fast-enroll-input-container">
                    <input ref={chargerIdInputRef} type="text" placeholder='충전기 하드웨어 일련번호를 입력해주세요' value={chargerIdContent} onChange={(e)=>{ setChargerIdContent(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    {
                      chargerIdContentValidation == "invalid" ?
                      <p className={'fast-enroll-input-validation-msg alert'}>충전기 하드웨어 일련번호를 입력해주세요.</p>
                      :
                      <></>
                    }
                  </div>
                  <p>OCPP</p>
                  <div className="fast-enroll-input-container occp">
                    <div className={'fast-enroll-selector '+occpActive} onClick={(e)=>{e.stopPropagation(); 
                      if(occpSelect == ''){setOccpSelect("none")}else{setOccpSelect("")} 
                      if(occpActive == ''){setOccpActive("active")}else{setOccpActive("")} 
                    }}>
                      <p className={chargerOCCPContent==null ?'none':""}>{chargerOCCPContent==null ? "프로토콜 선택하기" : chargerOCCPContent}</p>
                    </div>
                    <FaCaretDown className='cpo-occp-down-icon' onClick={(e)=>{e.stopPropagation(); 
                      if(occpSelect == ''){setOccpSelect("none")}else{setOccpSelect("")} 
                      if(occpActive == ''){setOccpActive("active")}else{setOccpActive("")} 
                    }}/>
                    <div className={'fast-enroll-selector-item-list ' + occpSelect}>
                      <div className={'fast-enroll-selector-item ' + occpSelect} onClick={()=>{setChargerOCCPContent("OCPP 1.6"); setOccpSelect('none');}}>
                        <p>OCPP 1.6</p>
                        {
                          chargerOCCPContent == "OCPP 1.6"?
                          <FaCheck className='cpo-occp-check-icon'/>
                          :null
                        }
                      </div>
                      {/* <div className={'fast-enroll-selector-item ' + occpSelect} onClick={()=>{setChargerOCCPContent("OCPP 2.0.1"); setOccpSelect('none');}}>
                        <p>OCPP 2.0.1</p>
                        {
                          chargerOCCPContent == "OCPP 2.0.1"?
                          <FaCheck className='cpo-occp-check-icon'/>
                          :null
                        }
                      </div> */}
                    </div>
                    {
                      chargerOCCPContentValidation == "invalid" ? 
                      <p className={'fast-enroll-input-validation-msg alert'}>OCPP 프로토콜을 선택해주세요.</p>
                      :
                      <></>
                    }
                  </div>
                  <p>충전기 모델</p>
                  <div className="fast-enroll-input-container occp">

                  <Selector2 append={true} name={"Name"} maxCount={5} dataList={modelList} select={ChargerModelVal} setSelect={setChargerModelVal} active={ChargerModelActive} setActive={setChargerModelActive} activeReset={activeReset} appendFunction={goToModelManagement}/>
                  
                  {
                    chargerModelValidation == "invalid" ? 
                    <p className={'fast-enroll-input-validation-msg alert'}>충전기 모델을 선택해주세요.</p>
                    :
                    <></>
                  }
                  </div>
                </div>
                :
                <>
                <div className="cpo-fast-enroll-input-list-container">
                  <p className='cpo-fast-enroll-input-check-msg'><HiSpeakerphone/>등록할 충전소 및 충전기 정보를 확인 후 등록 버튼을 눌러주세요.</p>
                  <p className='cpo-fast-enroll-input-check-title'>충전소 정보</p>
                  <div className='cpo-fast-enroll-input-check-container station'>
                    <div>
                      <div>충전소 명</div>
                      <div>
                      {
                        category == 1?
                        nameContent
                        :
                        stationSelect.ChargingStationName
                      }
                      </div>
                    </div>
                  </div>
                  
                  <p className='cpo-fast-enroll-input-check-title'>충전기 정보</p>
                  <div className='cpo-fast-enroll-input-check-container'>
                    <div>
                      <div>충전기 식별번호</div>
                      <div>{chargerDisplaySerialNumber}</div>
                    </div>
                    <div>
                      <div>충전기 하드웨어 일련번호</div>
                      <div>{chargerIdContent}</div>
                    </div>
                    <div>
                      <div>OCPP</div>
                      <div>{chargerOCCPContent}</div>
                    </div>
                    <div>
                      <div>충전기 모델명</div>
                      <div>{ChargerModelVal.Name}</div>
                    </div>
                  </div>
                </div>
                </>


              :
              tabIdx == 2 ?
              <div className='cpo-fast-enroll-input-list-container'>
                <p className='cpo-fast-enroll-input-check-msg'><HiSpeakerphone/>{!rateInputMode ?"첫 충전소와 충전기를 등록할 경우 요금 설정이 필요합니다!":"회원과 비회원의 요금을 설정하세요"}</p>
                {
                  !rateInputMode ?
                  <>
                  <div className={'cpo-fast-enroll-category-button '} onClick={()=>{setRateInputMode(true)}}>
                    <div className='cpo-fast-enroll-category-select-outer'>
                      <div className='cpo-fast-enroll-category-select-inner'></div>
                    </div>
                    <div className='cpo-fast-enroll-category-content-container'>
                      <p>요금 설정하기</p>
                      <p>회원, 비회원 별 요금을 설정해요</p>
                    </div>
                  </div>
                  </>
                  :
                  <form onSubmit={(e)=>{}}>
                    <div>
                      <p className='fast-enroll-input-title'>회원 요금 <color>(원 kWh, 부가세 포함)</color></p> 
                      <div className="fast-enroll-input-container">
                        <input type="text" placeholder='요금을 설정해주세요' value={memberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setMemberRate(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                      <p className='fast-enroll-input-title'>비회원 요금 <color>(원 kWh, 부가세 포함)</color></p>
                      <div className="fast-enroll-input-container">
                        <input type="text" placeholder='요금을 설정해주세요' value={nonMemberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setNonMemberRate(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                      </div>
                    </div>
                  </form>
                }
              </div>
              :
              <div className='cpo-fast-enroll-done-container'>
                <p>등록이 완료되었어요</p>
                <div onClick={()=>{navigator('/cpo')}}><p>대시보드로 이동</p></div>
              </div>
            }
            </div>
        </div>
        
    </div>
  )
}
export default CpoFastEnroll;