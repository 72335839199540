/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-20
 * @file 찾아줌 페이지 생성을 위해서 사용하는 유틸리티 컴포넌트 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */

/// <summary>
/// POI 구분키와 한글이름 표 
/// </summary>
export const poiKeyLabel = [
    {"keyValue": "cafe", "korLabel": "카페"},
    {"keyValue": "kidsCafe", "korLabel": "어린이카페"},
    {"keyValue": "restaurant", "korLabel": "식당"},
    {"keyValue": "familyRestaurant", "korLabel": "패밀리레스토랑"},
    {"keyValue": "fastFood", "korLabel": "패스트푸드점"},
    {"keyValue": "spaceRental", "korLabel": "공간대여점"},
    {"keyValue": "theater", "korLabel": "극장"},
    {"keyValue": "leisureSports", "korLabel": "레저스포츠용품점"}, 
    {"keyValue": "beauty", "korLabel": "미용"},
    {"keyValue": "shopping", "korLabel": "쇼핑"},
    {"keyValue": "studyCafe", "korLabel": "스터디카페"},
    {"keyValue": "medicalOthers", "korLabel": "의료용품점"},
    {"keyValue": "pub", "korLabel": "술집"},
    {"keyValue": "hotel", "korLabel": "호텔"},
    {"keyValue": "accommodation", "korLabel": "숙박업소"},
    {"keyValue": "condo", "korLabel": "콘도미니엄"},
    {"keyValue": "culturalFacilities", "korLabel": "문화시설"},
    {"keyValue": "touristAttraction", "korLabel": "여행명소"},
    {"keyValue": "culture", "korLabel": "문화"},
    {"keyValue": "aquarium", "korLabel": "수족관"},
    {"keyValue": "themeStreet", "korLabel": "테마거리"},
    {"keyValue": "cafeStreet", "korLabel": "카페거리"},
    {"keyValue": "university", "korLabel": "대학교"},
    {"keyValue": "school", "korLabel": "초/중/고"},
    {"keyValue": "educationalFacilities", "korLabel": "교육시설"},
    {"keyValue": "academy", "korLabel": "학원"},
    {"keyValue": "finance", "korLabel": "금융"},
    {"keyValue": "pharmacy", "korLabel": "약국"},
    {"keyValue": "hospital", "korLabel": "병원"},
    {"keyValue": "parkingLot", "korLabel": "주차장"},
    {"keyValue": "dailyConvenience", "korLabel": "생활편의시설"},
    {"keyValue": "cultureCenter", "korLabel": "문화센터"},
    {"keyValue": "realEstate", "korLabel": "부동산"},
    {"keyValue": "subway", "korLabel": "지하철"},
    {"keyValue": "convenienceStore", "korLabel": "편의점"},
    {"keyValue": "park", "korLabel": "공원"},
    {"keyValue": "publicInstitution", "korLabel": "공공기관"},
    {"keyValue": "company", "korLabel": "회사"},
    {"keyValue": "researchFacilities", "korLabel": "연구시설"},
    {"keyValue": "golfCourse", "korLabel": "골프장"},
    {"keyValue": "concertHall", "korLabel": "공연장"},
    {"keyValue": "fishingSpot", "korLabel": "낚시터"},
    {"keyValue": "amusementFacilities", "korLabel": "놀이시설"},
    {"keyValue": "church", "korLabel": "교회"},
    {"keyValue": "cathedral", "korLabel": "성당"},
    {"keyValue": "temple", "korLabel": "사찰"},
    {"keyValue": "welfareFacilities", "korLabel": "복지시설"},
    {"keyValue": "apartment", "korLabel": "아파트"},
    {"keyValue": "residentialFacilities", "korLabel": "주거시설"},
    {"keyValue": "gasStation", "korLabel": "주유소"},
    {"keyValue": "restArea", "korLabel": "휴게소"},
];

export const getPoiKeyKorLabel = (keyName) => 
{
    let result = poiKeyLabel.find( it => it.keyValue === keyName);
    console.log('[EMOOV] POI KEY KOREAN NAME:', result);
    return result !== null ? result.korLabel : null;
}