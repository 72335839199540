import React from 'react'

import { RiCustomerServiceFill, RiTeamFill, RiBattery2ChargeFill, RiTimerFlashLine, RiHomeGearLine } from "react-icons/ri";
import { FaChargingStation } from "react-icons/fa";
import { MdSpaceDashboard, MdManageAccounts } from "react-icons/md";
import { FaPlugCircleBolt } from "react-icons/fa6";
import { TbSettingsBolt, TbReportAnalytics,TbReportMoney } from "react-icons/tb";
import { FaChevronDown } from "react-icons/fa";
import { BsBuildingFillGear } from "react-icons/bs";
import { TbServerBolt } from "react-icons/tb";
import { FaSearchLocation } from 'react-icons/fa';
import { HiOutlineDocumentReport } from "react-icons/hi";

import { GrMoney } from "react-icons/gr";
import { TbRosetteDiscount } from "react-icons/tb";

const NavButton = (props) => {
  // Icon return 함수
  const icon = (idx)=>{
    switch (idx) {
      case 0:
        return <MdSpaceDashboard/>;
      
      case 1:
        return <RiTimerFlashLine/>;
    
      case 2:
        return <RiHomeGearLine/>;
      
      case 3:
      return <FaPlugCircleBolt/>;
      
      case 4:
      return <BsBuildingFillGear/>;
      
      case 5:
      return <TbServerBolt/>;

      case 6:
        return <GrMoney/>;

      case 7:
        return <TbRosetteDiscount/>;
      
      case 8:
        return <FaChargingStation/>;

      case 9:
        return <TbReportAnalytics/>;
      
      case 10:
        return <TbReportMoney/>;
      
      case 11:
        return <RiCustomerServiceFill/>;
      
      case 12:
        return <TbSettingsBolt/>;

      case 13:
        return <MdManageAccounts/>;
      
      case 14:
        return <RiTeamFill/>;
      
      case 15:
        return <HiOutlineDocumentReport/>;

      /// Added by edeward at 2024.11.26
      /// Start Code
      case 101:
        return  <FaSearchLocation/>;
      /// End Code
      case 102:
        return  <MdSpaceDashboard/>;
      
      default:
        return <RiBattery2ChargeFill/>;
    }
  }



  return (
    <>
    <div className={props.selectedIdx === props.id ? "active nav-button-inner " : "nav-button-inner "}>
      <div onClick={props.onClick} className={"nav-button"}>
        {icon(props.data.id)}
        <p>{props.data.name}</p>
        { // subNav가 있는가?
          props.data.items ? 
            <FaChevronDown className={props.selectedIdx === props.id ? 'nav-chevron active':'nav-chevron'}/>
          :null
        }
      </div>
    </div>
    {(props.fast==="fast") &&<div className='first-enroll-guide'>첫 등록이신가요?<br/><color>빠른등록</color>을 이용해보세요!</div>}
    </>
  )
}

export default NavButton;