import { configureStore, createSlice } from '@reduxjs/toolkit';

// 페이지 상태 관리
let page = createSlice({
  name: 'page',
  initialState: { num: { id: -1 } },
  reducers: {
    setNum(state, action) {
      state.num = action.payload;
    },
  },
});

// Redux 스토어 생성
export default configureStore({
  reducer: {
    page: page.reducer,
  },
});

// 액션 내보내기
export let { setNum } = page.actions;