/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-01
 * @file 찾아줌 충전소 최적위치 시뮬레이션 요청 팝업 모달
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useState, useEffect } from 'react';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent, ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent, ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FaInfoCircle } from 'react-icons/fa';
import data from '../../../data/localData.json';
import './ZoomSimRequestModal.css';
import { postZoomSimRequest } from '../../../api/zoom/zoomApi';


export const ZoomSimRequestModal = (props) => {

    let userSystemId = localStorage.getItem('user-systemId');

    const [zcodeValue, setZcodeValue] = useState('');
    const [zcodeText, setZcodeText] = useState('');
    const [sigCodeValue, setSigCodeValue] = useState('');
    const [filteredSigData, setFilteredSigData] = useState([]);
    /// 영향요인 선택여부  
    const [factor1Enable, setFactor1Enable] = useState(true);
    const [factor2Enable, setFactor2Enable] = useState(true);
    const [factor3Enable, setFactor3Enable] = useState(true);
    const [factor4Enable, setFactor4Enable] = useState(true);
    const [factor5Enable, setFactor5Enable] = useState(true);
    const [factor6Enable, setFactor6Enable] = useState(true);
    const [factor7Enable, setFactor7Enable] = useState(true);
    const [factor8Enable, setFactor8Enable] = useState(true);
    const [factor9Enable, setFactor9Enable] = useState(true);
    const [factor10Enable, setFactor10Enable] = useState(true);
    const [factor11Enable, setFactor11Enable] = useState(true);
    /// 영향요인 가중치 값
    const [factor1Value, setFactor1Value] = useState(1.0);
    const [factor2Value, setFactor2Value] = useState(1.0);
    const [factor3Value, setFactor3Value] = useState(1.0);
    const [factor4Value, setFactor4Value] = useState(1.0);
    const [factor5Value, setFactor5Value] = useState(1.0);
    const [factor6Value, setFactor6Value] = useState(1.0);
    const [factor7Value, setFactor7Value] = useState(1.0);
    const [factor8Value, setFactor8Value] = useState(1.0);
    const [factor9Value, setFactor9Value] = useState(1.0);
    const [factor10Value, setFactor10Value] = useState(1.0);
    const [factor11Value, setFactor11Value] = useState(1.0);
    /// 입력값 검사 결과 값 
    const [disableOkButton, setDisableOkButton] = useState(true);
    /// 오류메시지 
    const [factor1ValueErrMsg, setFactor1ValueErrMsg] = useState('');
    const [factor2ValueErrMsg, setFactor2ValueErrMsg] = useState('');
    const [factor3ValueErrMsg, setFactor3ValueErrMsg] = useState('');
    const [factor4ValueErrMsg, setFactor4ValueErrMsg] = useState('');
    const [factor5ValueErrMsg, setFactor5ValueErrMsg] = useState('');
    const [factor6ValueErrMsg, setFactor6ValueErrMsg] = useState('');
    const [factor7ValueErrMsg, setFactor7ValueErrMsg] = useState('');
    const [factor8ValueErrMsg, setFactor8ValueErrMsg] = useState('');
    const [factor9ValueErrMsg, setFactor9ValueErrMsg] = useState('');
    const [factor10ValueErrMsg, setFactor10ValueErrMsg] = useState('');
    const [factor11ValueErrMsg, setFactor11ValueErrMsg] = useState('');

    const dataFields = {text: 'label', value: 'code'};
    const zcodeData = data['zcode'];
    const sigData = data['sigcode'];

    const [visibleDialog, setVisibleDialog] = useState(props.visible);
    useEffect(() => {
        setVisibleDialog(props.visible);
    }, [props.visible]);

    useEffect(() => {
        okButtonEnableCheck();
    },[zcodeValue, sigCodeValue, 
        factor1ValueErrMsg, factor2ValueErrMsg, factor3ValueErrMsg, 
        factor4ValueErrMsg, factor5ValueErrMsg, factor6ValueErrMsg, 
        factor7ValueErrMsg, factor8ValueErrMsg, factor9ValueErrMsg, 
        factor10ValueErrMsg, factor11ValueErrMsg]
    );

    const weightData = [1.0, 1.5, 2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0];

    /// Syncfusion 다이얼로그 버튼 설정
    const dialogButtons = [
        {
            buttonModel: {
                content: '신청',
                cssClass: 'e-flat',
                isPrimary: false,
                disabled: disableOkButton
            },
            click: async() => {

                await postZoomSimRequest({
                    userSystemId: userSystemId,
                    sigCode: sigCodeValue,
                    factor1Enable: factor1Enable,
                    factor2Enable: factor2Enable,
                    factor3Enable: factor3Enable,
                    factor4Enable: factor4Enable,
                    factor5Enable: factor5Enable,
                    factor6Enable: factor6Enable,
                    factor7Enable: factor7Enable,
                    factor8Enable: factor8Enable,
                    factor9Enable: factor9Enable,
                    factor10Enable: factor10Enable,
                    factor11Enable: factor11Enable,
                    factor1Value: factor1Value,
                    factor2Value: factor2Value,
                    factor3Value: factor3Value,
                    factor4Value: factor4Value,
                    factor5Value: factor5Value,
                    factor6Value: factor6Value,
                    factor7Value: factor7Value,
                    factor8Value: factor8Value,
                    factor9Value: factor9Value,
                    factor10Value: factor10Value,
                    factor11Value: factor11Value
                });

                setVisibleDialog(false);
                props.closeDialogCallback();
            }
        },
        {
            buttonModel: {
                content: '닫기',
                cssClass: 'e-flat',
            },
            click: () => {
                setVisibleDialog(false);
                props.closeDialogCallback();
            }
        }
    ];

    /// 시도 목록 콤보박스 선택 변경 이벤트 핸들러 
    const onChangeZcode = (args) => {
        setZcodeValue(args.itemData === null ? 'null' : args.itemData[dataFields.value].toString());
        setZcodeText(args.itemData === null ? 'null' : args.itemData[dataFields.text].toString());

        const temp = sigData.filter(item => item.code.startsWith(args.itemData[dataFields.value].toString()));
        setFilteredSigData(temp);
        okButtonEnableCheck();
        //console.log(filteredSigData);
    };

    /// 군구 목록 콤보박스 선택 변경 이벤트 핸들러 
    const onChangeSigCode = (args) => {
        setSigCodeValue(args.itemData === null ? '' : args.itemData[dataFields.value].toString());
        okButtonEnableCheck();
    };

    /// 영향요인1 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor1Check = (args) => {
        setFactor1Enable(args.checked);
    };
    /// 영향요인2 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor2Check = (args) => {
        setFactor2Enable(args.checked);
    };
    /// 영향요인3 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor3Check = (args) => {
        setFactor3Enable(args.checked);
    };
    /// 영향요인4 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor4Check = (args) => {
        setFactor4Enable(args.checked);
    };
    /// 영향요인5 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor5Check = (args) => {
        setFactor5Enable(args.checked);
    };
    /// 영향요인6 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor6Check = (args) => {
        setFactor6Enable(args.checked);
    };
    /// 영향요인7 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor7Check = (args) => {
        setFactor7Enable(args.checked);
    };
    /// 영향요인8 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor8Check = (args) => {
        setFactor8Enable(args.checked);
    };
    /// 영향요인9 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor9Check = (args) => {
        setFactor9Enable(args.checked);
    };
    /// 영향요인10 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor10Check = (args) => {
        setFactor10Enable(args.checked);
    };
    /// 영향요인11 가중치 체크박스 변경 이벤트 핸들러
    const onChangeFactor11Check = (args) => {
        setFactor11Enable(args.checked);
    };

    /// OK 버튼 활성화 체크 함수
    const okButtonEnableCheck = () => {
        if(zcodeValue === '' || sigCodeValue === '') {
            setDisableOkButton(true);
            return;
        }
        if(factor1ValueErrMsg !== '' || factor2ValueErrMsg !== '' ||
            factor3ValueErrMsg !== '' || factor4ValueErrMsg !== '' || 
            factor5ValueErrMsg !== '' || factor6ValueErrMsg !== '' ||
            factor7ValueErrMsg !== '' || factor8ValueErrMsg !== '' ||
            factor9ValueErrMsg !== '' || factor10ValueErrMsg !== '' ||
            factor11ValueErrMsg !== '') {
            setDisableOkButton(true);
            return;
        }
        
        setDisableOkButton(false);
    };

    /// 가중치값 유효성 검사 함수 
    const validateFactorInputValue = (inputValue) => {
        const value = parseFloat(inputValue);
        if(isNaN(value)) {
            return false;
        }
        if(value < 1.0 || value > 10.0) {
            return false;
        }
        return true;
    };

    const valueErrorMsg = '가중치는 1.0에서 10.0사이의 값만 입력할 수 있습니다.';
    /// 영향요인1 가중치값 변경 이벤트 핸들러 
    const onChangeFactor1Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor1Value(args.value);
            setFactor1ValueErrMsg('');
        }
        else {
            setFactor1ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인2 가중치값 변경 이벤트 핸들러 
    const onChangeFactor2Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor2Value(args.value);
            setFactor2ValueErrMsg('');
        }
        else {
            setFactor2ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인3 가중치값 변경 이벤트 핸들러 
    const onChangeFactor3Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor3Value(args.value);
            setFactor3ValueErrMsg('');
        }
        else {
            setFactor3ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인4 가중치값 변경 이벤트 핸들러 
    const onChangeFactor4Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor4Value(args.value);
            setFactor4ValueErrMsg('');
        }
        else {
            setFactor4ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인5 가중치값 변경 이벤트 핸들러 
    const onChangeFactor5Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor5Value(args.value);
            setFactor5ValueErrMsg('');
        }
        else {
            setFactor5ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인6 가중치값 변경 이벤트 핸들러 
    const onChangeFactor6Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor6Value(args.value);
            setFactor6ValueErrMsg('');
        }
        else {
            setFactor6ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인7 가중치값 변경 이벤트 핸들러 
    const onChangeFactor7Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor7Value(args.value);
            setFactor7ValueErrMsg('');
        }
        else {
            setFactor7ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인8 가중치값 변경 이벤트 핸들러 
    const onChangeFactor8Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor8Value(args.value);
            setFactor8ValueErrMsg('');
        }
        else {
            setFactor8ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인9 가중치값 변경 이벤트 핸들러 
    const onChangeFactor9Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor9Value(args.value);
            setFactor9ValueErrMsg('');
        }
        else {
            setFactor9ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인10 가중치값 변경 이벤트 핸들러 
    const onChangeFactor10Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor10Value(args.value);
            setFactor10ValueErrMsg('');
        }
        else {
            setFactor10ValueErrMsg(valueErrorMsg);
        }
    };
    /// 영향요인11 가중치값 변경 이벤트 핸들러 
    const onChangeFactor11Value = (args) => {
        if(validateFactorInputValue(args.value) == true) {
            setFactor11Value(args.value);
            setFactor11ValueErrMsg('');
        }
        else {
            setFactor11ValueErrMsg(valueErrorMsg);
        }
    };


    return (
        <div>
            <DialogComponent width='500px' height='700px' header='충전소 최적위치 시뮬레이션 요청'
                isModal={true} 
                visible={visibleDialog}
                showCloseIcon={true} 
                close={props.closeDialogCallback}
                buttons={dialogButtons}>
                <div style={{display:'flex', flexDirection:'column', height:'100%', padding:'10px'}}>
                    <div style={{marginBottom:'10px'}}>
                        <DropDownListComponent placeholder='시/도를 선택해 주세요.' popupHeight='220px'
                            change={onChangeZcode}
                            value={zcodeValue}
                            fields={dataFields}
                            dataSource={zcodeData}/>
                    </div>
                    <div style={{marginBottom:'10px'}}>
                        <DropDownListComponent placeholder='군/구를 선택해 주세요.' popupHeight='220px'
                            change={onChangeSigCode}
                            value={sigCodeValue}
                            fields={dataFields}
                            dataSource={filteredSigData}/>
                    </div>
                    <div style={{
                        marginTop: '25px',
                        marginBottom: '15px',
                        fontSize: '15px',
                        fontWeight: 'bold'
                        }}>
                        영향요인 가중치 설정 (선택사항)
                    </div>
                    <div style={{  
                        marginBottom: '15px',
                        fontSize: '13px'
                        }}>
                        충전에 영향을 주는 각 요인별 가중치를 설정해 주세요. 가중치 설정을 통해 AI에게 무엇을 더 중요하게 생각하는지 알려줄 수 있습니다.
                        특정 요인을 반영하고 싶지 않은 경우라면, 해당 요인을 선택에서 제외하면 됩니다. 가중치는 1.0부터 10.0까지 입력할 수 있습니다.
                        기본 가중치는 1.0이며 가중치를 부여하려면 더 높은값을 입력해야 합니다. 
                    </div> 
                    {/* 환경요인1 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='번화가'
                            cssClass='custom-checkbox'
                            style={{width:'30%', marginRight: '50px'}} 
                            change={onChangeFactor1Check}
                            checked={factor1Enable} />
                        <ComboBoxComponent id='factor1' popupHeight='200px'
                            value={factor1Value} 
                            change={onChangeFactor1Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='번화가는 카페,공간대여,제과점,패스트푸드,음식점,스터디카페,약국,극장,식장,레져시설,의료기상사,심리상담소등이 많은 지역을 의미합니다. '
                            target='#factor1_info'>
                            <FaInfoCircle id='factor1_info' size={20}/>
                        </TooltipComponent>
                    </div>
                    {
                        factor1ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor1ValueErrMsg}</div>)
                    }
                    {/* 환경요인2 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='생활편의시설'
                            cssClass='custom-checkbox'
                            change={onChangeFactor2Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor2Enable} />
                        <ComboBoxComponent id='factor2' 
                            popupHeight='200px'
                            value={factor2Value} 
                            change={onChangeFactor2Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='생활편의시설은 편의점,뷰티,부동산,쇼핑등과 관계된 시설을 의미합니다. '
                            target='#factor2_info'>
                            <FaInfoCircle id='factor2_info' size={20}/>
                        </TooltipComponent>    
                    </div>
                    {
                        factor2ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor2ValueErrMsg}</div>)
                    }
                    {/* 환경요인3 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='상업 및 공공시설'
                            change={onChangeFactor3Check}
                            cssClass='custom-checkbox'
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor3Enable} />
                        <ComboBoxComponent id='factor3' 
                            popupHeight='200px'
                            value={factor3Value} 
                            change={onChangeFactor3Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='상업 및 공공시설은 호텔,숙박,금융,주점,문화센터,대형병원,공공기관등을 의미합니다. '
                            target='#factor3_info'>
                            <FaInfoCircle id='factor3_info' size={20}/>
                        </TooltipComponent>    
                    </div>
                    {
                        factor3ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor3ValueErrMsg}</div>)
                    }
                    {/* 환경요인4 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='거주 및 생활단지'
                            cssClass='custom-checkbox'
                            change={onChangeFactor4Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor4Enable} />
                        <ComboBoxComponent id='factor4' 
                            popupHeight='200px'
                            value={factor4Value} 
                            change={onChangeFactor4Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='거주 및 생홀단지는 아파트, 초중고, 교육시설, 정류장, 학원, 주차장, 공원등을 의미합니다. '
                            target='#factor4_info'>
                            <FaInfoCircle id='factor4_info' size={20}/>
                        </TooltipComponent>      
                    </div>
                    {
                        factor4ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor4ValueErrMsg}</div>)
                    }
                    {/* 환경요인5 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='연구단지 및 기업'
                            cssClass='custom-checkbox'
                            change={onChangeFactor5Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor5Enable} />
                        <ComboBoxComponent id='factor5' 
                            popupHeight='200px'
                            value={factor5Value} 
                            change={onChangeFactor5Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='연구단지 및 기업은 연구시설 및 기업을 의미합니다.'
                            target='#factor5_info'>
                            <FaInfoCircle id='factor5_info' size={20}/>
                        </TooltipComponent>      
                    </div>
                    {
                        factor5ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor5ValueErrMsg}</div>)
                    }
                    {/* 환경요인6 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='주유소 및 휴게소'
                            cssClass='custom-checkbox'
                            change={onChangeFactor6Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor6Enable} />
                        <ComboBoxComponent id='factor6' 
                            popupHeight='200px'
                            value={factor6Value} 
                            change={onChangeFactor6Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='주유소 및 휴게소는 주유소와 휴게소를 의미합니다.'
                            target='#factor6_info'>
                            <FaInfoCircle id='factor6_info' size={20}/>
                        </TooltipComponent>        
                    </div>
                    {
                        factor6ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor6ValueErrMsg}</div>)
                    }
                    {/* 환경요인7 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='교외 종교 및 복지시설'
                            cssClass='custom-checkbox'
                            change={onChangeFactor7Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor7Enable} />
                        <ComboBoxComponent id='factor7' 
                            popupHeight='200px'
                            value={factor7Value} 
                            change={onChangeFactor7Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='교외 종교 및 복지시설은 주거시설, 도로시설, 교회, 절, 복지시설 및 기타 종교시설을 의미합니다.'
                            target='#factor7_info'>
                            <FaInfoCircle id='factor7_info' size={20}/>
                        </TooltipComponent>      
                    </div>
                    {
                        factor7ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor7ValueErrMsg}</div>)
                    }
                    {/* 환경요인8 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='관광지 및 문화시설'
                            cssClass='custom-checkbox'
                            change={onChangeFactor8Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor8Enable} />
                        <ComboBoxComponent id='factor8' 
                            popupHeight='200px'
                            value={factor8Value} 
                            change={onChangeFactor8Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='관광지 및 문화시설은 여행명소와 문화시설을 의미합니다.'
                            target='#factor8_info'>
                            <FaInfoCircle id='factor8_info' size={20}/>
                        </TooltipComponent>      
                    </div>
                    {
                        factor8ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor8ValueErrMsg}</div>)
                    }
                    {/* 환경요인9 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='야외휴양시설'
                            cssClass='custom-checkbox'
                            change={onChangeFactor9Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor9Enable} />
                        <ComboBoxComponent id='factor9' 
                            popupHeight='200px'
                            value={factor9Value} 
                            change={onChangeFactor9Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='야외휴양시설은 캠프장, 야외놀이시설, 콘도등을 의미합니다.'
                            target='#factor9_info'>
                            <FaInfoCircle id='factor9_info' size={20}/>
                        </TooltipComponent>      
                    </div>
                    {
                        factor9ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor9ValueErrMsg}</div>)
                    }
                    {/* 환경요인10 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='대학교'
                            cssClass='custom-checkbox'
                            change={onChangeFactor10Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor10Enable} />
                        <ComboBoxComponent id='factor10' 
                            popupHeight='200px'
                            value={factor10Value} 
                            change={onChangeFactor10Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='대학교는 대학교와 관련된 모든 시설을 의미합니다.'
                            target='#factor10_info'>
                            <FaInfoCircle id='factor10_info' size={20}/>
                        </TooltipComponent>          
                    </div>
                    {
                        factor10ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor10ValueErrMsg}</div>)
                    }
                    {/* 환경요인11 입력 폼 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        marginBottom: '5px',
                    }}>
                        <CheckBoxComponent label='골프장'
                            cssClass='custom-checkbox'
                            change={onChangeFactor11Check}
                            style={{width:'30%', marginRight: '50px'}} 
                            checked={factor11Enable} />
                        <ComboBoxComponent id='factor11' 
                            popupHeight='200px'
                            value={factor11Value}
                            change={onChangeFactor11Value} 
                            style={{marginLeft: '10px'}}
                            dataSource={weightData}/>
                        <TooltipComponent position='TopCenter'
                            content='골프장은 골프장, 골프연승장을 의미합니다.'
                            target='#factor11_info'>
                            <FaInfoCircle id='factor11_info' size={20}/>
                        </TooltipComponent>          
                    </div>
                    {
                        factor11ValueErrMsg && (<div style={{ color: 'red', fontSize: '10px', marginBottom: '12px' }}>{factor11ValueErrMsg}</div>)
                    }
                    <div style={{marginLeft:'10px', marginRight:'10px', marginTop: 'auto'}}>
                    </div> 
                </div>    
            </DialogComponent>
        </div>
    );
}

export default ZoomSimRequestModal;