import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';

import { setCookie, getCookie, timeInput, dateFormat } from '../../util/util';
import { TfiClose } from "react-icons/tfi";
import Selector2 from '../buttons/Selector2';
import { timestampFormat3 } from '../../util/util';

export const CpoLogSearchModal = (props) => {

  // 공통
  const [DisplaySerialNumber, setDisplaySerialNumber] = useState(props.DisplaySerialNumber);
  // idTag
  const [TagId, setTagId] = useState(props.TagId);
  // 시작 날짜
  const [startDate, setStartDate] = useState(props.MinCreatedDate?timestampFormat3(props.MinCreatedDate).split(' ')[0]:null);
  // 종료 날짜
  const [stopDate, setStopDate] = useState(props.MaxCreatedDate?timestampFormat3(props.MaxCreatedDate).split(' ')[0] :null);
  // 시작 시간(시&분)
  const [timeFrom, setTimeFrom] = useState(props.MinCreatedDate ? ( timestampFormat3(props.MinCreatedDate).split(' ')[1] ):null);
  // 종료 시간(시&분)
  const [timeTo, setTimeTo] = useState(props.MaxCreatedDate ? (timestampFormat3(props.MaxCreatedDate).split(' ')[1] ) :null);  
  // 커넥터 개수
  const [connectorAmount, setConnectorAmount] = useState(props.connectorAmount);

  useEffect(()=>{
    if(startDate && stopDate){
      if(startDate > stopDate){
        let temp = startDate;
        setStartDate(stopDate);
        setStopDate(temp);
      }
    }
  },[startDate, stopDate])
  

  // tab 0
  const resultList = ["Accepted","Blocked","Expired","Invalid","ConcurrentTx"];
  // 클릭된 뱃지 리스트
  const [clickedResultList, setClickedResultList] = useState(props.clickedResultList?props.clickedResultList:[]);
  // 클릭 이벤트 처리 함수
  const clickResult = (idx)=>{
    let temp = [...clickedResultList];
    if(temp.indexOf(idx) !== -1){ 
      temp.splice(temp.indexOf(idx),1)
      setClickedResultList(temp);
    }
    else{
      temp.push(idx)
      setClickedResultList(temp);
    } 
  }

  // tab 1
  // 액션 뱃지 리스트
  const actionList = ["Authorize", "BootNotification", "ChangeAvailability", "ChangeConfiguration", "ClearCache", "DataTransfer", "GetConfiguration", "Heartbeat", "MeterValues", "RemoteStartTransaction", "RemoteStopTransaction", "Reset", "StartTransaction", "StatusNotification", "StopTransaction", "UnlockConnector", "GetDiagnostics", "DiagnosticsStatusNotification", "FirmwareStatusNotification", "UpdateFirmware", "GetLocalListVersion", "SendLocalList", "CancelReservation", "ReserveNow", "ClearChargingProfile", "GetCompositeSchedule", "SetChargingProfile", "TriggerMessage", "ClearLocalList", "CloseSession"]
  // 클릭된 뱃지 리스트
  const [clickedActionList, setClickedActionList] = useState(props.clickedActionList?props.clickedActionList:[]);
  // 클릭 이벤트 처리 함수
  const clickAction = (idx)=>{
    let temp = [...clickedActionList];
    if(temp.indexOf(idx) !== -1){ 
      temp.splice(temp.indexOf(idx),1)
      setClickedActionList(temp);
    }
    else{
      temp.push(idx)
      setClickedActionList(temp);
    } 
  }
  const messageTypeValList = ["CALL", "CALLRESULT", "CALLERROR"];
  const messageTypeList = ["REQUEST", "CONFIRM", "ERROR"];
  // 클릭된 뱃지 리스트
  const [clickedMessageTypeList, setClickedMessageTypeList] = useState(props.clickedMessageTypeList?props.clickedMessageTypeList:[]);
  // 클릭 이벤트 처리 함수
  const clickMessageType = (idx)=>{
    let temp = [...clickedMessageTypeList];
    if(temp.indexOf(idx) !== -1){ 
      temp.splice(temp.indexOf(idx),1)
      setClickedMessageTypeList(temp);
    }
    else{
      temp.push(idx)
      setClickedMessageTypeList(temp);
    } 
  }
  // tab 2
  const chargerStatusList = ["Available", "Preparing", "Charging", "SuspendedEVSE", "SuspendedEV", "Finishing", "Reserved", "Unavailable", "Faulted"];
  const [clickedChargerStatusList, setClickedChargerStatusList] = useState(props.clickedChargerStatusList?props.clickedChargerStatusList:[]);
  // 클릭 이벤트 처리 함수
  const clickChargerStatusList = (idx)=>{
    let temp = [...clickedChargerStatusList];
    if(temp.indexOf(idx) !== -1){ 
      temp.splice(temp.indexOf(idx),1)
      setClickedChargerStatusList(temp);
    }
    else{
      temp.push(idx)
      setClickedChargerStatusList(temp);
    } 
  }
  // tab 3
  const[VendorId,setVendorId] = useState(props.VendorId);
  const[VendorErrorCode,setVendorErrorCode] = useState(props.VendorErrorCode);
  const errorCodeList = ["ConnectorLockFailure","EVCommunicationError","GroundFailure","HighTemperature","InternalError","LocalListConflict","NoError","OtherError","OverCurrentFailure","OverVoltage","PowerMeterFailure","PowerSwitchFailure","ReaderFailure","ResetFailure","UnderVoltage","WeakSignal"];
  const [clickedErrorCodeList, setClickedErrorCodeList] = useState(props.clickedErrorCodeList?props.clickedErrorCodeList:[]);
  // 클릭 이벤트 처리 함수
  const clickErrorCode = (idx)=>{
    let temp = [...clickedErrorCodeList];
    if(temp.indexOf(idx) !== -1){ 
      temp.splice(temp.indexOf(idx),1)
      setClickedErrorCodeList(temp);
    }
    else{
      temp.push(idx)
      setClickedErrorCodeList(temp);
    } 
  }

  const mappingFunc = (tag,target,val)=>{
    let temp =[];
    val.map((it)=>{
      temp.push(target[it]);
    })
    return temp.toString().replaceAll(',',tag);
  }

  const submit = ()=>{
    if(props.tabIdx == 0){
      props.setDisplaySerialNumber(DisplaySerialNumber);
      props.setTagId(TagId);
      props.setMinCreatedDate(startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null)
      props.setMaxCreatedDate(stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null)
      props.setClickedResultList(clickedResultList);
      props.makeQueryTagAuthorization(DisplaySerialNumber, TagId, startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null, stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null, clickedResultList, mappingFunc('&AuthorizationResults=',resultList,clickedResultList));
    }
    else if(props.tabIdx == 1){
      props.setDisplaySerialNumber(DisplaySerialNumber);
      props.setTagId(TagId);
      props.setMinCreatedDate(startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null);
      props.setMaxCreatedDate(stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null);
      props.setClickedActionList(clickedActionList);
      props.setClickedMessageTypeList(clickedMessageTypeList);
      props.makeQueryOccpMessage(DisplaySerialNumber,TagId,startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null,stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null,clickedActionList,clickedMessageTypeList, mappingFunc('&Actions=',actionList,clickedActionList), mappingFunc('&MessageTypes=',messageTypeValList,clickedMessageTypeList));
    }
    else if(props.tabIdx == 2){
      props.setDisplaySerialNumber(DisplaySerialNumber);
      props.setTagId(TagId);
      props.setMinCreatedDate(startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null);
      props.setMaxCreatedDate(stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null);
      props.setClickedChargerStatusList(clickedChargerStatusList);
      props.makeQueryChargerStatusHistories(DisplaySerialNumber,TagId,startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null,stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null,clickedChargerStatusList, mappingFunc('&Statuses=',chargerStatusList ,clickedChargerStatusList))
    }
    else if(props.tabIdx == 3){
      props.setDisplaySerialNumber(DisplaySerialNumber);
      props.setTagId(TagId);
      props.setMinCreatedDate(startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null);
      props.setMaxCreatedDate(stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null);
      props.setClickedErrorCodeList(clickedErrorCodeList);
      props.setVendorErrorCode(VendorErrorCode)
      props.setVendorId(VendorId);
      props.makeQueryChargerErrorHistories(DisplaySerialNumber,TagId,startDate ? (new Date(startDate+ " " + `${timeFrom? timeFrom.replaceAll(" ",""):""}`))?.toISOString():null,stopDate ? (new Date(stopDate+ " " + `${timeTo? timeTo.replaceAll(" ",""):""}`)).toISOString():null,VendorErrorCode,VendorId,clickedErrorCodeList, mappingFunc('&ErrorCodes=',errorCodeList, clickedErrorCodeList))
    }
    props.setOnOff(false);
  }
  
  const resetState = ()=>{
    setDisplaySerialNumber("");
    setTagId("");
    setStartDate("");
    setStopDate("");
    setTimeFrom("");
    setTimeTo("");
    setConnectorAmount("");
    setClickedResultList([]);
    setClickedActionList([]);
    setClickedMessageTypeList([]);
    setClickedChargerStatusList([]);
    setClickedErrorCodeList([]);
    setVendorId("");
    setVendorErrorCode("");
  }

  return (
    <div className="cpo-log-report-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-log-report-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-log-report-modal-header">
          <title>검색필터</title>
          <p>검색 필터를 설정해주세요</p>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>


        <div className="cpo-log-report-modal-content ">
          {
            props.tabIdx == 0 ?
            <div className='cpo-log-report-modal-content-box log-tab0'>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>충전기식별번호</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='충전기식별번호를 입력하세요' value={DisplaySerialNumber} onChange={(e)=>{setDisplaySerialNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>회원 idTag</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='회원 idTag를 입력하세요' value={TagId} onChange={(e)=>{setTagId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
                <p>검색 기간</p>
                <p>최소와 최대 범위를 설정해주세요</p>                
              </div>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date" onClick={(e)=>{e.stopPropagation(); }} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeFrom} onChange={(e)=>{setTimeFrom(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
                <div>~</div>
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date"  onClick={(e)=>{e.stopPropagation(); }} value={stopDate} onChange={(e)=>{setStopDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeTo} onChange={(e)=>{setTimeTo(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
              </div>

              <div className="cpo-log-report-modal-content-wrapper marginTop">
                <p>인증 결과</p>
                <div className='cpo-log-report-modal-content-data-setting-badge-container'>
                  {
                  resultList.map((it, idx)=>{
                    return<div className={"cpo-log-report-modal-content-data-setting-badge result " + `${it}` + `${clickedResultList.indexOf(idx) !== -1 ? " selected" :"" }`} onClick={()=>{clickResult(idx)}}>{it}</div>
                  })
                  }
                </div>
              </div>
            </div>
            : props.tabIdx == 1 ?
            <div className='cpo-log-report-modal-content-box log-tab1'>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>충전기식별번호</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='충전기식별번호를 입력하세요' value={DisplaySerialNumber} onChange={(e)=>{setDisplaySerialNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>트랜잭션 ID</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='트랜잭션 ID를 입력하세요' value={TagId} onChange={(e)=>{setTagId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
                <p>검색 기간</p>
                <p>최소와 최대 범위를 설정해주세요</p>                
              </div>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date" onClick={(e)=>{e.stopPropagation(); }} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeFrom} onChange={(e)=>{setTimeFrom(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
                <div>~</div>
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date"  onClick={(e)=>{e.stopPropagation(); }} value={stopDate} onChange={(e)=>{setStopDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeTo} onChange={(e)=>{setTimeTo(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop">
                <p>액션명</p>
                <div className='cpo-log-report-modal-content-data-setting-badge-container'>
                  {
                  actionList.map((it, idx)=>{
                    return<div className={"cpo-log-report-modal-content-data-setting-badge " + `${clickedActionList.indexOf(idx) !== -1 ? "selected" :"" }`} onClick={()=>{clickAction(idx)}}>{it}</div>
                  })
                  }
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop">
                <p>메세지 타입</p>
                <div className='cpo-log-report-modal-content-data-setting-badge-container'>
                  {
                  messageTypeList.map((it, idx)=>{
                    return<div className={"cpo-log-report-modal-content-data-setting-badge messageType " + `${clickedMessageTypeList.indexOf(idx) !== -1 ? "selected" :"" }`} onClick={()=>{clickMessageType(idx)}}>{it}</div>
                  })
                  }
                </div>
              </div>
              
            </div>
            : props.tabIdx == 2 ?
            <div className='cpo-log-report-modal-content-box log-tab2'>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>충전기식별번호</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='충전기식별번호를 입력하세요' value={DisplaySerialNumber} onChange={(e)=>{setDisplaySerialNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>커넥터 번호</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='커넥터 번호를 입력하세요' value={TagId} onChange={(e)=>{setTagId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
                <p>검색 기간</p>
                <p>최소와 최대 범위를 설정해주세요</p>                
              </div>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date" onClick={(e)=>{e.stopPropagation(); }} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeFrom} onChange={(e)=>{setTimeFrom(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
                <div>~</div>
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date"  onClick={(e)=>{e.stopPropagation(); }} value={stopDate} onChange={(e)=>{setStopDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeTo} onChange={(e)=>{setTimeTo(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop">
                <p>충전기 상태</p>
                <div className='cpo-log-report-modal-content-data-setting-badge-container'>
                  {
                  chargerStatusList.map((it, idx)=>{
                    return<div className={"cpo-log-report-modal-content-data-setting-badge " + `${clickedChargerStatusList.indexOf(idx) !== -1 ? "selected" :"" }`} onClick={()=>{clickChargerStatusList(idx)}}>{it}</div>
                  })
                  }
                </div>
              </div>
            </div>
            : props.tabIdx == 3 ?
            <div className='cpo-log-report-modal-content-box log-tab3'>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>충전기식별번호</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='충전기식별번호를 입력하세요' value={DisplaySerialNumber} onChange={(e)=>{setDisplaySerialNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>커넥터 번호</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='커넥터 번호를 입력하세요' value={TagId} onChange={(e)=>{setTagId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
                <p>검색 기간</p>
                <p>최소와 최대 범위를 설정해주세요</p>                
              </div>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date" onClick={(e)=>{e.stopPropagation(); }} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeFrom} onChange={(e)=>{setTimeFrom(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
                <div>~</div>
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date"  onClick={(e)=>{e.stopPropagation(); }} value={stopDate} onChange={(e)=>{setStopDate(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={timeTo} onChange={(e)=>{setTimeTo(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper marginTop">
                <p>에러코드</p>
                <div className='cpo-log-report-modal-content-data-setting-badge-container'>
                  {
                  errorCodeList.map((it, idx)=>{
                    return<div className={"cpo-log-report-modal-content-data-setting-badge " + `${clickedErrorCodeList.indexOf(idx) !== -1 ? "selected" :"" }`} onClick={()=>{clickErrorCode(idx)}}>{it}</div>
                  })
                  }
                </div>
              </div>
              <div className="cpo-log-report-modal-content-wrapper divide">
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>벤더 에러코드</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='벤더 에러코드를 입력하세요' value={VendorErrorCode} onChange={(e)=>{setVendorErrorCode(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <p>벤더 아이디</p>
                  </div>
                  <div className="cpo-log-report-modal-content-wrapper">
                    <div className="cpo-log-report-modal-content-input-container ">
                      <input type="text" placeholder='벤더 아이디를 입력하세요' value={VendorId} onChange={(e)=>{setVendorId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : <></>
          }
        </div>



        <div className='cpo-log-report-modal-button-container'>
          <div className="cpo-log-report-modal-button reset" onClick={()=>{resetState();}}>초기화</div>
          <div className="cpo-log-report-modal-button" onClick={()=>{submit();}}>완료</div>
        </div>
        
      </div>
    </div>
  )
}
export default CpoLogSearchModal



