import React, { useRef, useState, useEffect } from 'react';
import { MdSearch } from "react-icons/md";
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { getCookie } from '../../util/util.js';
import { exceptDeletedStation } from '../../util/util.js';
import { getStationList, getChargerByStationId, getChargerListByStationId } from '../../api/infra/infraApi.js';

import { getCardList } from '../../api/payment/paymentApi.js';


import Carousel from '../../components/carousel/Carousel';
import ChargerCard from '../../components/list/ChargerCard.js';
import ChasSettingModal from '../../components/modals/ChasSettingModal.js';
import CpoChargerCardEventModal from '../../components/modals/CpoChargerCardEventModal.js';
import PaymentMethodAlert from '../../components/alerts/PaymentMethodAlert.js';
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";
// 충전기 운용제어
const CpoChargerControl = (props) => {
  // redux
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:8, idx:0}));
  },[dispatch])

  // 선택된 충전소 idx
  const [selectedStation, setSelectedStation] = useState(null);
  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  // 결제 카드 리스트 GET
  const reqGetCardList = async()=>{
    let result = await getCardList();
    if(result.status === 200){
      let carList = [...result.data]
      if(carList.length === 0){
        // TODO
        setPaymentMethodAlertOn(true);
      }
    }
    else{
      // TODO
    }
  }

  
  useEffect(()=>{
    reqGetCardList();
  },[])


  useEffect(()=>{
    reqGetStationList();
  },[])
  
  // cpo가 소유한 station GET
  const reqGetStationList = async(id)=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
    else{

    }
  }

  // station에 종속된 charger
  // const reqChargerList = async()=>{
  //   let result =[];
  //   for await(const station of stationInfo){
  //     let tempChargerList = await getChargerByStationId(station.ChargingStationId);
  //     result.push(tempChargerList.data);
  //   }
  //   setChargerInfo(result);
  // }
  
  const reqChargerList = async(pageNum)=>{
    let result = await getChargerListByStationId(stationInfo[selectedStation]?.ChargingStationId, pageNum?pageNum:1);
    if(result.status === 200){
      setChargerInfo(result.data);
    }
    else{
      // TODO
      setChargerInfo([]);
    }
  }



  // chargerHub 정보 API GET
  // const getChargerHubList = async(id)=>{
  //   let temp = [];
  //   await axios.get(`/controlhub/api/cpouser/v1/Chargers/${id}`,{
  //     headers: {
  //       Authorization: `Bearer ${signedToken}`
  //     }
  //   })
  //   .then((res)=>{
  //     temp.push(res.data);
  //   })
  //   return temp;
  // }
  const getChargerHubList = async(id)=>{
    let temp = [];
    for await(const it of chargerInfo?.Results){
      await axios.get(`/controlhub/api/cpouser/v1/Chargers/${it.ChargerId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
    }
    setChargerHubInfo(temp);
  }
  
  // chargerHub 정보 API GET
  const reqChargerHubList = async()=>{
    let temp = [];
    for await(const it of chargerInfo?.Results){
      await axios.get(`/controlhub/api/cpouser/v1/Chargers/${it.ChargerId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
    }
    setChargerHubInfo(temp);
  }

  
  
  // search input ref
  const searchInputRef = useRef(null);
  // 검색 function
  const submitSearch= async(e)=>{
    e.preventDefault();
    console.log(searchInputRef.current.value);
  }
  
  const [modalOn, setModalOn] = useState(false);
  const search = (val)=>{
    setSelectedStation(stationInfo.indexOf(stationInfo.filter(it=>it.ChargingStationName == val)[0]));
  }
  const searchClick = ()=>{

    const val = document.getElementById('searchInput').value;
    const opts = document.getElementById('searchOptions').children;
    for (var i = 0; i < opts.length; i++) {
      if (opts[i].value === val) {
        search(opts[i].value);
        break;
      }
    }
  }

  const [chargerEventModalOn, setChargerEventModalOn] = useState(false);
  const [chargerEventModalIdx, setChargerEventModalIdx] = useState(null);
  const [chargerEventModalData, setChargerEventModalData] = useState(null);

  const [paymentMethodAlertOn, setPaymentMethodAlertOn] = useState(false);


  const [pageNum, setPageNum] = useState(1);

  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
    const [pageSize, setPageSize] = useState(10);
    // 총 페이지 수
    const pageCount = Math.ceil(chargerInfo?.Total/pageSize);
    // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
    const getInitPage = (page)=>{
      if(page<1)return 1;
      if(pageCount === 0)return 1;
      if(page>pageCount)return pageCount;
      return (Math.ceil(page/5)-1)*5+1;
    }
    // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
    const getEndPage = (page)=>{
      if(page<1)return 1;
      if(pageCount === 0)return 1;
      if(page>pageCount)return pageCount;
      return (Math.ceil(page/5)-1)*5+4;
    }
    // 페이지 이동 함수
    const movePage = (page)=>{
      setPageNum(page);
    }
    // 페이지 버튼 렌더링 함수
    const pageSelectorRendering = ()=>{
      let page = pageNum?pageNum:1;
      let initPage = getInitPage(pageNum?pageNum:1);
      let endPage = initPage + 5;
  
      const result = [];
      // 화면에 최대 5개의 페이지 버튼 생성
      for (let id = initPage; id < endPage && id <= pageCount; id++) {
        result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
      }
      if(result.length === 0){
        result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
      }
      return result;
    }
  
  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  // TODO : selectedStation 바뀌면 pageNum 초기화
  useEffect(() => {
    if((!stationInfo || (selectedStation!==0 && !selectedStation)))return;
    if(pageNum!==1)setPageNum(1);
    else reqChargerList(1);
  }, [selectedStation]);
  
  useEffect(() => {
    if((!stationInfo || (selectedStation!==0 && !selectedStation)))return;
    reqChargerList(pageNum);
  }, [pageNum]);
  
  
  useEffect(()=>{
    if(!chargerInfo)return;
    reqChargerHubList();
  },[chargerInfo])

  return (
    <div className='cpo-charger-control-container'>
      {chargerEventModalOn&&<CpoChargerCardEventModal getChargerList={reqChargerList} data={chargerEventModalData}  idx={chargerEventModalIdx} setOnOff={setChargerEventModalOn}/>}
      {paymentMethodAlertOn&&<PaymentMethodAlert setOnOff={setChargerEventModalOn}/>}
      <div className="cpo-charger-control-wrapper">
        {modalOn && chargerHubInfo && stationInfo && chargerInfo ? <ChasSettingModal getChargerHubList={reqChargerHubList} setOnOff={setModalOn} selectedStation={selectedStation} setSelectedStation={setSelectedStation} stationInfo={stationInfo} chargerInfo={chargerInfo} chargerHubInfo={chargerHubInfo}/>:<></>}
        <div className="cpo-charger-control-header">
          <p>
            나의 충전소
          </p>
          <div className="nav-input-container">
            <input id='searchInput' list='searchOptions' autocomplete='on' ref={searchInputRef} type="text" onInput={()=>{searchClick()}} placeholder='충전소를 검색하세요' onKeyDown={(e)=>{if(e.key == "Enter")search(e.target.value)}}/>
            <MdSearch className='search-icon'/>
          </div>
          <datalist id='searchOptions'>
            {stationInfo?.map((it,idx)=>{
              return(<option value={it.ChargingStationName}/>)
            })}
          </datalist>
        </div>
        {
          stationInfo ?
          <Carousel items={stationInfo} selected={selectedStation} setSelected={setSelectedStation}/>
          :<></>
        }
        <div className="cpo-charger-control-header second">
          <p onClick={()=>{console.log(selectedStation, stationInfo, chargerInfo, chargerHubInfo)}}>
            충전기
          </p>
          <div className={'cpo-charger-chas-setting-open-button '+`${ (chargerInfo && chargerInfo?.Results?.length == 0) || selectedStation==undefined ?"none":""}`} onClick={()=>{setModalOn(true)}}><p>CHAS 설정</p></div>
        </div>
        <div className="cpo-charger-control-device-container">
          {
            selectedStation!==0&&!selectedStation?
              <div className='plz-select-msg'><p>충전소를 선택해주세요.</p></div>
            : chargerInfo&&chargerHubInfo&&chargerInfo?.Results?.map((it, idx)=>{
              if(it.IsDeleted !== true){
                return(
                  <ChargerCard setChargerEventModalData={setChargerEventModalData} setChargerEventModalOn={setChargerEventModalOn} setChargerEventModalIdx={setChargerEventModalIdx} getChargerHubList={reqChargerHubList} getChargerList={reqChargerList} data={it} key={idx} controlhubData={chargerHubInfo[idx]}/>
                );
              }
              else{
                return(<></>);
              }
            })
          }
        </div>
        {
          (selectedStation||selectedStation==0)&&
          <div className='page-selector-container'>
            <CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>
            <CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>
            {pageSelectorRendering()}
            <CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>
            <CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>
          </div>
        }

      </div>
    </div>
  );
};

export default CpoChargerControl;
