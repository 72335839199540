import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'

import ChargerItem from "../../components/list/ChargerItem.js";
import ChargerDetailModal from "../../components/modals/ChargerDetailModal.js";
import ChargerEnrollModal from '../../components/modals/ChargerEnrollModal.js';
import ChargerDeleteModal from '../../components/modals/ChargerDeleteModal.js';
import SearchSelectorNotCreatable from '../../components/buttons/SearchSelectorNotCreatable.js'

import { getCookie } from '../../util/util.js';

import { getStationList } from '../../api/infra/infraApi.js';
import { exceptDeletedStation } from '../../util/util.js';

import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

const CpoChargerManagement = () => {
  // redux
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");


  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:3}));
  },[dispatch])


  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);
  // 충전기 selector label 리스트
  const [labelList, setLabelList] = useState();
  // chargerHub 데이터 로딩 상태
  const [chargerHubLoading, setChargerHubLoading] = useState(false);

  useEffect(()=>{
    reqGetStationList();
  },[])

  // cpo가 소유한 station GET
  const reqGetStationList = async(id)=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
    setCheckChargerList([]);
  }

// chargerHub 정보 List API GET with ChargerId
const getChargerHubList = async()=>{
  let temp = [];
  for await(const it of chargerInfo?.Results){
    await axios.get(`/controlhub/api/cpouser/v1/Chargers/${it.ChargerId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
  }
  setChargerHubLoading(false);
  setChargerHubInfo(temp);
}

// charger 정보 API GET
const getChargerList = async()=>{
  let temp = [];
  for await(const it of stationInfo){
    await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
  }
  console.log(temp);
  setChargerInfo(temp);
}

// chargerHub 정보 API GET
const getChargerHubInfo = async()=>{
  await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${selectedStationId}&size=10&page=${pageNum}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    setChargerHubInfo(res.data);
  })
}

// charger Infra 정보 API GET
const getChargerInfo = async()=>{
  await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${selectedStationId}&size=10&page=${pageNum}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }  
  })
  .then((res)=>{
    setChargerInfo(res.data);
  })
}


// label 리스트 get
const getLabelList = ()=>{
  let temp = [];
  stationInfo.map((it, idx)=>{
    let val = it.ChargingStationName;
    let id = it.ChargingStationId;
    temp.push({value: val, label: val, idx:idx, id: id});
  })
  setLabelList(temp);
}
// station 데이터 받은 후 labelList 생성
useEffect(() => {
  if(!stationInfo)return;
  getLabelList();
}, [stationInfo])

const [chargerTypeStatus, setChargerTypeStatus] = useState({FAST_CHARGER:0, SLOW_CHARGER:0});
const [chargerCategoryStatus, setChargerCategoryStatus] = useState({'완전공용':0, '부분공용':0, '비공용':0});

// TODO : 새로운 API 추가 시 수정 필요 (chargerType, chargerCategory)
useEffect(()=>{
  // if(!chargerInfo)return;
  // let chargerTypeTemp = {FAST_CHARGER:0, SLOW_CHARGER:0, NONE:0}; 
  // let ChargerCategoryTemp = {'완전공용':0, '부분공용':0, '비공용':0, '미설정':0};
  // for (const chargerList of chargerInfo){
  //   for (const charger of chargerList.Results){
  //     if(charger.IsDeleted)continue;
  //     if(charger.ChargerModel?.ChargerType){
  //       chargerTypeTemp[charger?.ChargerModel.ChargerType] = chargerTypeTemp[charger.ChargerModel.ChargerType]+1;
  //     }
  //     else{
  //       chargerTypeTemp["NONE"]=chargerTypeTemp.NONE+1;
  //     }

  //     if(charger.CommonCategory){
  //       ChargerCategoryTemp[charger.CommonCategory] = ChargerCategoryTemp[charger.CommonCategory]+1;
  //     }
  //     else{
  //       ChargerCategoryTemp['미설정']=ChargerCategoryTemp['미설정']+1;
  //     }
  //   }
  // }
  // setChargerTypeStatus(chargerTypeTemp);
  // setChargerCategoryStatus(ChargerCategoryTemp);
},[chargerInfo])

  
  // station list Ref[]
  const chargerRef = useRef([]);
  

  // 클릭한 charger list item의 idx state
  const [clickedCharger, setClickedCharger] = useState(null);

  // 상세 페이지 Modal
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  // 클릭 시 상세 모달창 open
  useEffect(()=>{
    if(clickedCharger == -1 || clickedCharger == null || clickedCharger == undefined ){
      setDetailModalOpen(false);
    }
    else{
      setDetailModalOpen(true);
    }
  },[clickedCharger])



  // selector에서 클릭한 label (Station)
  const [dataLabel, setDataLabel] = useState();
  // 선택한 station id
  const [selectedStationId, setSelectedStationId] = useState(null);
  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(chargerInfo?.Total/pageSize);
  const totalCount = chargerInfo?.Total;
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(stationInfo&&pageNum){
      getChargerInfo();
      // getChargerHubInfo();
    }
  },[pageNum])

  useEffect(()=>{
    if(chargerInfo){
      setChargerHubLoading(true);
      getChargerHubList();
    }
  },[chargerInfo])

  // 클릭한 station idx
  const [selected, setSelected] = useState(0);
  

  useEffect(()=>{
    if(labelList){
      let station = localStorage.getItem('selectedStationCM');
      let stationLabel = labelList.filter(it=>it.value === station)[0]
      if(station && stationLabel){
        setDataLabel(stationLabel)
      }
      else{
        setDataLabel(labelList[0]);
      }
    }
  },[labelList])

  useEffect(()=>{
    if(dataLabel){
      setSelected(dataLabel.idx)
      setSelectedStationId(dataLabel.id);
      setPageNum(1);
    }
  },[dataLabel])

  useEffect(()=>{
    if(clickedCharger !== null) setClickedCharger(-1);
    setCheckChargerList([]);
    setChecked(false)
  },[selected])

  useEffect(()=>{
    if(!dataLabel)return;
    localStorage.setItem("selectedStationCM", dataLabel.value);
  },[dataLabel])
  
  useEffect(()=>{
    if(selectedStationId){
      // getChargerHubInfo();
      getChargerInfo();
    }
  },[selectedStationId])



  // check한 charger list
  const [checkChargerList, setCheckChargerList] = useState([]);

  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);

  
  useEffect(()=>{
    if(checked){
      let temp = new Array(chargerInfo?.Results.length).fill(0).map((_, i) => i)
      setCheckChargerList(temp);
    }
    else{
      setCheckChargerList([]);
    }
  },[checked])

  const [enrollModalOn, setEnrollModalOn] = useState(false);
  const [deleteModalOn, setDeleteModalOn] = useState(false);

  




  return (
    <div className='cpo-charger-management-container'>
      {enrollModalOn&&<ChargerEnrollModal setOnOff={setEnrollModalOn} stationInfo={stationInfo[selected]} chargerCount={chargerInfo?.length} getStationList={reqGetStationList} getChargerInfo={getChargerInfo}/>}
      {deleteModalOn&&<ChargerDeleteModal setOnOff={setDeleteModalOn} checkChargerList={checkChargerList} chargerList={chargerInfo.Results} getStationList={reqGetStationList} getChargerInfo={getChargerInfo}/>}
      <div className="cpo-charger-management-inner">
        {chargerInfo!==null&&chargerHubInfo!==null&&chargerInfo.length!==0 ? <ChargerDetailModal pageNum={pageNum}  getChargerInfo={getChargerInfo} data={chargerInfo?.Results[clickedCharger]} chasData={chargerHubInfo[clickedCharger]} detailModalOpen={detailModalOpen} setDetailModalOpen={setDetailModalOpen} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} lastIdx={chargerInfo.Total-1}/>:<></>}
        
        <div className="cpo-charger-management-category-outer">
          {/* <div className='cpo-charger-management-category-container'>
            <p>충전기 타입</p>
            <div className="cpo-charger-management-category-type-container">
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>고속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.FAST_CHARGER}</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>중속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>0</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>완속</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.SLOW_CHARGER}</p>
                </div>
              </div>
              <div className="cpo-charger-management-category-type">
                <div className="cpo-charger-management-category-type-title">
                  <p>미설정</p>
                </div>
                <div className="cpo-charger-management-category-type-content">
                  <p>{chargerTypeStatus.NONE}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='cpo-charger-management-category-container'>
            <p>충전기 분류</p>
            <div className="cpo-charger-management-category-class-container">

              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">완전공용</div>
                <p>{chargerCategoryStatus.완전공용}</p>
              </div>

              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">부분공용</div>
                <p>{chargerCategoryStatus.부분공용}</p>
              </div>
              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">비공용</div>
                <p>{chargerCategoryStatus.비공용}</p>
              </div>
              <div className="cpo-charger-management-category-class">
                <div className="cpo-charger-management-category-class-title">미설정</div>
                <p>{chargerCategoryStatus.미설정}</p>
              </div>
            </div>
          </div> */}

          <div className={detailModalOpen ? 'cpo-charger-management-charger-list-container open':'cpo-charger-management-charger-list-container' }>
            <div className='cpo-charger-management-charger-list-header'>
              <div className="cpo-charger-management-station-selector-container">
                <SearchSelectorNotCreatable placeholder="충전소를 선택하세요" options={labelList} value={dataLabel} setValue={setDataLabel} />
              </div>
              <div className={"cpo-charger-management-charger-list-button-container " + `${detailModalOpen? "open":""}`}>
                <div className={'delete '+ `${checkChargerList.length == 0 ? "none":""}`} onClick={()=>{setDeleteModalOn(true);}}><p>삭제</p></div>
                <div onClick={()=>{setEnrollModalOn(true)}}><p>등록</p></div>
              </div>
            </div>

            <div className="cpo-charger-management-charger-list">
              <div className="cpo-charger-management-charger-list-column">
                <div className='cpo-charger-management-charger-list-column-item'>
                  <label className="checkbox_label">
                    <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                    <span className="checkbox_icon"></span>
                  </label>
                </div>
                <div className='cpo-charger-management-charger-list-column-item'><p>식별번호</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>모델명</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>충전기타입</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>커넥터1 타입</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>커넥터2 타입</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>운영여부</p></div>
                <div className='cpo-charger-management-charger-list-column-item'><p>OCPP</p></div>
              </div>
              <div className='cpo-charger-management-list-content'>
                {
        //{"DisplaySerialNumber": "MOTP80985","modelName": "ModelB","ChargerType": "중속","ConnectorTypes": "차데모","status": false,"Protocol": "2.0.1"},
                chargerInfo &&
                  (chargerInfo.Results.map((ia,idx)=>{
                    if(ia.IsDeleted)return;
                    return(
                      <ChargerItem key={'charger- '+idx}  idx={idx} chargerRef={chargerRef} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} checkChargerList={checkChargerList} setCheckChargerList={setCheckChargerList} data={ia} controlHubData={chargerHubInfo?chargerHubInfo[idx]:null} controlhubLoading={chargerHubLoading}/>
                  )
                  }))
                }
              </div>
              
            </div>
            <div className='page-selector-container'>
              {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
              {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
              {pageSelectorRendering()}
              {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
              {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
              <div className='page-total-info'>{`Total : ${totalCount}`}</div>
            </div>
          </div>
        </div>




      </div>
    </div>
  )
}
export default CpoChargerManagement;