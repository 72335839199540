import React, { useState, useEffect } from 'react';

import { FaChevronDown } from "react-icons/fa6";


export const CpoActionSetProfileListItem = (props) => {
  
  const [tabOpen, setTabOpen] = useState(false);
  const [checked, setChecked] = useState(null);

  useEffect(()=>{
    if(props.deleteMode){
      setTabOpen(false);
    }
    else{
      setTabOpen(false);

      setChecked(false);
    }
  },[props.deleteMode])

  return (
    <div className={'cpo-action-set-profile-list-item-container '+`${tabOpen ? "open":""}`}>
      <div className={'cpo-action-set-profile-list-item '} onClick={()=>{setTabOpen(!tabOpen)}}>
        <div><p>{props.data?.name}</p></div>
        <div><p>{props.data?.platform}</p></div>
        <div><p>{props.data?.ocppVersion}</p></div>
        <div><p>{props.data?.description}</p></div>
        {/* <div><p className='cpo-system-chas-detail-button'>자세히보기</p></div> */}
        <div><p>{tabOpen ? "닫기 ":"펼치기 "}</p><FaChevronDown/></div>
      </div>
      <div className='cpo-action-set-profile-list-item-content'>
        <div className="cpo-action-set-profile-version-list-container">
          <div className="cpo-action-set-profile-version-list-item-column">
            <div></div>
            <div>버전</div>
            <div>설명</div>
            <div>Total : {props.data?.versions?.length}</div>
          </div>
          <div className="cpo-action-set-profile-version-list-item-container">
            {
              props.data?.versions?.map((it, idx)=>{
                return(
                  <>
                    <div className="cpo-action-set-profile-version-list-item">
                      <div>{idx+1}</div>
                      <div>{it.version}</div>
                      <div>{it.description}</div>
                      <div><p className='cpo-system-chas-detail-button' onClick={()=>{props.setSelectedProfileIdx(props.idx);props.setSelectedVersionIdx(idx); props.setModalOpen(true)}}>미리보기</p></div>
                    </div>
                    
                  </>
                )
              })
            }
          </div>          
        </div>

      </div>
    </div>
  )
}
export default CpoActionSetProfileListItem;