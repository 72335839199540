import React, { useRef, useState, useEffect } from 'react';
import { Outlet, RouterProvider,createBrowserRouter, useNavigate, useLocation } from 'react-router-dom'
import { getCookie, removeCookie, refresh, signedOut } from '../../util/util.js';

import '../../styles/Default.css';
import '../../styles/Introduce.css';

import footerBg from '../../img/footer.svg'

export const MainTemplate = () => {
  let navigator = useNavigate();
  let refreshToken = getCookie("refreshToken");
  let signedToken = getCookie("signedToken");
  const location = useLocation();

  const [modalOn, setModalOn] = useState(false);
  const [modalIdx, setModalIdx] = useState(null);
  
  useEffect(()=>{
    console.log(location.pathname)
  },[])

  return (
    <div className='main-template-container' onClick={()=>{setModalOn(false)}}>
      <div className="main-template-header">
        <div className="main-template-header-inner">
          <div className="nav-logo-container">
            <img className='nav-logo' alt="" onClick={()=>{navigator("/cpo-service")}}/>
          </div>
          <div className='nav-function-container'>
            {modalOn&&<>
              <div className='nav-menu-modal-container' onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setModalOn(true);}}>
                <div>
                  <div className={'nav-products-menu-category ' +`${location.pathname == "/cpo-service" ? "selected":""}`} onClick={(e)=>{e.stopPropagation(); navigator("/cpo-service"); setModalOn(false)}}>
                    <p>공유형 CPO 서비스</p>
                    <p>Charger Station Management Subscriptions Service</p>
                  </div>
                  
                  {/* <div className={'nav-products-menu-category ' +`${location.pathname == "occp-service" ? "":""}`}>
                    <p>찾아ZooM: 충전소 최적입지 추천 서비스</p>
                    <p>AI based Simulation Service</p>
                  </div> */}
                  <div className={'nav-products-menu-category ' +`${location.pathname == "/occp-service" ? "selected":""}`} onClick={(e)=>{e.stopPropagation(); navigator("/occp-service"); setModalOn(false)}}>
                    <p>OCPP 프록시 서비스</p>
                    <p>OCPP Proxy Service</p>
                  </div>

                </div>
              </div>
            </>}
            <div className='nav-menu-container'>
              <div className={'nav-menu-button '+`${modalIdx==0?"selected":""}`} onClick={(e)=>{e.stopPropagation(); setModalOn(!modalOn); setModalIdx(0)}}>Products</div>
              <div className={'nav-menu-button '+`${modalIdx==1?"selected":""}`} onClick={(e)=>{e.stopPropagation(); setModalOn(false); setModalIdx(1); navigator("/service-pricing")}}>Pricing</div>
              <div className={'nav-menu-button '+`${modalIdx==2?"selected":""}`} onClick={(e)=>{e.stopPropagation(); setModalOn(false); setModalIdx(2); }}>Support</div>
            </div>
            <div className='nav-sign-in-container'>
              {signedToken &&<div onClick={()=>{navigator("/cpo");}}><p>관리페이지</p></div>}
              {
                signedToken ?
                <div onClick={()=>{setModalIdx(-1);signedOut();}}><p>로그아웃</p></div>
                :<div onClick={()=>{setModalIdx(-1);navigator("/login");}}><p>로그인</p></div>
              }
              <div onClick={()=>{setModalIdx(-1);navigator("/sign-up");}}><p>회원가입</p></div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <Outlet/>
      </section>
      <footer-image>
        <img src={footerBg} alt="" />
        <p>1.0.8+10</p>
      </footer-image>
    
    </div>
  )
}
export default MainTemplate;