import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { useNavigate, Routes, Route, Link, Switch, useParams, useLocation } from "react-router-dom";

import { timestampFormat, timestampFormat2, timestampFormat3, timestampFormat4, getUTCTime} from '../../util/util';
import { PiSlidersHorizontal } from "react-icons/pi";

import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";
import { MdClose } from "react-icons/md";


import CpoIncomeReportSearchModal from '../../components/modals/CpoIncomeReportSearchModal.js';

import { getChargerInfo } from '../../api/infra/infraApi.js';
import { getChargingTransactions, getOccpMessageHistoriesByUniqueId, getOccpActionMessageHistoriesList } from '../../api/controlhub/controlhubApi.js';
import {getChargingStaticsTotal} from '../../api/statistics/statisticsApi.js';

const CpoIncomeReport = (props) => {

  let navigator = useNavigate();
  const params = useParams();
  const location = useLocation();
  const initDisplaySerialNumber = location.state?.DisplaySerialNumber;
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  
  

  useEffect(()=>{
    let val = location.pathname
    if(val.replace('/cpo/income-report','').length !== 0)dispatch(setNum({id:15, idx:0, detail:true}));
    else dispatch(setNum({id:15, idx:0}));
  },[])
  
  useEffect(()=>{
    reqChargingTransactions();
  },[])

  useEffect(()=>{
    reqChargingTransactions();
  },[params.id])

  // 총 페이지 갯수
  const [pageCount, setPageCount] = useState(null);

  // 한 번에 보일 컨텐츠 수
  let showContentCount = 15;

  
  
  // 트랜잭션
  const [resData, setResData] = useState([]);
  // 선택한 트랜잭션Id
  const [clickedTransactionId, setClickedTransactionId] = useState(null);
  // 액션 리스트
  const [resData2, setResData2] = useState([]);
  // 통신 로그
  const [actionList, setActionList] = useState();
  // 클릭한 액션 idx
  const [clickedActionIdx, setClickedActionIdx] = useState();
  // 액션 필터링리스트
  const [visibleActionList, setVisibleActionList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  // 트랜잭션 요청 함수
  let reqChargingTransactions = async()=>{
    console.log(params);
    let query = await makeQuery();
    let query1 = await makeQuery(true);
    let query2 = await makeQuery(false);
    let val = await getChargingTransactions(params.id?params.id:1, showContentCount, query);
    let success = await getChargingTransactions(params.id?params.id:1, showContentCount, query1);
    let error = await getChargingTransactions(params.id?params.id:1, showContentCount, query2);
    setPageCount(Math.ceil(val.total/showContentCount));
    setTotalCount(val.total);
    setSuccessCount(success.total);
    setFailureCount(error.total);
    setResData([...val.results]);
  }
  let makeQuery = async(completed)=>{
    let temp = ""
    if(MinCreatedDate) temp += `&MinCreatedDate=${MinCreatedDate}`
    if(MaxCreatedDate) temp += `&MaxCreatedDate=${MaxCreatedDate}`
    if(completed===true || completed===false ) temp += `&PaymentCompleted=${completed}`
    return temp;
  }

  const [chargingStaticsTotal, setChargingStaticsTotal] = useState();


  const reqGetChargingStatisticTotal = async() => {
      let data = {
        startTime: MinCreatedDate,
        endTime: MaxCreatedDate,
        chargerType: null
      }
      let res = await getChargingStaticsTotal(data);
      if(res.status === 200){
        setChargingStaticsTotal(res.data);
      }
      else{
      }
    }

  // 생성일
  const [MinCreatedDate, setMinCreatedDate] = useState(new Date(new Date().getMonth() + 1 + "/01/" + new Date().getFullYear()).toISOString());
  const [MaxCreatedDate, setMaxCreatedDate] = useState(new Date(new Date(`${new Date().getMonth() + 2}/1/${new Date().getFullYear()}`).getTime() - 1000).toISOString());

  // 결제 성공 여부
  // const [PaymentCompleted, setPaymentCompleted] = useState();

  useEffect(()=>{
    reqChargingTransactions();
    reqGetChargingStatisticTotal();
  },[MinCreatedDate,MaxCreatedDate
    // PaymentCompleted
  ])

  useEffect(()=>{
    console.log(chargingStaticsTotal)
  },[chargingStaticsTotal])


  // 현재 보일 첫 페이지 번호 반환 함수
  const getInitPage = (pageNum)=>{
    return (Math.ceil(pageNum/5)-1)*5+1;
  }
  // 현재 보일 마지막 페이지 번호 반환 함수
  const getEndPage = (pageNum)=>{
    return getInitPage(pageNum)+5;
  }
  // 페이지 이동함수
  const movePage = (page)=>{
    navigator(`/cpo/income-report/${page}`, {state: {detail:true}});
  }
  // 페이지 선택자 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = params.id?params.id:1;
    let initPage = getInitPage(params.id?params.id:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    return result;
  }
  // 검색 모달 OnOff state
  const [modalOn, setModalOn] = useState();

  const [submitTrigger, setSubmitTrigger] = useState(false);

  useEffect(()=>{
    if(submitTrigger){
      makeQuery();
      setSubmitTrigger(false);
    }
  },[submitTrigger])


  return (
    <div className='cpo-pay-report-container'>
      {modalOn&&<CpoIncomeReportSearchModal setOnOff={setModalOn} 
      MinCreatedDate={MinCreatedDate} setMinCreatedDate={setMinCreatedDate}
      MaxCreatedDate={MaxCreatedDate} setMaxCreatedDate={setMaxCreatedDate}

      actionData={resData2} visibleActionList={visibleActionList} setVisibleActionList={setVisibleActionList}/>}
      <div className="cpo-pay-report-inner">

        <div className={"cpo-pay-report-content-container "}>
          <div className="cpo-pay-report-content-search-container">
            <div className="cpo-pay-report-content-search-filter-button" onClick={()=>{setModalOn(true)}}>검색필터<PiSlidersHorizontal/></div>

            {/* {tabIdx == 0&&<div className="cpo-pay-report-content-search-input-container">
              <input type="text" placeholder='이름, 넘버, idTag를 키워드로 검색하기' value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
              <FaSearch/>
            </div>} */}
            

            {/* DisplaySerialNumber
            TagId
            MinCreatedDate
            MaxCreatedDate
            MinUnitPrice
            MaxUnitPrice
            PaymentCompleted */}
            {/* <div className='cpo-log-report-filter-item-container'>
              {MinCreatedDate || MaxCreatedDate ? <div className='cpo-log-report-filter-item'>{
                `${MinCreatedDate ? timestampFormat3(MinCreatedDate): ""} ~ ${MaxCreatedDate ? timestampFormat3(MaxCreatedDate):""}`
              }<MdClose onClick={()=>{setMinCreatedDate(null);   setMaxCreatedDate(null); setSubmitTrigger(true);}}/></div> : null}
              {(PaymentCompleted === true || PaymentCompleted === false) ? <div className='cpo-log-report-filter-item'>{PaymentCompleted === true ? "결제 성공" : "결제 실패"}<MdClose onClick={()=>{setPaymentCompleted(null); setSubmitTrigger(true);}}/></div> : null}

            </div> */}
            <div className='cpo-income-report-period-container'>
              {`${MinCreatedDate ? timestampFormat4(MinCreatedDate): ""} ~ ${MaxCreatedDate ? timestampFormat4(MaxCreatedDate):""}`}
            </div>

          </div>
          <div className='cpo-income-report-summary-container'>
            
            <div className='cpo-income-report-summary-item'>
              <div className='cpo-income-report-summary-item-title'>총 결제 금액</div>
              <div className='cpo-income-report-summary-item-content revenue'>{chargingStaticsTotal?.revenue ? chargingStaticsTotal?.revenue: 0}</div>
            </div>
            
            <div className='cpo-income-report-summary-item'>
              <div className='cpo-income-report-summary-item-title'>총 충전량</div>
              <div className='cpo-income-report-summary-item-content energyCharged'>{chargingStaticsTotal?.energyCharged ? chargingStaticsTotal?.energyCharged: 0}</div>
            </div>
            
            <div className='cpo-income-report-summary-item'>
              <div className='cpo-income-report-summary-item-title'>총 충전시간</div>
              <div className='cpo-income-report-summary-item-content chargingTime'>{chargingStaticsTotal?.chargingTime ? parseFloat(chargingStaticsTotal?.chargingTime).toFixed(2): 0}</div>
            </div>
            
            <div className='cpo-income-report-summary-item'>
              <div className='cpo-income-report-summary-item-title'>총 결제 건수</div>
              <div className='cpo-income-report-summary-item-content'>{totalCount ? totalCount : 0}</div>
            </div>
            
            <div className='cpo-income-report-summary-item'>
              <div className='cpo-income-report-summary-item-title success'>결제 성공</div>
              <div className='cpo-income-report-summary-item-content'>{successCount ? successCount : 0}</div>
            </div>
            
            <div className='cpo-income-report-summary-item'>
              <div className='cpo-income-report-summary-item-title failure'>결제 실패</div>
              <div className='cpo-income-report-summary-item-content'>{failureCount ? failureCount : 0}</div>
            </div>
          </div>
          <div className={'cpo-pay-report-item-list-grid-container'}>
              <div className="cpo-pay-report-content-item-list-container">
                  <div className="cpo-pay-report-content-item-column pay-tab0">
                    <div>충전기식별번호</div>
                    <div>트랜잭션 ID</div>
                    <div>회원 idTag</div>
                    <div>결제일자</div>
                    <div>결제성공여부</div>
                    <div>결제금액</div>
                    <div>시작 Meter</div>
                    <div>종료 Meter</div>
                    <div>단가</div>
                  </div>
                

                {
                  resData?.map((it, idx)=>{
                    return(
                      <ChargingLogListItem key={idx} data={it} idx={idx} />
                    );
                  })
                }
                
              </div>
              
          </div>
          <div className='page-selector-container'>
            {/* {(params.id>5) &&<FaChevronLeft onClick={()=>{movePage(getInitPage(params.id?params.id:1) -1)}}/>}
            {pageSelectorRendering()}
            {(getEndPage(params.id?params.id:1) < pageCount) &&<FaChevronRight onClick={()=>{movePage(getEndPage(params.id?params.id:1))}}/>} */}

            {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${ (params.id==="1" || !(params.id))?"disabled":""}`}/>}
            {<CgChevronLeft onClick={()=>{movePage(getInitPage(params.id?params.id:1) -1)}} className={`${params.id>5?"":"disabled"}`}/>}
            {pageSelectorRendering()}
            {<CgChevronRight onClick={()=>{movePage(getEndPage(params.id?params.id:1)+2)}} className={`${(getEndPage(params.id?params.id:1) < pageCount-1)?"":"disabled"}`}/>}
            {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${params.id === pageCount || pageCount<=1 ? "disabled":""}`}/>}
            <div className='page-total-info'>{`Total : ${totalCount}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoIncomeReport;


const ChargingLogListItem = (props)=>{

  const calPayment = (start, end, rate)=>{
    let val =  (parseFloat(end) - parseFloat(start))*parseFloat(rate);
    return isNaN(val)?"-":val+ "원";
  }

  return(
    <div className={'cpo-pay-report-content-item pay-tab0 '+`${props.readOnly?"readOnly":""}`} onClick={()=>{}}>
      <div><p>{props.data.charger.displaySerialNumber}</p></div>
      <div><p>{props.data.ocppTransactionId}</p></div>
      <div><p>{props.data.tag.idTag}</p></div>
      <div><p>{timestampFormat(new Date(props.data.createdDate))}</p></div>
      <div>
        <div className={'cpo-pay-report-content-item-payment ' + `${props.data.paymentCompleted ? "success":"failure"}`}><p>{props.data.paymentCompleted ? "성공":"실패"}</p></div>
      </div>
      <div><p>{calPayment(props.data.meterStart, props.data.meterStop, props.data.unitPrice)}</p></div>
      <div><p>{isNaN(props.data.meterStart)?"-":props.data.meterStart}</p></div>
      <div><p>{props.data.meterStop?props.data.meterStop:"-"}</p></div>
      <div><p>{props.data.unitPrice}</p></div>
    </div>
    )
}