/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-08
 * @file 찾아ZooM 시뮬레이션 보고서 전시 페이지 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setNum, setFeature } from '../../store.js';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { ColumnDirective, 
    ColumnsDirective, 
    GridComponent, 
    Inject, 
    Page, 
    Selection } from '@syncfusion/ej2-react-grids';
import ZoomSimReportMapLegend from '../../components/fragments/zoom/ZoomSimReportMapLegend.jsx';
import ZoomSimReportFactorWeightCard from '../../components/fragments/zoom/ZoomSimReportFactorWeightCard.jsx';
import mapboxgl from 'mapbox-gl';
import { FaChevronLeft } from "react-icons/fa"; 
import * as ZoomApi from '../../api/zoom/zoomApi.jsx';  
import 'mapbox-gl/dist/mapbox-gl.css';



const CpoStationZoomSimReport = () => {

    /********************************************************/
    /* VAR SECTION                                          */
    /********************************************************/
    let dispatch  = useDispatch();
    let userSystemId = localStorage.getItem('user-systemId');

    const params = useParams();
    const navigator = useNavigate();
    const location = useLocation();
    const requestInfo = location.state;
    const date = new Date(requestInfo.date);
    const localDateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    };
    const localDateStr = date.toLocaleString('ko-KR', localDateOptions);
    const mapBoxContainerRef = useRef();
    const mapBoxMapRef = useRef();
    const recommendedStationIcon = `${process.env.PUBLIC_URL}/img/high_recommended_station_icon.png`;
    const recommendedLocationIcon = `${process.env.PUBLIC_URL}/img/suggestion_location_pin_icon.png`;
    const stationIcon = `${process.env.PUBLIC_URL}/img/fast_electric_icon.png`;

    //let existStationDataUrl = '';

    /********************************************************/
    /* STATE SECTION                                        */
    /********************************************************/
    const [predictGeojsonUrl, setPredictGeojsonUrl] = useState('');
    const [existStationDataUrl, setExistStationDataUrl] = useState('');
    const [recommendedPositionDataUrl, setRecommendedPositionDataUrl] = useState('');
    const [newPositionDataUrl, setNewPositionDataUrl] = useState('');
    const [centerLat, setCenterLat] = useState(0.0);
    const [centerLng, setCenterLng] = useState(0.0);
    const [boundPoint1Lat, setBoundPoint1Lat] = useState(0.0);
    const [boundPoint1Lng, setBoundPoint1Lng] = useState(0.0);
    const [boundPoint2Lat, setBoundPoint2Lat] = useState(0.0);
    const [boundPoint2Lng, setBoundPoint2Lng] = useState(0.0);
    const [hasGeographicData, setHasGeographicData] = useState(false);
    /// 충전소추가설치 추천충전소목록  
    const [recommendedStations, setRecommendedStations] = useState([]);
    /// 신규충전소 설치위치 목록
    const [newLocations, setNewLocations] = useState([]);

    /********************************************************/
    /* EFFECT SECTION                                       */
    /********************************************************/
    useEffect(() => {
        dispatch(setNum({id:101, report:true}));
    },[]);    

    /// 페이지 로드되면 기본 geojson 데이터 가져오기 API 호출  
    useEffect(() => {
        if(requestInfo.status === '완료') {
            getGeographicDataAsync();
        }
    }, []);

    useEffect(() => {
        loadMapboxAsync();
        addRegionalCharacteristicLayerAsync();
        addExistStationMarkerAsync();
        //addRecommendedLocationMarkerAsync();
        //addNewLocationMarkerAsync();
    }, [hasGeographicData]);

    /********************************************************/
    /* HANDLER SECTION                                      */
    /********************************************************/
    /// 신규 추전장소를 표시하는 마커를 추가한다.
    /// TODO: 오류 있음 
    const addNewLocationMarkerAsync = async() => {
        await fetch(newPositionDataUrl)
        .then(response => response.json())
        .then(data => {
            const imageUrl = `${process.env.PUBLIC_URL}/img/suggestion_location_pin_icon.png`;

            data.features.forEach((feature) => {
                const coordinate = feature.geometry.coordinates;
                const properties = feature.properties; 
                
                const customMarker = document.createElement('div');
                customMarker.style.backgroundImage = `url(${imageUrl})`; // 이미지 URL
                customMarker.style.width = '40px'; // 너비
                customMarker.style.height = '40px'; // 높이
                customMarker.style.backgroundSize = 'cover'; // 배경 이미지 크기 조정

                const marker = new mapboxgl.Marker(customMarker)
                    .setLngLat(coordinate)
                    .addTo(mapBoxMapRef.current);
            });
            setNewLocations(data.features);
        });
    };

    /// 기존 충전소에 추가적으로 설치하면 좋을곳을 표시하는 마커 추가 
    const addRecommendedLocationMarkerAsync = async() => {
        await fetch(recommendedPositionDataUrl)
        .then(response => response.json())
        .then(data => {
            const imageUrl = `${process.env.PUBLIC_URL}/img/high_recommended_station_icon.png`;
            
            const stations = [];
            data.features.forEach((feature) => {
                const coordinate = feature.geometry.coordinates;
                const properties = feature.properties; 
                
                if(properties.totalRecommendation === 'highRecommended') {

                    const customMarker = document.createElement('div');
                    customMarker.style.backgroundImage = `url(${imageUrl})`; // 이미지 URL
                    customMarker.style.width = '40px'; // 너비
                    customMarker.style.height = '40px'; // 높이
                    customMarker.style.backgroundSize = 'cover'; // 배경 이미지 크기 조정

                    const marker = new mapboxgl.Marker(customMarker)
                    .setLngLat(coordinate)
                    .setPopup(
                        new mapboxgl.Popup({offset:50})
                            .setHTML(
                                `<h3>${properties.statId}</h3>`
                            )
                    )
                    .addTo(mapBoxMapRef.current);

                    stations.push(feature);
                }
            });
            setRecommendedStations(stations);
        });
    };

    /// 지도에 기존에 설치되어 운영되고 있는 충전소정보를 마커로 추가합니다.
    const addExistStationMarkerAsync = async() => {

        await fetch(existStationDataUrl)
        .then(response => response.json())
        .then(data => {

            const imageUrl = `${process.env.PUBLIC_URL}/img/fast_electric_icon.png`;

            data.features.forEach((feature) => {
                const coordinate = feature.geometry.coordinates;
                const properties = feature.properties;

                const customMarker = document.createElement('div');
                customMarker.style.backgroundImage = `url(${imageUrl})`; // 이미지 URL
                customMarker.style.width = '30px'; // 너비
                customMarker.style.height = '30px'; // 높이
                customMarker.style.backgroundSize = 'cover'; // 배경 이미지 크기 조정

                const marker = new mapboxgl.Marker(customMarker)
                    .setLngLat(coordinate)
                    .setPopup(
                        new mapboxgl.Popup({offest:50})
                            .setHTML(
                                `<h3>${properties.stationId}</h3>
                                ${properties.stationAddress}<br/>
                                급속:${properties.fastChargerCount}대/완속:${properties.slowChargerCount}`
                            )
                    )
                    .addTo(mapBoxMapRef.current);
            });
        });
    };

    /// 지도에 지역특성 레이어 추가 
    const addRegionalCharacteristicLayerAsync = async() => {
        await fetch(predictGeojsonUrl)
        .then(response => response.json())
        .then(data => {
            if(mapBoxMapRef.current != null && !mapBoxMapRef.current.getSource('regional_properties')) {
                //setGridData(data);
                mapBoxMapRef.current.addSource('regional_properties', {
                    type: 'geojson',
                    data: data
                });
                mapBoxMapRef.current.addLayer({
                    'id': 'regional_properties',
                    'type': 'fill',
                    'source': 'regional_properties',
                    'paint': {
                        'fill-color': [
                            'case',
                            ['==', ['get', 'clusterType'], 'CommercialResidentialBusyArea'], '#001790',
                            ['==', ['get', 'clusterType'], 'SchoolDistrictApartmentComplex'], '#FFCC57',
                            ['==', ['get', 'clusterType'], 'ResidentialArea'], '#0f5930',
                            ['==', ['get', 'clusterType'], 'UnderdevelopedArea'], '#813234',
                            ['==', ['get', 'clusterType'], 'SuburbanWelfareFacility'], '#FF3E44',
                            '#F2F2F2'
                        ],
                        'fill-opacity': 0.6,
                        'fill-outline-color': '#120907'
                    }
                });
                /// 추가된 레이어에 오른쪽 마우스 클릭 이벤트 핸들러 추가 
                mapBoxMapRef.current.on('contextmenu', 'regional_properties', (e) => {
                    const feature = e.features[0];
                    const gid = feature.properties.gid;
                    console.log('[EMOOV] selected gid: ', gid);
                    localStorage.setItem('feature', JSON.stringify(feature));
                    navigator(`/cpo/station-zoom/sim-grid-report/${params.id}/${requestInfo.zscode}/${gid}`,
                      {state: feature}
                    );
                });
            } 
        });
    };

    /// Mapbox 지도 불러오기 
    const loadMapboxAsync = async() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiZWRld2FyZCIsImEiOiJjbTU2NThseGgyaWEzMnJxMzNhdjlidDZxIn0.xkWKDdpRX3IjHqfKidAbHg';
        mapBoxMapRef.current = new mapboxgl.Map({
            container: mapBoxContainerRef.current,
            center: [centerLng, centerLat],
            pitch: 45,
            style: 'mapbox://styles/edeward/cm56qto4200nc01sv59skgqse',
            zoom: 13
        });

        const bounds = [
            [boundPoint1Lng, boundPoint1Lat],
            [boundPoint2Lng, boundPoint2Lat]
        ];
        mapBoxMapRef.current.setMaxBounds(bounds);
    };

    /// 지리데이터 정보 불러오기 
    const getGeographicDataAsync = async() => {
        let rawData = await ZoomApi.getZoomSimRequestResult(userSystemId, params.id);
        setPredictGeojsonUrl(rawData.predKWHUrl);
        setExistStationDataUrl(rawData.chargerInfoUrl);
        setRecommendedPositionDataUrl(rawData.bigChargerUrl);
        setNewPositionDataUrl(rawData.mclpUrl);

        setCenterLat(rawData.center.centerLat);
        setCenterLng(rawData.center.centerLng);

        setBoundPoint1Lng(rawData.boundingBox.swLng);
        setBoundPoint1Lat(rawData.boundingBox.swLat);
        setBoundPoint2Lng(rawData.boundingBox.neLng);
        setBoundPoint2Lat(rawData.boundingBox.neLat);

        console.log('[EMOOV]', rawData);
        setHasGeographicData(true);
    };  

    /// 추가설치가 권장되는 충전소 목록의 아이템 선택 이벤트핸들러 
    const onSelectRecommendedStation = (args) => {
        const selectedRow = args.data;
        /// 지도를 이동시킨다.
        mapBoxMapRef.current.flyTo({
            center: selectedRow.geometry.coordinates,
            zoom: 16,
            speed: 1.5,
            curve: 1.5
        });
    };

    /// 신규설치가 권장되는 목록의 아이템 선택 이벤트핸들러 
    const onSelectRecommendedLocation = (args) => {
        const selectedRow = args.data;
        /// 지도를 이동시킨다.
        mapBoxMapRef.current.flyTo({
            center: selectedRow.geometry.coordinates,
            zoom: 16,
            speed: 1.5,
            curve: 1.5
        });       
    };

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return(
        <div style={{height:'100%'}}>
            {/* 목록으로 돌아가기 네비게이션 */}
            <div onClick={
                    ()=>{navigator('/cpo/station-zoom')}
                } 
                style={{
                    marginBottom: '15px', 
                    color:'#2FB4D9',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content'}}>
                <FaChevronLeft/><p>목록으로 이동</p>
            </div>
            {/* 개요 세션 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px'
                }}>
                <h1>'{requestInfo.korName}' 충전소 추천입지 보고서 </h1>
                
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '15px'
                }}>
                {/* <p>요청 일자: {localDateStr}</p> */}
                <p>요청 일자: {requestInfo.date}</p>
            </div>
            {/* 시뮬레이션 요인 가중치 정보 카드 컴포넌트  */}
            <ZoomSimReportFactorWeightCard factorWeight={requestInfo.factors}/>
            <hr/> 
            {/* PART: 지도전시 영역 */}
            <div style={{
                    marginLeft: '15px',   
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                [지도사용방법] 지도에 표시된 충전소관련 아이콘을 클릭하면 요약정보를 확인 할 수 있습니다. 
                지도에 표시된 격자에 대한 보다 자세한 정보를 확인하고 싶다면 마우스 오른쪽버튼을 이용하여 격자를 클릭하면 됩니다.
            </div>             
            <div style={{
                    height: '800px', 
                    width: '97%',
                    marginLeft: '15px'
                }}
                ref={mapBoxContainerRef}
                className="map-container">
            </div>
            {/* 지도범례 컴포넌트  */}
            <ZoomSimReportMapLegend/>
            {/* PART: 추가설치가 권장되는 충전소 목록 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>1. 추가설치가 권장되는 충전소</h3>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                기존 설치되어 있는 충전소의 평균충전량을 근거로 추가설치가 권장되는 충전소를 제안합니다.
            </div>
            <div style={{
                    marginBottom:'20px',
                    marginLeft: '25px'
                }}>
                <GridComponent 
                    height={210}
                    allowPaging={true}
                    loadingIndicator={{indicatorType: 'Spinner'}}
                    pageSettings = {{pageSize:5, pageCount:10}}
                    rowSelected={onSelectRecommendedStation}
                    //queryCellInfo={simRequestGridqueryCellInfoHandler}
                    dataSource={recommendedStations}>
                    <ColumnsDirective>
                        <ColumnDirective field='properties.statId' headerText='충전소식별번호' width='50' textAlign='Center'/>    
                        <ColumnDirective field='properties.chargerCount' headerText='충전기개수' width='50' textAlign='Left'/>
                        <ColumnDirective field='properties.meanKWH' headerText='1회 평균충전량 ' width='150' textAlign='Left'/>  
                    </ColumnsDirective>    
                    <Inject services={[Page, Selection]}/>
                </GridComponent>                
            </div>
            {/* PART: 신규 설치장소 제안된 장소 목록 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>2. 신규설치가 권장되는 충전소</h3>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                충전소가 아직 없는 지역 중 AI 학습을 통해 신규설치가 권장되는 지역을 제안합니다.
            </div>
            <div style={{
                    marginBottom:'20px',
                    marginLeft: '25px'
                }}>
                <GridComponent 
                    height={210}
                    allowPaging={true}
                    loadingIndicator={{indicatorType: 'Spinner'}}
                    pageSettings = {{pageSize:5, pageCount:10}}
                    rowSelected={onSelectRecommendedLocation}
                    //queryCellInfo={simRequestGridqueryCellInfoHandler}
                    dataSource={newLocations}>
                    <ColumnsDirective>
                        <ColumnDirective field='properties.Estimated_kWh' headerText='예상되는 충전량' width='50' textAlign='Center'/>     
                    </ColumnsDirective>    
                    <Inject services={[Page, Selection]}/>
                </GridComponent>                
            </div>
        </div>
    );
}

export default CpoStationZoomSimReport;